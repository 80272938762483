import { downloadFile } from '../../helpers/downloads'
import * as tableReducers from './reducers'
import * as api from './api'
import * as notificationActions from '../notification/actions'
import * as constants from '../../helpers/constants'
import * as generalAccessors from '../general/accessors'
import * as webcontrolAccessors from '../webcontrol/accessors'
import * as controlappAccessors from '../controlapp/accessors'
import * as tableAccessors from '../table/accessors'

export const filterStartDate = (date) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.FILTER_START_DATE,
        startDate: date,
    })
}

export const filterEndDate = (date) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.FILTER_END_DATE,
        endDate: date,
    })
}

export const resetTable = () => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.RESET_TABLE,
    })
}

export const fetchTableData = () => (dispatch, getState) => {
    const viewName = generalAccessors.getViewName(getState())
    const language = generalAccessors.getLanguage(getState())
    const tableFilters = tableAccessors.getCurrentFilterValues(getState())
    let appFilters = []
    switch (viewName) {
        case constants.viewNames.EVENTS:
            appFilters = webcontrolAccessors.getCurrentFilterValues(getState())
            break
        case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
        case constants.viewNames.EVALUATIONS:
        case constants.viewNames.INCIDENTS:
        case constants.viewNames.VISITS:
        case constants.viewNames.KEYRINGS:
        case constants.viewNames.ROUNDS:
        case constants.viewNames.SURVEILLANCE:
            appFilters = controlappAccessors.getCurrentFilterValues(getState())
            break
        default:
            break
    }
    const currentFilters = { ...tableFilters, ...appFilters }
    dispatch({
        type: tableReducers.actionTypes.FETCH_TABLE_DATA_REQUEST,
        currentFilters: currentFilters,
        viewName: viewName,
    })
    api.fetchTableData(currentFilters, viewName)
        .then((response) => {
            dispatch({
                type: tableReducers.actionTypes.FETCH_TABLE_DATA_SUCCESS,
                data: response.data,
            })
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorTableData
                    : constants.translationsEn.errorTableData
            dispatch({
                type: tableReducers.actionTypes
                    .FETCH_TABLE_DATA_FAILURE,
                error: errorMessage,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })

}

export const downloadDocument = (documentFormat) => (dispatch, getState) => {
    const viewName = generalAccessors.getViewName(getState())
    const language = generalAccessors.getLanguage(getState())
    const tableFiltersWithoutPagination = tableAccessors.getCurrentFilterValuesWithoutPagination(getState())
    let appFilters = []
    switch (viewName) {
        case constants.viewNames.EVENTS:
            appFilters = webcontrolAccessors.getCurrentFilterValues(getState())
            break
        case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
        case constants.viewNames.EVALUATIONS:
        case constants.viewNames.INCIDENTS:
        case constants.viewNames.VISITS:
        case constants.viewNames.KEYRINGS:
        case constants.viewNames.ROUNDS:
        case constants.viewNames.SURVEILLANCE:
            appFilters = controlappAccessors.getCurrentFilterValues(getState())
            break
        default:
            break
    }
    const currentFilters = { ...tableFiltersWithoutPagination, ...appFilters }
    dispatch({
        type: tableReducers.actionTypes.DOWNLOAD_DOCUMENT_REQUEST,
        currentFilters: currentFilters,
        viewName: viewName,
        documentFormat: documentFormat,
    })
    api.fetchDocument(currentFilters, viewName, documentFormat)
        .then((response) => {
            const file = response.data.file
            const name = response.data.name
            dispatch({
                type: tableReducers.actionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
            })
            downloadFile(file, name)
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? documentFormat === constants.formats.PDF
                        ? constants.translationsEs.errorDownloadPdf
                        : constants.translationsEs.errorDownloadExcel
                    : documentFormat === constants.formats.PDF
                        ? constants.translationsEn.errorDownloadPdf
                        : constants.translationsEn.errorDownloadExcel
            dispatch({
                type: tableReducers.actionTypes.DOWNLOAD_DOCUMENT_FAILURE,
                error: errorMessage,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const selectStartDate = (date) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.SELECT_START_DATE,
        startDate: date,
    })
}

export const selectEndDate = (date) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.SELECT_END_DATE,
        endDate: date,
    })
}

export const setPage = (page) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.SET_PAGE,
        page: page,
    })
}

export const setPageSize = (pageSize) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.SET_PAGE_SIZE,
        pageSize: pageSize,
    })
}

export const sortByColumn = (column, descending) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.SET_SORTING,
        sortBy: column,
        sortDescending: descending,
    })
}

export const setColumnFilters = (filtered) => (dispatch, getState) => {
    dispatch({
        type: tableReducers.actionTypes.SET_COLUMN_FILTERS,
        filtered: filtered,
    })
}
