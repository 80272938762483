import { mdiCalendarAlert } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Tooltip, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'
import * as generalActions from '../../store/general/actions'

const RoundIncidentLink = (props) => {
    const { originalRowData } = props
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const theme = useTheme()
    const handleRoundIncidentLinkButtonPress = () => {
        dispatch(controlappActions.setRoundIncidentData({
            description: originalRowData.incident_description,
            observations: originalRowData.incident_observation,
            point: originalRowData.point,
        }))
        if (originalRowData.incident_image_id) {
            dispatch(controlappActions.fetchRoundIncidentImage(originalRowData.incident_image_id))
        }
        dispatch(generalActions.setRoundIncidentModalOpen(true))
    }
    return (
        <Tooltip title={language === 'es' ? constants.translationsEs.incident : constants.translationsEn.incident}>
            <Button onClick={handleRoundIncidentLinkButtonPress}>
                <Icon path={mdiCalendarAlert} size={1} color={theme.palette.primary.main} />
            </Button>
        </Tooltip>
    )
}

export default RoundIncidentLink
