const initialState = {
    // SELECTORS
    companies: [],
    filteredCustomers: [],
    selectedCompany: null,
    selectedCustomer: null,
    // CONFIG
    userName: null,
    language: 'es',
    timezone: 'UTC+1',
    // UI
    viewName: [],
    screens: [],
    controlappRoundTasksModalOpen: false,
    controlappRoundIncidentModalOpen: false,
    controlappImageModalOpen: false,
    customerMenuOpen: false,
    drawerOpen: true,
    incidentDetailModalOpen: false,
    languageSelectorOpen: false,
    sarebMenuOpen: false,
    traceAppMenuOpen: false,
    visitDetailModalOpen: false,
    visitorControlMenuOpen: false,
    webcontrolMenuOpen: false,
}

export const actionTypes = {
    SELECT_COMPANY: 'SELECT_COMPANY',
    SELECT_CUSTOMER: 'SELECT_CUSTOMER',
    SELECT_LANGUAGE: 'SELECT_LANGUAGE',
    SET_CONFIGURATION: 'SET_CONFIGURATION',
    SET_FILTERED_CUSTOMERS: 'SET_FILTERED_CUSTOMERS',
    SET_SCREENS: 'SET_SCREENS',
    SET_VIEW_NAME: 'SET_VIEW_NAME',
    SET_CUSTOMER_MENU_OPEN: 'SET_CUSTOMER_MENU_OPEN',
    SET_DRAWER_OPEN: 'SET_DRAWER_OPEN',
    SET_INCIDENT_DETAIL_MODAL_OPEN: 'SET_INCIDENT_DETAIL_MODAL_OPEN',
    SET_LANGUAGE_SELECTOR_OPEN: 'SET_LANGUAGE_SELECTOR_OPEN',
    SET_SAREB_MENU_OPEN: 'SET_SAREB_MENU_OPEN',
    SET_TRACEAPP_MENU_OPEN: 'SET_TRACEAPP_MENU_OPEN',
    SET_VISITOR_CONTROL_MENU_OPEN: 'SET_VISITOR_CONTROL_MENU_OPEN',
    SET_WEBCONTROL_MENU_OPEN: 'SET_WEBCONTROL_MENU_OPEN',
    RESET_MODALS: 'RESET_MODALS',
    SET_VISIT_DETAIL_MODAL_OPEN: 'SET_VISIT_DETAIL_MODAL_OPEN',
    SET_CONTROLAPP_ROUND_TASKS_MODAL_OPEN: 'SET_CONTROLAPP_ROUND_TASKS_MODAL_OPEN',
    SET_CONTROLAPP_ROUND_TASK_MODAL_OPEN: 'SET_CONTROLAPP_ROUND_TASK_MODAL_OPEN',
    SET_CONTROLAPP_ROUND_INCIDENT_MODAL_OPEN: 'SET_CONTROLAPP_ROUND_INCIDENT_MODAL_OPEN',
    SET_CONTROLAPP_IMAGE_MODAL_OPEN: 'SET_CONTROLAPP_IMAGE_MODAL_OPEN',
}

const general = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONFIGURATION:
            return {
                ...state,
                language: action.language,
                companies: action.data,
                userName: action.userName,
                screens: action.screens,
            }
        case actionTypes.SELECT_LANGUAGE:
            return {
                ...state,
                language: action.language,
            }
        case actionTypes.SELECT_COMPANY:
            return {
                ...state,
                selectedCompany: action.selectedCompany,
            }
        case actionTypes.SELECT_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.selectedCustomer,
            }
        case actionTypes.SET_DRAWER_OPEN:
            return {
                ...state,
                drawerOpen: action.drawerOpen,
            }
        case actionTypes.SET_LANGUAGE_SELECTOR_OPEN:
            return {
                ...state,
                languageSelectorOpen: action.languageSelectorOpen,
            }
        case actionTypes.SET_SAREB_MENU_OPEN:
            return {
                ...state,
                sarebMenuOpen: action.sarebMenuOpen,
            }
        case actionTypes.SET_TRACEAPP_MENU_OPEN:
            return {
                ...state,
                traceAppMenuOpen: action.traceAppMenuOpen,
            }
        case actionTypes.SET_CUSTOMER_MENU_OPEN:
            return {
                ...state,
                customerMenuOpen: action.customerMenuOpen,
            }
        case actionTypes.SET_VIEW_NAME:
            return {
                ...state,
                viewName: action.viewName,
            }
        case actionTypes.SET_VISITOR_CONTROL_MENU_OPEN:
            return {
                ...state,
                visitorControlMenuOpen: action.visitorControlMenuOpen,
            }
        case actionTypes.SET_WEBCONTROL_MENU_OPEN:
            return {
                ...state,
                webcontrolMenuOpen: action.webcontrolMenuOpen,
            }
        case actionTypes.SET_SCREENS:
            return {
                ...state,
                screens: action.screens,
            }
        case actionTypes.SET_FILTERED_CUSTOMERS:
            return {
                ...state,
                filteredCustomers: action.filteredCustomers,
            }
        case actionTypes.SET_INCIDENT_DETAIL_MODAL_OPEN:
            return {
                ...state,
                incidentDetailModalOpen: action.incidentDetailModalOpen,
            }
        case actionTypes.SET_VISIT_DETAIL_MODAL_OPEN:
            return {
                ...state,
                visitDetailModalOpen: action.visitDetailModalOpen,
            }
        case actionTypes.SET_CONTROLAPP_ROUND_TASKS_MODAL_OPEN:
        case actionTypes.SET_CONTROLAPP_ROUND_TASK_MODAL_OPEN:
            return {
                ...state,
                controlappRoundTasksModalOpen: action.controlappRoundTasksModalOpen,
            }
        case actionTypes.SET_CONTROLAPP_ROUND_INCIDENT_MODAL_OPEN:
            return {
                ...state,
                controlappRoundIncidentModalOpen: action.controlappRoundIncidentModalOpen,
            }
        case actionTypes.SET_CONTROLAPP_IMAGE_MODAL_OPEN:
            return {
                ...state,
                controlappImageModalOpen: action.controlappImageModalOpen,
            }
        case actionTypes.RESET_MODALS:
            return {
                ...state,
                languageSelectorOpen: false,
                incidentDetailModalOpen: false,
                visitDetailModalOpen: false,
            }
        default:
            return state
    }
}

export default general
