export const getCurrentFilterValues = (state) => {
    const selectedCenter = state.controlapp.selectedCenter ? state.controlapp.selectedCenter.center_code : null
    const selectedContract = state.controlapp.selectedContract ? state.controlapp.selectedContract.contract_code : null
    const selectedService = state.controlapp.selectedService ? state.controlapp.selectedService.service_code : null
    return {
        selectedCenter,
        selectedContract,
        selectedService,
    }
}

export const getFilteredContracts = (state) => {
    if (!state.controlapp.loading) {
        return state.controlapp.filteredContracts
    } else {
        return []
    }
}

export const getFilteredServices = (state) => {
    if (!state.controlapp.loading) {
        return state.controlapp.filteredServices
    } else {
        return []
    }
}

export const getSelectedCenter = (state) => {
    return state.controlapp.selectedCenter
}

export const getSelectedContract = (state) => {
    return state.controlapp.selectedContract
}

export const getSelectedService = (state) => {
    return state.controlapp.selectedService
}

export const getCenters = (state) => {
    return state.controlapp.centers
}

export const getLoading = (state) => {
    return state.controlapp.loading
}

export const getIdRegImage = (state) => {
    return state.controlapp.idRegImage
}

export const getImage = (state) => {
    return state.controlapp.image
}

export const getIncidentData = (state) => {
    return state.controlapp.incidentData
}

export const getIncidentImage = (state) => {
    return state.controlapp.incidentImage
}

export const getVisitData = (state) => {
    return state.controlapp.visitData
}

export const getVisitImage = (state) => {
    return state.controlapp.visitImage
}

export const getRoundIncidentData = state => {
    return state.controlapp.roundIncidentData
}

export const getRoundIncidentImage = state => {
    return state.controlapp.roundIncidentImage
}

export const getLoadingRoundIncidentData = state => {
    return state.controlapp.loadingRoundIncidentData
}

export const getRoundTasksData = state => {
    return state.controlapp.roundTasksData
}

export const getLoadingRoundTasksData = state => {
    return state.controlapp.loadingRoundTasksData
}

export const getRoundTasks = state => {
    return state.controlapp.roundTasks
}

export const getHasError = state => {
    return state.controlapp.hasError
}

export const getLoadingIncidentImage = state => {
    return state.controlapp.loadingIncidentImage
}

export const getLoadingIncidentData = state => {
    return state.controlapp.loadingIncidentData
}

export const getLoadingVisitData = state => {
    return state.controlapp.loadingVisitData
}
