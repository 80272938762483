import { AppBar, Box, Divider, FormLabel, Grid, GridList, GridListTile, GridListTileBar, IconButton, Tab, Tabs, TextField, Button } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../styles/Styles";
import * as constants from "../../helpers/constants";
import * as generalAccessors from "../../store/general/accessors";
import * as controlappAccessors from "../../store/controlapp/accessors";
import * as controlappActions from "../../store/controlapp/actions";
import { mdiEye, mdiFilePdfBox } from "@mdi/js";
import Icon from "@mdi/react";
import GoogleMapLink from "../columnActionButtons/GoogleMapLink";
import { Card, CardContent, LinearProgress, Modal, ThemeProvider, Typography } from "@mui/material";
import { useTheme } from '@material-ui/core/styles'
import { Cancel } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';

const VisitDetailModal = () => {
    const dispatch = useDispatch();
    const theme = useTheme()
    const classes = useStyles();
    const visitData = useSelector(controlappAccessors.getVisitData);
    const language = useSelector(generalAccessors.getLanguage);
    const open = useSelector(generalAccessors.getVisitDetailModalOpen);
    const loading = useSelector(controlappAccessors.getLoadingVisitData)
    const loadingPdf = useSelector(controlappAccessors.getLoading)
    const hasError = useSelector(controlappAccessors.getHasError)
    const handleVisitDetailModalClose = () => {
        dispatch(controlappActions.clearVisitData());
    };
    const handleVisitImageActionButtonClick = (visitImage) => {
        var image = new Image()
        image.src = visitImage
        image.height = window.innerHeight
        var w = window.open('');
        w.document.write(image.outerHTML);
    };
    const handleDownloadPdfButtonPress = () => {
        dispatch(controlappActions.downloadVisitPdf())
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
        );
    }

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newTab) => {
        setSelectedTab(newTab);
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleVisitDetailModalClose}
            >
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 1000,
                        boxShadow: 24,
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    style={{ backgroundColor: theme.palette.primary.main }}
                >
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            color={theme.palette.background.default}
                            variant='h4'
                        >
                            {language === 'es' ? constants.translationsEs.visitData : constants.translationsEn.visitData}{' '}
                            {visitData.id ? visitData.id : ''}{visitData.id && ' ('}{visitData.id && (language === 'es' ? constants.translationsEs.subscriberCode : constants.translationsEn.subscriberCode)}{' '}{visitData.id && visitData.sbn_code}{visitData.id && ')'}
                        </Typography>
                    </CardContent>
                    {loading && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.loadingVisitData : constants.translationsEn.loadingVisitData}</Typography>
                            <LinearProgress sx={{ mt: 5, mb: 5 }} color='primary' />
                        </CardContent>
                    )}
                    {hasError && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.errorVisitData : constants.translationsEn.errorVisitData}</Typography>
                            <Typography variant='h6'>{language === 'es' ? constants.translationsEs.pleaseTryAgain : constants.translationsEn.pleaseTryAgain}</Typography>
                        </CardContent>
                    )}
                    {visitData.id && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default, minHeight: 460 }}>
                            <AppBar position="relative">
                                <Tabs
                                    value={selectedTab}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                >
                                    <Tab label={
                                        language === "es"
                                            ? constants.translationsEs.property
                                            : constants.translationsEn.property
                                    }
                                    />
                                    <Tab label={
                                        language === "es"
                                            ? constants.translationsEs.event
                                            : constants.translationsEn.event
                                    }
                                    />
                                    {visitData.watchman_name && (
                                        <Tab label={
                                            language === "es"
                                                ? constants.translationsEs.surveillance
                                                : constants.translationsEn.surveillance
                                        }
                                        />
                                    )}
                                    {visitData.visit_image_data && visitData.visit_image_data.length && (
                                        <Tab label={
                                            language === "es"
                                                ? constants.translationsEs.images
                                                : constants.translationsEn.images
                                        }
                                        />
                                    )}
                                </Tabs>
                            </AppBar>

                            {/* Datos del Inmueble */}
                            <TabPanel value={selectedTab} index={0}>
                                <h3>{
                                    language === "es"
                                        ? constants.translationsEs.propertyData
                                        : constants.translationsEn.propertyData
                                }</h3>
                                <Grid container spacing={1}>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={visitData.service_name ?? ''}
                                                label={
                                                    language === "es"
                                                        ? constants.translationsEs.serviceName
                                                        : constants.translationsEn.serviceName
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={visitData.address ?? ''}
                                                label={
                                                    language === "es"
                                                        ? constants.translationsEs.address
                                                        : constants.translationsEn.address
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={visitData.city ?? ''}
                                                label={
                                                    language === "es"
                                                        ? constants.translationsEs.city
                                                        : constants.translationsEn.city
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={visitData.province ?? ''}
                                                label={
                                                    language === "es"
                                                        ? constants.translationsEs.province
                                                        : constants.translationsEn.province
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Grid>
                            </TabPanel>
                            {/* Datos del Evento */}
                            <TabPanel value={selectedTab} index={1}>
                                <h3>{
                                    language === "es"
                                        ? constants.translationsEs.eventData
                                        : constants.translationsEn.eventData
                                }</h3>
                                <Grid container spacing={1}>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={6} md={visitData.arriving_date ? 4 : 6}>
                                            <TextField
                                                value={visitData.start_date ?? ''}
                                                label={
                                                    language === "es"
                                                        ? constants.translationsEs.creationDate
                                                        : constants.translationsEn.creationDate
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={visitData.arriving_date ? 4 : 6}>
                                            <TextField
                                                value={visitData.start_time ?? ''}
                                                label={
                                                    language === "es"
                                                        ? constants.translationsEs.creationTime
                                                        : constants.translationsEn.creationTime
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4} />
                                    </Grid>
                                    {visitData.arriving_date && (
                                        <Grid container item xs={12} spacing={3}>
                                            <Grid item xs={6} md={4}>
                                                <TextField
                                                    value={visitData.arriving_date ?? ''}
                                                    label={
                                                        language === "es"
                                                            ? constants.translationsEs.watchmanArrivalDate
                                                            : constants.translationsEn.watchmanArrivalDate
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <TextField
                                                    value={visitData.arriving_time ?? ''}
                                                    label={
                                                        language === "es"
                                                            ? constants.translationsEs.watchmanArrivalTime
                                                            : constants.translationsEn.watchmanArrivalTime
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormLabel filled>
                                                    {language === "es"
                                                        ? constants.translationsEs.watchmanArrivalGeolocation
                                                        : constants.translationsEn.watchmanArrivalGeolocation}
                                                    <GoogleMapLink
                                                        latitude={visitData.arriving_latitude ?? ''}
                                                        longitude={visitData.arriving_longitude ?? ''}
                                                    />
                                                </FormLabel>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {visitData.finished_date && (
                                        <Grid container item xs={12} spacing={3}>
                                            <Grid item xs={6} md={4}>
                                                <TextField
                                                    value={visitData.finished_date ?? ''}
                                                    label={
                                                        language === "es"
                                                            ? constants.translationsEs.watchmanCompletionDate
                                                            : constants.translationsEn.watchmanCompletionDate
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <TextField
                                                    value={visitData.finished_time ?? ''}
                                                    label={
                                                        language === "es"
                                                            ? constants.translationsEs.watchmanCompletionTime
                                                            : constants.translationsEn.watchmanCompletionTime
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormLabel filled>
                                                    {language === "es"
                                                        ? constants.translationsEs.watchmanCompletionGeolocation
                                                        : constants.translationsEn
                                                            .watchmanCompletionGeolocation}
                                                    <GoogleMapLink
                                                        latitude={visitData.finished_latitude ?? ''}
                                                        longitude={visitData.finished_longitude ?? ''}
                                                    />
                                                </FormLabel>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </TabPanel>

                            {/* Datos de Vigilancia */}
                            <TabPanel value={selectedTab} index={2}>
                                <h3>{
                                    language === "es"
                                        ? constants.translationsEs.surveillanceReport
                                        : constants.translationsEn.surveillanceReport
                                }</h3>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.watchman_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.watchmanName
                                                    : constants.translationsEn.watchmanName
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.watchman_tip ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.watchmanTip
                                                    : constants.translationsEn.watchmanTip
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.key_used_option_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.keyringCustody
                                                    : constants.translationsEn.keyringCustody
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.intrusion_option_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.isIntrusion
                                                    : constants.translationsEn.isIntrusion
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            value={visitData.property_damages ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.propertyDamage
                                                    : constants.translationsEn.propertyDamage
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.system_verified_option_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.isSystemVerified
                                                    : constants.translationsEn.isSystemVerified
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.trespassed_option_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.accessedInterior
                                                    : constants.translationsEn.accessedInterior
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.system_damage_option_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.systemDamage
                                                    : constants.translationsEn.systemDamage
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            value={visitData.closing_verification_option_name ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.perimeterVerificationOption
                                                    : constants.translationsEn.perimeterVerificationOption
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </Grid>
                                {visitData.closing_verification && (
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            value={visitData.closing_verification ?? ''}
                                            label={
                                                language === "es"
                                                    ? constants.translationsEs.perimeterVerificationText
                                                    : constants.translationsEn.perimeterVerificationText
                                            }
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth={true}
                                            multiline={true}
                                        />
                                    </Grid>
                                )}
                            </TabPanel>

                            {/* Galeria de Fotos */}
                            <TabPanel value={selectedTab} index={3}>
                                <h3>{
                                    language === "es"
                                        ? constants.translationsEs.photoGallery
                                        : constants.translationsEn.photoGallery
                                }</h3>
                                {visitData.visit_image_data && (
                                    <div className={classes.gridListRoot}>
                                        <GridList className={classes.gridList} cols={3} spacing={10}>
                                            {visitData.visit_image_data.map((image) => (
                                                <GridListTile key={image.filename}>
                                                    <img src={image.blobData} alt={image.filename} />
                                                    <GridListTileBar
                                                        title={image.filename}
                                                        classes={{
                                                            root: classes.gridListTitleBar,
                                                            title: classes.gridListTitle,
                                                        }}
                                                        actionIcon={
                                                            <IconButton onClick={() => handleVisitImageActionButtonClick(image.blobData)} >
                                                                <Icon
                                                                    path={mdiEye}
                                                                    className={classes.gridListTitle}
                                                                    size={1}
                                                                />
                                                            </IconButton>
                                                        }
                                                    />
                                                </GridListTile>
                                            ))}
                                        </GridList>
                                    </div>
                                )}
                            </TabPanel>
                        </CardContent>
                    )}
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-around', backgroundColor: theme.palette.background.default }}>
                        {visitData.id && (
                            <LoadingButton
                                onClick={handleDownloadPdfButtonPress}
                                variant="contained"
                                color="secondary"
                                size="small"
                                startIcon={<Icon path={mdiFilePdfBox} size={1} />}
                                loading={loadingPdf}
                            >
                                {
                                    language === "es"
                                        ? constants.translationsEs.downloadPdf
                                        : constants.translationsEn.downloadPdf
                                }</LoadingButton>
                        )}
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={handleVisitDetailModalClose}
                            startIcon={<Cancel />}
                        >
                            {language === 'es' ? constants.translationsEs.close : constants.translationsEn.close}
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </ThemeProvider>
    )
}

export default VisitDetailModal;
