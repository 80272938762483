const initialState = {
    buildingSelector: [],
    selectedBuilding: null,
    loading: false,
}

export const actionTypes = {
    FETCH_BUILDINGS_REQUEST: 'FETCH_BUILDINGS_REQUEST',
    FETCH_BUILDINGS_SUCCESS: 'FETCH_BUILDINGS_SUCCESS',
    FETCH_BUILDINGS_FAILURE: 'FETCH_BUILDINGS_FAILURE',
    SELECT_BUILDING: 'SELECT_BUILDING',
    RESET_VISITORLIST_SELECTORS: 'RESET_VISITORLIST_SELECTORS',
    RESET_LOADING: 'RESET_LOADING',
}

const visitorlist = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_BUILDINGS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_BUILDINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                buildingSelector: action.buildingSelector,
            }
        case actionTypes.FETCH_BUILDINGS_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.SELECT_BUILDING:
            return {
                ...state,
                selectedBuilding: action.selectedBuilding,
            }
        case actionTypes.RESET_VISITORLIST_SELECTORS:
            return {
                ...state,
                selectedBuilding: null,
            }
        case actionTypes.RESET_LOADING:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export default visitorlist
