import * as tableAccessors from '../table/accessors'
import * as webcontrolAccessors from '../webcontrol/accessors'
import * as visitorlistAccessors from '../visitorlist/accessors'
import { addMonths, isAfter, isBefore, subDays, subMonths, format } from 'date-fns'
import * as constants from '../../helpers/constants'
import * as generalAccessors from '../general/accessors'

export const getExportButtonsDisabled = (state) => {
    return (
        !state.table.data.data ||
        state.table.data.data.length < 1 ||
        state.table.loading
    )
}

export const getData = (state) => {
    let data = []
    if (state.table.data && state.table.data.data) {
        data = state.table.data.data
    }
    return data
}

export const getTotal = (state) => {
    let data = []
    if (state.table.data) {
        data = state.table.data.total
    }
    return data
}

export const getPages = (state) => {
    let pages = -1
    if (state.table.data) {
        pages = state.table.data.last_page
    }
    return pages
}

export const getLoading = (state) => {
    return state.table.loading
}

export const getStartDate = (state) => {
    return new Date(state.table.startDate)
}

export const getEndDate = (state) => {
    return new Date(state.table.endDate)
}

export const getMaxEndDate = (state) => {
    return addMonths(new Date(state.table.startDate), 1)
}

export const getCurrentFilterValues = (state) => {
    const filtered = state.table.filtered
    const sortBy = state.table.sortBy
    const sortDescending = state.table.sortDescending
    const pageSize = state.table.pageSize
    const page = state.table.page
    const startDate = format(new Date(state.table.startDate), 'yyyy-MM-dd')
    const endDate = format(new Date(state.table.endDate), 'yyyy-MM-dd')
    const selectedCompany = state.general.selectedCompany ? state.general.selectedCompany.company_code : null
    const selectedCustomer = state.general.selectedCustomer ? state.general.selectedCustomer.customer_code : null
    return {
        filtered,
        sortBy,
        sortDescending,
        pageSize,
        page,
        startDate,
        endDate,
        selectedCompany,
        selectedCustomer,
    }
}

export const getFiltered = (state) => {
    return state.table.filtered
}

export const getCurrentFilterValuesWithoutPagination = (state) => {
    const filtered = state.table.filtered
    const sortBy = state.table.sortBy
    const sortDescending = state.table.sortDescending
    const startDate = format(new Date(state.table.startDate), 'yyyy-MM-dd')
    const endDate = format(new Date(state.table.endDate), 'yyyy-MM-dd')
    const selectedCompany = state.general.selectedCompany ? state.general.selectedCompany.company_code : null
    const selectedCustomer = state.general.selectedCustomer ? state.general.selectedCustomer.customer_code : null
    return {
        filtered,
        sortBy,
        sortDescending,
        startDate,
        endDate,
        selectedCompany,
        selectedCustomer,
    }
}

export const getSearchButtonDisabled = (state) => {
    const endDate = getEndDate(state)
    const startDate = getStartDate(state)
    const selectedCompany = generalAccessors.getSelectedCompany(state)
    const selectedCustomer = generalAccessors.getSelectedCustomer(state)
    const selectedBuilding = visitorlistAccessors.getSelectedBuilding(state)
    const selectedInstallation = webcontrolAccessors.getSelectedInstallation(state)
    const isLoadingTable = tableAccessors.getLoading(state)
    let disabled = false
    switch (state.general.viewName) {
        case constants.viewNames.VISITOR_CONTROL:
            if (!selectedBuilding || isLoadingTable) {
                disabled = true
            }
            break
        case constants.viewNames.EVENTS:
            if (!selectedInstallation) {
                disabled = true
            }
            break
        default:
            if (
                isAfter(endDate, addMonths(endDate, 1)) ||
                isBefore(startDate, subDays(subMonths(endDate, 1), 1)) ||
                !selectedCompany ||
                !selectedCustomer ||
                isLoadingTable
            ) {
                disabled = true
            }
    }
    return disabled
}
