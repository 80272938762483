import React from "react"
import ReactDOM from "react-dom"

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import configureStore from './store/configureStore'
import Spinner from "./components/Spinner"
import App from "./App"

const { store, persistor } = configureStore()

ReactDOM.render(
    <Provider
        store={store}>
        <PersistGate
            loading={<Spinner />}
            persistor={persistor}
        >
            <App />
        </PersistGate>
    </Provider>
    ,
    document.getElementById("root")
)
