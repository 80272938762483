import { makeStyles } from "@material-ui/core";

const drawerWidth = 350;

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: 65,
      backgroundColor: theme.palette.primary.main,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      color: "white",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    customerSelectorSideButton: {
      fontWeight: "bold",
      whiteSpace: "break-spaces",
      padding: "10px",
      marginTop: 10,
      marginBottom: 10,
    },
    customerSelectorSideArrow: {
      display: "flex",
      alignItems: "center",
      color: "#575756",
      padding: "10px",
      textAlign: "center",
    },
    customerSelectorSideArrowIcon: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    customerSelectorSlider: {
      position: "absolute",
      width: "480px",
      padding: "6px",
      height: "48px",
      zIndex: "2",
      fontSize: "14px",
      backgroundColor: "lightgrey",
      marginTop: '7px',
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },

    companyDropdown: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 260,
      height: 66,
      display: "flex",
      alignItems: "end",
    },
    buttons: {
      marginTop: 40,
      marginBottom: 20,
    },
    notificationModal: {
      position: "fixed",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      zIndex: "10000",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
    imageModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    gridListRoot: {
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    gridListTitle: {
      color: theme.palette.primary.light,
    },
    gridListTitleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    incidentImageModal: {
      overflow: "scroll",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    incidentImageModalImage: {
      width: "auto",
      height: "90%",
    },
    iconButton: {
      color: theme.palette.secondary.main,
    },
    expandButtonSide: {
      color: "#575756",
    },
    expandMenuItemText: {
      color: "#575756",
    },
    MuiListItemText: {
      color: theme.palette.primary.main,
    },
    gutters: theme.mixins.gutters({
      color: theme.palette.primary.main,
    }),
    guttersExpand: theme.mixins.gutters({
      color: "#575756",
    }),
    itemListSelect: {
      fontWeight: 600,
    },
    cardItem: {
      minWidth: "40%",
      maxWidth: "40%",
      marginLeft: "5%",
      display: "inline-block",
    },
    sideBar: {
      backgroundColor: "lightgrey",
      height: "100%",
      paddingTop: '0px',
    },
    userAccountButton: {
      display: "flex",
      alignItems: "center",
    },
    cardTitle: {
      fontSize: 14,
    },
    carouselSlider: {
      overflow: "hidden",
      alignItems: "center",
    },
    carouselSliderContent: {
      alignItems: "center",
      textAlign: "center",
    },
    carouselSliderItem: {
      width: "100%",
      maxWidth: "800px",
    },
    selectIcon: {
      color: "white",
    },
    datatableFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px 10px",
      lineHeight: "1.5",
      color: "black",
      borderTop: "1px solid #f2f2ff",
      backgroundColor: "#fcfcff",
      marginBottom: "0",
    },
    datatableBasicIncident: {
      whiteSpace: 'unset',
      fontSize: 16,
      fontWeight: "bold",
      width: '100%',
      height: '100%',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    datatableEndService: {
      whiteSpace: 'unset',
      backgroundColor: "#E8D4D4",
      fontSize: 16,
      fontWeight: "bold",
      width: '100%',
      height: '100%',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    datatableNovedadService: {
      whiteSpace: 'unset',
      backgroundColor: "#EDE97E",
      fontSize: 16,
      fontWeight: "bold",
      width: '100%',
      height: '100%',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    datatableStartService: {
      whiteSpace: 'unset',
      backgroundColor: "#D7F2D3",
      fontSize: 16,
      fontWeight: "bold",
      width: '100%',
      height: '100%',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    powerbiEmbedContainer: {
      width: "100%",
      height: '500px',
    },
    preProductionRedirect: {
      fontWeight: "bold",
      width: '100wh',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#c5cdd6',
    },
    submitPreProduction: {
      fontSize: 18,
      padding: '20px',
      backgroundColor: '#831627',
      margin: '50px',

    },
    spinner: {
      position: "fixed",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      zIndex: "10000",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  }),
  { index: 1 }
);
