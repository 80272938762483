import * as visitorlistAccessors from './accessors'
import * as visitorlistReducers from './reducers'
import * as visitorlistApi from './api'
import * as generalAccessors from '../general/accessors'
import * as notificationActions from '../notification/actions'
import * as constants from '../../helpers/constants'

export const selectBuilding = (selectedBuilding) => (dispatch, getState) => {
    const buildings = visitorlistAccessors.getBuildingSelector(getState())
    dispatch({
        type: visitorlistReducers.actionTypes.SELECT_BUILDING,
        selectedBuilding: selectedBuilding ? buildings[selectedBuilding] : null,
    })
}

export const fetchBuildings = () => (dispatch, getState) => {
    dispatch({
        type: visitorlistReducers.actionTypes.FETCH_BUILDINGS_REQUEST,
    })
    const language = generalAccessors.getLanguage(getState())
    visitorlistApi.fetchBuildings()
        .then((response) => {
            dispatch({
                type: visitorlistReducers.actionTypes.FETCH_BUILDINGS_SUCCESS,
                buildingSelector: response.data.payload.buildings,
            })
        })
        .catch(() => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorDropdown
                    : constants.translationsEn.errorDropdown
            dispatch({
                type: visitorlistReducers.actionTypes.FETCH_BUILDINGS_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const resetVisitorlistSelectors = () => (dispatch, getState) => {
    dispatch({
        type: visitorlistReducers.actionTypes.RESET_VISITORLIST_SELECTORS,
    })
}
