import React from "react"
import { Snackbar } from "@mui/material"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "../../styles/Styles"
import { hideModal } from "../../store/notification/actions"
import { getLanguage } from "../../store/general/accessors"
import * as constants from "../../helpers/constants"
import * as notificationAccessors from "../../store/notification/accessors"

export default function NotificationModal() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const errorMessage = useSelector(notificationAccessors.getError)
    const successMessage = useSelector(notificationAccessors.getSuccess)
    const language = useSelector(getLanguage)
    const showNotificationModal = useSelector(
        notificationAccessors.getShowModal
    )
    const handleCloseButtonPress = () => {
        dispatch(hideModal())
    }
    return (
        <div className={classes.notificationModal}>
            {showNotificationModal && (
                <Snackbar
                    open={showNotificationModal}
                    onClose={() => {
                        handleCloseButtonPress()
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        severity={errorMessage ? "error" : "success"}
                        variant="filled"

                    >
                        <AlertTitle>
                            {errorMessage
                                ? "Error"
                                : language === "es"
                                    ? constants.translationsEs.success
                                    : constants.translationsEn.success}
                        </AlertTitle>
                        {errorMessage ? errorMessage : successMessage}
                    </Alert>
                </Snackbar>
            )}
        </div>
    )
}
