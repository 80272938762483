import { mdiEye } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Tooltip, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'

const VisitLink = (props) => {
    const { visitId } = props
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const theme = useTheme()
    const handleVisitLinkButtonPress = () => {
        dispatch(controlappActions.fetchVisitData(visitId))
    }
    return visitId ? (
        <Tooltip title={language === 'es' ? constants.translationsEs.visit : constants.translationsEn.visit}>
            <Button onClick={handleVisitLinkButtonPress}>
                <Icon path={mdiEye} size={1} color={theme.palette.primary.main} />
            </Button>
        </Tooltip>
    ) : null
}

export default VisitLink
