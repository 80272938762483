import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Container, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStyles } from '../styles/Styles'
import Icon from '@mdi/react'
import {
    mdiSend,
    mdiHistory
} from "@mdi/js";
import * as constants from '../helpers/constants'
import * as authAccessors from '../store/auth/accessors'
import * as generalAccessors from '../store/general/accessors'
import * as generalActions from '../store/general/actions'
import * as sarebAccessors from '../store/sareb/accessors'
import * as sarebActions from '../store/sareb/actions'
import { useFormik } from 'formik'
import { enGB, es } from 'date-fns/locale'
import * as yup from 'yup'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const RegistrationRequestForm = () => {
    const language = useSelector(generalAccessors.getLanguage)
    const validationSchema = yup.object({
        servicer: yup
            .object()
            .shape({
                name: yup.string().required(),
                customer_code: yup.string().required(),
            })
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.servicerRequired
                    : constants.translationsEn.validation.servicerRequired
            )
            .nullable(),
        assetId: yup
            .string()
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.assetIdRequired
                    : constants.translationsEn.validation.assetIdRequired
            )
            .max(
                191,
                language === 'es'
                    ? constants.translationsEs.validation.assetIdMax
                    : constants.translationsEn.validation.assetIdMax
            ),
        province: yup
            .object()
            .shape({
                name: yup.string().required(),
                province_id: yup.string().required(),
                ccaa: yup.string().required(),
            })
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.provinceRequired
                    : constants.translationsEn.validation.provinceRequired
            )
            .nullable(),
        city: yup
            .object()
            .shape({
                name: yup.string().required(),
                zip: yup.string().required(),
            })
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.cityRequired
                    : constants.translationsEn.validation.cityRequired
            )
            .nullable(),
        address: yup
            .string()
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.addressRequired
                    : constants.translationsEn.validation.addressRequired
            )
            .max(
                191,
                language === 'es'
                    ? constants.translationsEs.validation.addressMax
                    : constants.translationsEn.validation.addressMax
            ),
        refCatastral: yup
            .string()
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.refCatastralRequired
                    : constants.translationsEn.validation.refCatastralRequired
            )
            .max(
                191,
                language === 'es'
                    ? constants.translationsEs.validation.refCatastralMax
                    : constants.translationsEn.validation.refCatastralMax
            ),
        requestType: yup
            .object()
            .shape({
                name: yup.string().required(),
                id: yup.string().required(),
            })
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.requestTypeRequired
                    : constants.translationsEn.validation.requestTypeRequired
            )
            .nullable(),
        promotionCode: yup
            .string()
            .max(
                191,
                language === 'es'
                    ? constants.translationsEs.validation.promotionCodeMax
                    : constants.translationsEn.validation.promotionCodeMax
            ),
        launchDate: yup
            .date()
            .nullable()
            .when('requestType', {
                is: (requestType) => requestType && requestType.id === '3',
                then: yup
                    .date()
                    .nullable()
                    .required(
                        language === 'es'
                            ? constants.translationsEs.validation
                                .launchDateRequired
                            : constants.translationsEn.validation
                                .launchDateRequired
                    )
                    .min(
                        new Date(),
                        language === 'es'
                            ? constants.translationsEs.validation.launchDateMin
                            : constants.translationsEn.validation.launchDateMin
                    ),
            }),
        servicerId: yup
            .string()
            .max(
                191,
                language === 'es'
                    ? constants.translationsEs.validation.servicerIdMax
                    : constants.translationsEn.validation.servicerIdMax
            ),
        noticeNumber: yup
            .string()
            .max(
                191,
                language === 'es'
                    ? constants.translationsEs.validation.noticeNumberMax
                    : constants.translationsEn.validation.noticeNumberMax
            ),
    })
    const formik = useFormik({
        initialValues: {
            servicer: null,
            assetId: '',
            province: null,
            city: null,
            address: '',
            refCatastral: '',
            requestType: null,
            promotionCode: '',
            launchDate: null,
            servicerId: '',
            noticeNumber: '',
            observations: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sarebActions.submitRegistrationRequestForm(values))
        },
    })
    const canReadRegistrationRequestsTool = useSelector(
        authAccessors.getCanReadRegistrationRequestsTool
    )
    const dispatch = useDispatch()
    const classes = useStyles()
    const provinces = useSelector(sarebAccessors.getProvinces)
    const cities = useSelector(sarebAccessors.getCities)
    const blockFormSubmission = useSelector(
        sarebAccessors.getBlockFormSubmission
    )
    const servicers = useSelector(generalAccessors.getFilteredCustomers)
    const requestTypes = [
        {
            id: 1,
            name:
                language === 'es'
                    ? constants.translationsEs.ordinary
                    : constants.translationsEn.ordinary,
        },
        {
            id: 2,
            name:
                language === 'es'
                    ? constants.translationsEs.extraordinary
                    : constants.translationsEn.extraordinary,
        },
        {
            id: 3,
            name:
                language === 'es'
                    ? constants.translationsEs.launch
                    : constants.translationsEn.launch,
        },
    ]
    useEffect(() => {
        dispatch(sarebActions.fetchProvinces())
        dispatch(
            generalActions.setViewName(
                constants.viewNames.REGISTRATION_REQUEST_FORM
            )
        )
    }, [dispatch])
    const handleResetButtonPress = () => {
        dispatch(sarebActions.fetchProvinces())
        formik.resetForm()
    }
    const showLaunchDatePicker =
        formik &&
        formik.values &&
        formik.values.requestType &&
        formik.values.requestType.id === 3
    const TextFieldComp = (params) => (
        <TextField {...params} fullWidth size='small' variant='outlined' />
    )
    return canReadRegistrationRequestsTool ? (
        <Container maxWidth='xl' className={classes.container}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid container item>
                        <h1>
                            {language === 'es'
                                ? constants.viewNamesEs.registrationRequestForm
                                : constants.viewNamesEn.registrationRequestForm}
                        </h1>
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={6}>
                            <Autocomplete
                                id='servicers'
                                size='small'
                                options={servicers}
                                value={formik.values.servicer}
                                getOptionLabel={(servicer) =>
                                    servicer ? servicer.name : ''
                                }
                                getOptionSelected={(option, value) => {
                                    return value.value === option.value
                                }}
                                onOpen={formik.handleBlur}
                                onChange={(e, selectedServicer) =>
                                    formik.setFieldValue(
                                        'servicer',
                                        selectedServicer || null
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id='servicer'
                                        label='Servicer'
                                        fullWidth
                                        variant='outlined'
                                        error={Boolean(
                                            formik.touched.servicer &&
                                            formik.errors.servicer
                                        )}
                                        helperText={
                                            formik.touched.servicer &&
                                            formik.errors.servicer
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='servicerId'
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.servicerId
                                        : constants.translationsEn.servicerId
                                }
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={formik.values.servicerId}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.servicerId &&
                                    Boolean(formik.errors.servicerId)
                                }
                                helperText={
                                    formik.touched.servicerId &&
                                    formik.errors.servicerId
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={6}>
                            <TextField
                                id='assetId'
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.assetId
                                        : constants.translationsEn.assetId
                                }
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={formik.values.assetId}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.assetId &&
                                    Boolean(formik.errors.assetId)
                                }
                                helperText={
                                    formik.touched.assetId &&
                                    formik.errors.assetId
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='promotionCode'
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.promotionCode
                                        : constants.translationsEn.promotionCode
                                }
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={formik.values.promotionCode}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.promotionCode &&
                                    Boolean(formik.errors.promotionCode)
                                }
                                helperText={
                                    formik.touched.promotionCode &&
                                    formik.errors.promotionCode
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={6}>
                            <TextField
                                id='refCatastral'
                                label='Ref catastral'
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={formik.values.refCatastral}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.refCatastral &&
                                    Boolean(formik.errors.refCatastral)
                                }
                                helperText={
                                    formik.touched.refCatastral &&
                                    formik.errors.refCatastral
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id='noticeNumber'
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.noticeNumber
                                        : constants.translationsEn.noticeNumber
                                }
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={formik.values.noticeNumber}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.noticeNumber &&
                                    Boolean(formik.errors.noticeNumber)
                                }
                                helperText={
                                    formik.touched.noticeNumber &&
                                    formik.errors.noticeNumber
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='address'
                            label={
                                language === 'es'
                                    ? constants.translationsEs.address
                                    : constants.translationsEn.address
                            }
                            fullWidth
                            size='small'
                            variant='outlined'
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.address &&
                                Boolean(formik.errors.address)
                            }
                            helperText={
                                formik.touched.address && formik.errors.address
                            }
                        />
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={6}>
                            <Autocomplete
                                id='provinces'
                                size='small'
                                options={provinces}
                                value={formik.values.province}
                                getOptionLabel={(province) =>
                                    province ? province.name : ''
                                }
                                getOptionSelected={(option, value) => {
                                    return value.value === option.value
                                }}
                                onOpen={formik.handleBlur}
                                onChange={(e, selectedProvince) => {
                                    formik.setFieldValue(
                                        'province',
                                        selectedProvince || null
                                    )
                                    formik.setFieldValue('city', null)
                                    if (selectedProvince) {
                                        dispatch(
                                            sarebActions.fetchCities(
                                                selectedProvince
                                            )
                                        )
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id='province'
                                        label={
                                            language === 'es'
                                                ? constants.translationsEs
                                                    .province
                                                : constants.translationsEn
                                                    .province
                                        }
                                        fullWidth
                                        variant='outlined'
                                        error={Boolean(
                                            formik.touched.province &&
                                            formik.errors.province
                                        )}
                                        helperText={
                                            formik.touched.province &&
                                            formik.errors.province
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id='city'
                                size='small'
                                options={cities}
                                value={formik.values.city}
                                getOptionLabel={(city) =>
                                    city ? city.zip + ' - ' + city.name : ''
                                }
                                onOpen={formik.handleBlur}
                                onChange={(e, selectedCity) =>
                                    formik.setFieldValue(
                                        'city',
                                        selectedCity || null
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id='city'
                                        label={
                                            language === 'es'
                                                ? constants.translationsEs.city
                                                : constants.translationsEn.city
                                        }
                                        fullWidth
                                        variant='outlined'
                                        error={Boolean(
                                            formik.touched.city &&
                                            formik.errors.city
                                        )}
                                        helperText={
                                            formik.touched.city &&
                                            formik.errors.city
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='observations'
                            label={
                                language === 'es'
                                    ? constants.translationsEs.observations
                                    : constants.translationsEn.observations
                            }
                            fullWidth
                            multiline
                            rows={3}
                            size='small'
                            variant='outlined'
                            value={formik.values.observations}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.observations &&
                                Boolean(formik.errors.observations)
                            }
                            helperText={
                                formik.touched.observations &&
                                formik.errors.observations
                            }
                        />
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={showLaunchDatePicker ? 6 : 12}>
                            <Autocomplete
                                id='requestTypes'
                                size='small'
                                options={requestTypes}
                                value={formik.values.requestType}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => {
                                    return value.value === option.value
                                }}
                                onOpen={formik.handleBlur}
                                onChange={(e, selectedRequestType) =>
                                    formik.setFieldValue(
                                        'requestType',
                                        selectedRequestType || null
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id='requestType'
                                        label={
                                            language === 'es'
                                                ? constants.translationsEs
                                                    .requestType
                                                : constants.translationsEn
                                                    .requestType
                                        }
                                        fullWidth
                                        variant='outlined'
                                        error={Boolean(
                                            formik.touched.requestType &&
                                            formik.errors.requestType
                                        )}
                                        helperText={
                                            formik.touched.requestType &&
                                            formik.errors.requestType
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {showLaunchDatePicker && (
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={language === 'es' ? es : enGB}
                                >
                                    <DateTimePicker
                                        value={formik.values.launchDate}
                                        onChange={(value) => {
                                            formik.setFieldValue(
                                                'launchDate',
                                                value
                                            )
                                        }}
                                        disablePast
                                        clearable
                                        format={
                                            language === 'es'
                                                ? constants.translationsEs
                                                    .dateTimeFormat
                                                : constants.translationsEn
                                                    .dateTimeFormat
                                        }
                                        ampm={language !== 'es'}
                                        label={
                                            language === 'es'
                                                ? constants.translationsEs
                                                    .launchDate
                                                : constants.translationsEn
                                                    .launchDate
                                        }
                                        TextFieldComponent={TextFieldComp}
                                        error={
                                            formik.touched.launchDate &&
                                            Boolean(formik.errors.launchDate)
                                        }
                                        helperText={
                                            formik.touched.launchDate &&
                                            formik.errors.launchDate
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item justify='space-around'>
                        <Button
                            type='submit'
                            variant='contained'
                            color='default'
                            startIcon={<Icon path={mdiSend} size={1} />}
                            disabled={blockFormSubmission}
                        >
                            {language === 'es'
                                ? constants.translationsEs.submit
                                : constants.translationsEn.submit}
                        </Button>
                        {blockFormSubmission && (
                            <Button
                                variant='contained'
                                color='default'
                                onClick={handleResetButtonPress}
                                startIcon={<Icon path={mdiHistory} size={1} />}
                            >
                                {language === 'es'
                                    ? constants.translationsEs.reset
                                    : constants.translationsEn.reset}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Container>
    ) : null
}

export default RegistrationRequestForm
