const initialState = {
    loading: false,
    cities: [],
    provinces: [],
    blockFormSubmission: false,
}

export const actionTypes = {
    RESET_SAREB_STATE: 'RESET_SAREB_STATE',
    UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_REQUEST:
        'UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_REQUEST',
    UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_SUCCESS:
        'UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_SUCCESS',
    UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_FAILURE:
        'UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_FAILURE',
    FETCH_PROVINCES_REQUEST: 'FETCH_PROVINCES_REQUEST',
    FETCH_PROVINCES_SUCCESS: 'FETCH_PROVINCES_SUCCESS',
    FETCH_PROVINCES_FAILURE: 'FETCH_PROVINCES_FAILURE',
    SELECT_PROVINCE: 'SELECT_PROVINCE',
    SUBMIT_REGISTRATION_REQUEST_FORM_REQUEST:
        'SUBMIT_REGISTRATION_REQUEST_FORM_REQUEST',
    SUBMIT_REGISTRATION_REQUEST_FORM_SUCCESS:
        'SUBMIT_REGISTRATION_REQUEST_FORM_SUCCESS',
    SUBMIT_REGISTRATION_REQUEST_FORM_FAILURE:
        'SUBMIT_REGISTRATION_REQUEST_FORM_FAILURE',
    SET_BLOCK_FORM_SUBMISSION: 'SET_BLOCK_FORM_SUBMISSION',
    FETCH_CITIES_BY_PROVINCE_REQUEST: 'FETCH_CITIES_BY_PROVINCE_REQUEST',
    FETCH_CITIES_BY_PROVINCE_SUCCESS: 'FETCH_CITIES_BY_PROVINCE_SUCCESS',
    FETCH_CITIES_BY_PROVINCE_FAILURE: 'FETCH_CITIES_BY_PROVINCE_FAILURE',
    RESET_LOADING: 'RESET_LOADING',
}

const sareb = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_REQUEST:
        case actionTypes.SUBMIT_REGISTRATION_REQUEST_FORM_REQUEST:
        case actionTypes.FETCH_PROVINCES_REQUEST:
        case actionTypes.FETCH_CITIES_BY_PROVINCE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_SUCCESS:
        case actionTypes.SUBMIT_REGISTRATION_REQUEST_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.RESET_SAREB_STATE:
            return {
                ...initialState,
            }
        case actionTypes.FETCH_PROVINCES_SUCCESS:
            return {
                ...state,
                loading: false,
                provinces: action.provinces,
            }
        case actionTypes.FETCH_CITIES_BY_PROVINCE_SUCCESS:
            return {
                ...state,
                loading: false,
                cities: action.cities,
            }
        case actionTypes.SELECT_PROVINCE:
            return {
                ...state,
                selectedProvince: action.selectedProvince,
            }
        case actionTypes.SUBMIT_REGISTRATION_REQUEST_FORM_FAILURE:
        case actionTypes.UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_FAILURE:
        case actionTypes.FETCH_PROVINCES_FAILURE:
        case actionTypes.FETCH_CITIES_BY_PROVINCE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.SET_BLOCK_FORM_SUBMISSION:
            return {
                ...state,
                blockFormSubmission: action.blockFormSubmission,
            }
        case actionTypes.RESET_LOADING:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export default sareb
