export const getCities = (state) => {
    return state.sareb.filteredCities && state.sareb.filteredCities.length > 0
        ? state.sareb.filteredCities
        : state.sareb.cities
}

export const getProvinces = (state) => {
    return state.sareb.provinces
}

export const getLoading = (state) => {
    return state.sareb.loading
}

export const getBlockFormSubmission = (state) => {
    return state.sareb.blockFormSubmission
}
