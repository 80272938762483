import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es, enGB } from 'date-fns/locale'
import * as constants from '../helpers/constants'
import * as tableActions from '../store/table/actions'
import * as generalAccessors from '../store/general/accessors'
import * as authAccessors from '../store/auth/accessors'
import * as controlappActions from '../store/controlapp/actions'
import * as controlappAccessors from '../store/controlapp/accessors'
import * as tableAccessors from '../store/table/accessors'

const ControlappFilters = () => {
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const centers = useSelector(controlappAccessors.getCenters)
    const contracts = useSelector(controlappAccessors.getFilteredContracts)
    const services = useSelector(controlappAccessors.getFilteredServices)
    const startDate = useSelector(tableAccessors.getStartDate)
    const endDate = useSelector(tableAccessors.getEndDate)
    const maxEndDate = useSelector(tableAccessors.getMaxEndDate)
    const selectedCenter = useSelector(controlappAccessors.getSelectedCenter)
    const selectedContract = useSelector(controlappAccessors.getSelectedContract)
    const selectedService = useSelector(controlappAccessors.getSelectedService)
    const showServiceDropdown = useSelector(
        generalAccessors.getShowServiceDropdown
    )
    const selectedCustomer = useSelector(generalAccessors.getSelectedCustomer)
    const showDatePickers = useSelector(generalAccessors.getShowDatePickers)
    const loading = useSelector(controlappAccessors.getLoading)
    const loadingAuth = useSelector(authAccessors.getLoading)
    const handleCenterChange = (event, values) => {
        dispatch(
            controlappActions.selectCenter(values ? values.center_code : null)
        )
    }
    const handleContractChange = (event, values) => {
        dispatch(
            controlappActions.selectContract(values ? values.contract_code : null)
        )
    }
    const handleServiceChange = (event, values) => {
        dispatch(
            controlappActions.selectService(values ? values.service_code : null)
        )
    }
    const handleStartDateChange = (date) => {
        dispatch(tableActions.selectStartDate(date))
    }
    const handleEndDateChange = (date) => {
        dispatch(tableActions.selectEndDate(date))
    }
    const TextFieldComp = (params) => (
        <TextField {...params} fullWidth size='small' variant='outlined' />
    )
    const setOptionLabel = (option) => {
        let label= option.name
        if(option.subscriber_code){
            label = option.subscriber_code + ' - ' + option.name
        }
        return label;
    }
    return (
        <Grid container spacing={1}>
            <Grid container item spacing={1} justify='space-between'>
                <Grid item xs={12}>
                    <Autocomplete
                        id='center'
                        options={centers}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                            value.value === option.value
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.center
                                        : constants.translationsEn.center
                                }
                                variant='outlined'
                            />
                        )}
                        onChange={handleCenterChange}
                        value={selectedCenter}
                        size='small'
                        disabled={selectedCustomer === null}
                        loading={loading}
                        loadingText={language === 'es' ? constants.translationsEs.loading : constants.translationsEn.loading}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={1} justify='space-between'>
                <Grid item xs={12}>
                    <Autocomplete
                        id='contract'
                        options={contracts}
                        getOptionLabel = { (option) => setOptionLabel(option) }
                        getOptionSelected={(option, value) =>
                            value.value === option.value
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.contract
                                        : constants.translationsEn.contract
                                }
                                variant='outlined'
                            />
                        )}
                        onChange={handleContractChange}
                        value={selectedContract}
                        size='small'
                        disabled={selectedCustomer === null}
                        loading={loading}
                        loadingText={language === 'es' ? constants.translationsEs.loading : constants.translationsEn.loading}
                    />
                </Grid>
            </Grid>
            {showServiceDropdown && (
                <Grid container item spacing={1} justify='space-between'>
                    <Grid item xs={12}>
                        <Autocomplete
                            id='service'
                            options={services}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) =>
                                value.value === option.value
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={
                                        language === 'es'
                                            ? constants.translationsEs.service
                                            : constants.translationsEn.service
                                    }
                                    variant='outlined'
                                />
                            )}
                            onChange={handleServiceChange}
                            value={selectedService}
                            size='small'
                            disabled={selectedCustomer === null || selectedContract === null}
                            loading={loading}
                            loadingText={language === 'es' ? constants.translationsEs.loading : constants.translationsEn.loading}
                        />
                    </Grid>
                </Grid>
            )}
            {showDatePickers && (
                <Grid container item spacing={1} justify='space-between'>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={language === 'es' ? es : enGB}
                    >
                        <Grid item xs={6}>
                            <DatePicker
                                value={startDate}
                                onChange={handleStartDateChange}
                                disableFuture
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.startDate
                                        : constants.translationsEn.startDate
                                }
                                format={
                                    language === 'es'
                                        ? constants.translationsEs.dateFormat
                                        : constants.translationsEn.dateFormat
                                }
                                TextFieldComponent={TextFieldComp}
                                disabled={loadingAuth || loading}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                value={endDate}
                                onChange={handleEndDateChange}
                                maxDate={maxEndDate}
                                minDate={startDate}
                                maxDateMessage={
                                    language === 'es'
                                        ? constants.translationsEs.errorMaxDate
                                        : constants.translationsEn.errorMaxDate
                                }
                                minDateMessage={
                                    language === 'es'
                                        ? constants.translationsEs.errorMinDate
                                        : constants.translationsEn.errorMinDate
                                }
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.endDate
                                        : constants.translationsEn.endDate
                                }
                                format={
                                    language === 'es'
                                        ? constants.translationsEs.dateFormat
                                        : constants.translationsEn.dateFormat
                                }
                                TextFieldComponent={TextFieldComp}
                                disabled={loadingAuth || loading}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )}
        </Grid>
    )
}

export default ControlappFilters
