import * as axios from "axios"
const apiLocation = process.env.REACT_APP_HOST_API

export const login = (username, password) => {
    return axios.post(
        apiLocation + "auth/login",
        {
            username,
            password,
        },
        { skipAuthRefresh: true }
    )
}

export const logout = () => {
    return axios.post(apiLocation + "auth/logout", { skipAuthRefresh: true })
}

export const refreshConfiguration = () => {
    return axios.post(apiLocation + "auth/refresh", { skipAuthRefresh: true })
}
