import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import jwtDecode from 'jwt-decode'
import * as constants from '../helpers/constants'
import { browserHistory } from '../store/configureStore'
import { Cookies } from 'react-cookie'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.timeout = 90 * 1000
const ApiLocation = process.env.REACT_APP_HOST_API

axios.interceptors.request.use((config) => {
    const cookies = new Cookies()
    const token = cookies.getAll().access_token
    if (token ) {
        config.headers['Authorization'] = 'Bearer ' + token
    } else if (browserHistory.location.pathname !== constants.paths.login){
        browserHistory.push(constants.paths.login)
        browserHistory.go()
    }
    return config
})

const refreshAuthLogic = (failedRequest) =>
    axios
        .post(ApiLocation + 'auth/refreshToken', { skipAuthRefresh: true })
        .then((tokenRefreshResponse) => {
            let token = jwtDecode(tokenRefreshResponse.data).access_token
            const cookies = new Cookies()
            cookies.set('access_token', token)
            failedRequest.response.config.headers['Authorization'] =
                'Bearer ' + token
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject()
        })

createAuthRefreshInterceptor(axios, refreshAuthLogic)

export const runGet = (url, params) => {
    return axios.get(ApiLocation + url, { params })
}

export const runPost = (url, body) => {
    return axios.post(ApiLocation + url, body)
}

export const runPut = (url, body) => {
    return axios.put(ApiLocation + url, body)
}

export const runDelete = (url, params) => {
    return axios.delete(ApiLocation + url, { params })
}
