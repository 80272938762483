import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as generalAccessors from '../store/general/accessors'

const PowerbiHomeScreen = (props) => {
    const powerbiHomeScreens = useSelector(
        generalAccessors.getPowerbiHomeScreens
    )
    const ref = useRef(null)
    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0)
    }, [ref])
    return (
        <div ref={ref}>
            {powerbiHomeScreens.map((element) => {
                const pageName = JSON.parse(element.pivot.metadata).pageName
                return (
                    <iframe
                        title={pageName}
                        key={pageName}
                        src={
                            'https://app.powerbi.com/reportEmbed?reportId=97ecd2f0-6c14-42f4-bffb-decd515fab17&autoAuth=true&ctid=8677ccfe-e65b-4bdc-9eba-0cd7489b8dcf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWV1cm9wZS1ub3J0aC1iLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9&navContentPaneEnabled=false&pageName=' +
                            pageName
                        }
                        width={width}
                        height={width / 2}
                        style={{
                            padding: '2em',
                            backgroundColor: 'white',
                            border: '1px solid grey',
                        }}
                    ></iframe>
                )
            })}
        </div>
    )
}
export default PowerbiHomeScreen
