import { mdiFilePdfBox } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Tooltip, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'

const EvaluationReportLink = (props) => {
    const { evaluationId } = props
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const theme = useTheme()
    const handleEvaluationReportLinkButtonClick = () => {
        dispatch(controlappActions.fetchEvaluationReport(evaluationId))
    }
    return evaluationId ? (
        <Tooltip title={language === 'es' ? constants.translationsEs.downloadPdf : constants.translationsEn.downloadPdf}>
            <Button onClick={handleEvaluationReportLinkButtonClick}>
                <Icon path={mdiFilePdfBox} size={1} color={theme.palette.primary.main} />
            </Button>
        </Tooltip>
    ) : null
}

export default EvaluationReportLink
