import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import * as constants from '../helpers/constants'
import * as generalActions from '../store/general/actions'
import * as generalAccessors from '../store/general/accessors'

const CustomersDataFilter = ( props ) => {
    const { onChangeCustomer } = props
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const customers = useSelector(generalAccessors.getFilteredCustomers)
    const selectedCustomer = useSelector(generalAccessors.getSelectedCustomer)
    const customerChangeDisabled = useSelector(generalAccessors.getCustomerChangeDisabled)
    const handleCustomerChange = (event, newSelectedCustomer) => {
        dispatch(generalActions.selectCustomer(newSelectedCustomer))
        onChangeCustomer()
    }

    return (
        <Autocomplete
            id='customer'
            options={customers}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => value.value === option.value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={
                        language === 'es'
                            ? constants.translationsEs.customer
                            : constants.translationsEn.customer
                    }
                    variant='outlined'
                />
            )}
            value={selectedCustomer}
            onChange={handleCustomerChange}
            size='small'
            disabled={customerChangeDisabled}
            disableClearable
        />
    )
}

export default CustomersDataFilter
