import * as controlappReducers from '../controlapp/reducers'
import * as controlappAccessors from '../controlapp/accessors'
import * as generalAccessors from '../general/accessors'
import { filterByObject } from '../../helpers/objects'
import * as constants from '../../helpers/constants'
import * as notificationActions from '../notification/actions'
import * as controlappApi from './api'
import { downloadFile } from '../../helpers/downloads'
import * as generalReducers from '../general/reducers'

export const selectCenter =
    (centerCode = null) =>
        (dispatch, getState) => {
            let centers = controlappAccessors.getCenters(getState())
            let filteredCenters = []
            let filteredContracts = []
            let filteredServices = []
            if (centerCode) {
                const centerFilter = { center_code: centerCode }
                filteredCenters = filterByObject(centerFilter, centers)
            } else {
                filteredCenters = centers
            }
            dispatch({
                type: controlappReducers.actionTypes.SELECT_CENTER,
                selectedCenter: centerCode ? filteredCenters[0] : null,
            })
            dispatch({
                type: controlappReducers.actionTypes.SELECT_CONTRACT,
                selectedContract: null,
            })
            dispatch({
                type: controlappReducers.actionTypes.SELECT_SERVICE,
                selectedService: null,
            })
            filteredCenters.forEach((center) => {
                center.contracts.forEach((contract) => {
                    filteredContracts.push(contract)
                })
            })
            filteredContracts.forEach((contract) => {
                if (contract.services) {
                    contract.services.forEach((service) => {
                        filteredServices.push(service)
                    })
                }
            })
            dispatch({
                type: controlappReducers.actionTypes.UPDATE_CONTRACTS_DROPDOWN,
                filteredContracts: filteredContracts,
            })
            dispatch({
                type: controlappReducers.actionTypes.UPDATE_SERVICES_DROPDOWN,
                filteredServices: filteredServices,
            })
        }

export const selectContract = (contractCode) => (dispatch, getState) => {
    let filteredContracts = controlappAccessors.getFilteredContracts(getState())
    let filteredServices = []
    if (contractCode) {
        const contractFilter = { contract_code: contractCode }
        filteredContracts = filterByObject(contractFilter, filteredContracts)
    }
    dispatch({
        type: controlappReducers.actionTypes.SELECT_CONTRACT,
        selectedContract: contractCode ? filteredContracts[0] : null,
    })
    dispatch({
        type: controlappReducers.actionTypes.SELECT_SERVICE,
        selectedService: null,
    })
    filteredContracts.forEach((contract) => {
        if (contract.services) {
            contract.services.forEach((service) => {
                filteredServices.push(service)
            })
        }
    })
    dispatch({
        type: controlappReducers.actionTypes.UPDATE_SERVICES_DROPDOWN,
        filteredServices: filteredServices,
    })
}

export const selectService = (serviceCode) => (dispatch, getState) => {
    let filteredServices = controlappAccessors.getFilteredServices(getState())
    if (serviceCode) {
        const serviceFilter = { service_code: serviceCode }
        filteredServices = filterByObject(serviceFilter, filteredServices)
    }
    dispatch({
        type: controlappReducers.actionTypes.SELECT_SERVICE,
        selectedService: serviceCode ? filteredServices[0] : null,
    })
}

export const fetchDropdownData = () => (dispatch, getState) => {
    const viewName = generalAccessors.getViewName(getState())
    dispatch({
        type: controlappReducers.actionTypes.FETCH_DROPDOWN_DATA_REQUEST,
        viewName: viewName,
    })
    dispatch(resetControlappSelectors())
    const language = generalAccessors.getLanguage(getState())
    const selectedCompany = generalAccessors.getSelectedCompany(getState())
    const selectedCustomer = generalAccessors.getSelectedCustomer(getState())
    controlappApi.fetchDropdownData(selectedCompany, selectedCustomer, viewName)
        .then((response) => {
            const centers = response.data.data.centers
            let filteredContracts = []
            let filteredServices = []
            if (centers) {
                centers.forEach((center) => {
                    center.contracts.forEach((contract) => {
                        filteredContracts.push(contract)
                    })
                })
                filteredContracts.forEach((contract) => {
                    if (contract.services) {
                        contract.services.forEach((service) => {
                            filteredServices.push(service)
                        })
                    }
                })
            }
            dispatch({
                type: controlappReducers.actionTypes.FETCH_DROPDOWN_DATA_SUCCESS,
                centers: centers ?? [],
                filteredCenters: centers ?? [],
                filteredContracts: filteredContracts,
                filteredServices: filteredServices,
            })
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorDropdown
                    : constants.translationsEn.errorDropdown
            dispatch({
                type: controlappReducers.actionTypes.FETCH_DROPDOWN_DATA_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const fetchEvaluationReport = (evaluationId) => (dispatch, getState) => {
    const language = generalAccessors.getLanguage(getState())
    dispatch({
        type: controlappReducers.actionTypes.FETCH_EVALUATION_REPORT_REQUEST,
        evaluationId: evaluationId,
    })
    controlappApi.fetchEvaluationReport(evaluationId)
        .then((response) => {
            const file = response.data.file
            const name = response.data.name
            dispatch({
                type: controlappReducers.actionTypes
                    .FETCH_EVALUATION_REPORT_SUCCESS,
            })
            downloadFile(file, name)
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorDownloadEvaluationReport
                    : constants.translationsEn.errorDownloadEvaluationReport
            dispatch({
                type: controlappReducers.actionTypes
                    .FETCH_EVALUATION_REPORT_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const setImageRegisterId = (idRegImage) => (dispatch, getState) => {
    if (!idRegImage) {
        dispatch({
            type: generalReducers.actionTypes.SET_CONTROLAPP_IMAGE_MODAL_OPEN,
            controlappImageModalOpen: false,

        })
        dispatch({
            type: controlappReducers.actionTypes.RESET_IMAGE,
        })
    } else {
        dispatch({
            type: generalReducers.actionTypes.SET_CONTROLAPP_IMAGE_MODAL_OPEN,
            controlappImageModalOpen: true,
        })
        dispatch({
            type: controlappReducers.actionTypes.SET_IMAGE_REGISTER_ID,
            idRegImage: idRegImage,
        })
    }
}

export const fetchImage = (idRegImage) => (dispatch, getState) => {
    const language = generalAccessors.getLanguage(getState())
    const selectedCompany = generalAccessors.getSelectedCompany(getState())
    const selectedCustomer = generalAccessors.getSelectedCustomer(getState())
    dispatch({
        type: controlappReducers.actionTypes.FETCH_IMAGE_REQUEST,
        idRegImage: idRegImage,
        selectedCompany: selectedCompany,
        selectedCustomer: selectedCustomer,
    })
    let errorMessage = language === 'es'
        ? constants.translationsEs.errorDownloadImage
        : constants.translationsEn.errorDownloadImage
    controlappApi.fetchImage(idRegImage, selectedCompany, selectedCustomer)
        .then((response) => {
            const image = response.data
            if (image !== '') {
                dispatch({
                    type: controlappReducers.actionTypes.FETCH_IMAGE_SUCCESS,
                    image: image,
                })
            } else {
                dispatch({
                    type: controlappReducers.actionTypes.FETCH_IMAGE_FAILURE,
                })
                dispatch(setImageRegisterId(null))
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal())
            }
        })
        .catch((error) => {
            dispatch({
                type: controlappReducers.actionTypes.FETCH_IMAGE_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const fetchIncidentData = (incidentId) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_INCIDENT_DETAIL_MODAL_OPEN,
        incidentDetailModalOpen: true,
    })
    dispatch({
        type: controlappReducers.actionTypes.FETCH_INCIDENT_DATA_REQUEST,
        incidentId: incidentId,
    })
    const language = generalAccessors.getLanguage(getState())
    const selectedCompany = generalAccessors.getSelectedCompany(getState())
    const selectedCustomer = generalAccessors.getSelectedCustomer(getState())
    controlappApi.fetchIncidentData(incidentId, selectedCompany, selectedCustomer)
        .then((response) => {
            dispatch({
                type: controlappReducers.actionTypes.FETCH_INCIDENT_DATA_SUCCESS,
                incidentData: response.data,
            })
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorIncidentData
                    : constants.translationsEn.errorIncidentData
            dispatch({
                type: controlappReducers.actionTypes.FETCH_INCIDENT_DATA_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal(false))
        })
}

export const clearIncidentData = () => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.CLEAR_INCIDENT_DATA,
    })
    dispatch({
        type: generalReducers.actionTypes.SET_INCIDENT_DETAIL_MODAL_OPEN,
        incidentDetailModalOpen: false,
    })
}

export const resetControlappSelectors = () => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.RESET_CONTROLAPP_SELECTORS,
    })
}

export const downloadIncidentPdf = () => (dispatch, getState) => {
    const incidentId = controlappAccessors.getIncidentData(getState()).incident.incident_id
    dispatch({
        type: controlappReducers.actionTypes.DOWNLOAD_INCIDENT_PDF_REQUEST,
        incidentId,
    })
    const language = generalAccessors.getLanguage(getState())
    const companyCode = generalAccessors.getSelectedCompany(getState()).company_code
    const customerCode = generalAccessors.getSelectedCustomer(getState()).customer_code
    controlappApi.downloadIncidentPdf(incidentId, companyCode, customerCode)
        .then((response) => {
            const file = response.data.file
            const name = response.data.name
            dispatch({
                type: controlappReducers.actionTypes.DOWNLOAD_INCIDENT_PDF_SUCCESS,
                file: file,
                name: name,
            })
            downloadFile(file, name)
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorDownloadIncidentReport
                    : constants.translationsEn.errorDownloadIncidentReport
            dispatch({
                type: controlappReducers.actionTypes.DOWNLOAD_INCIDENT_PDF_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal(false))
        })
}

export const fetchVisitData = (visitId) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_VISIT_DETAIL_MODAL_OPEN,
        visitDetailModalOpen: true,
    })
    dispatch({
        type: controlappReducers.actionTypes.FETCH_VISIT_DATA_REQUEST,
        visitId: visitId,
    })
    const language = generalAccessors.getLanguage(getState())
    const selectedCompany = generalAccessors.getSelectedCompany(getState())
    const selectedCustomer = generalAccessors.getSelectedCustomer(getState())
    controlappApi.fetchVisitData(visitId, selectedCompany, selectedCustomer)
        .then((response) => {
            dispatch({
                type: controlappReducers.actionTypes.FETCH_VISIT_DATA_SUCCESS,
                visitData: response.data.visit,
            })
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorVisitData
                    : constants.translationsEn.errorVisitData
            dispatch({
                type: controlappReducers.actionTypes.FETCH_VISIT_DATA_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal(false))
        })
}

export const clearVisitData = () => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.CLEAR_VISIT_DATA,
    })
    dispatch({
        type: generalReducers.actionTypes.SET_VISIT_DETAIL_MODAL_OPEN,
        visitDetailModalOpen: false,
    })
}

export const downloadVisitPdf = () => (dispatch, getState) => {
    const visitId = controlappAccessors.getVisitData(getState()).id
    dispatch({
        type: controlappReducers.actionTypes.DOWNLOAD_VISIT_PDF_REQUEST,
        visitId: visitId,
    })
    const language = generalAccessors.getLanguage(getState())
    const companyCode = generalAccessors.getSelectedCompany(getState()).company_code
    const customerCode = generalAccessors.getSelectedCustomer(getState()).customer_code
    controlappApi.downloadVisitPdf(visitId, companyCode, customerCode)
        .then((response) => {
            const file = response.data.file
            const name = response.data.name
            dispatch({
                type: controlappReducers.actionTypes.DOWNLOAD_VISIT_PDF_SUCCESS,
                file: file,
                name: name,
            })
            downloadFile(file, name)
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorDownloadVisitReport
                    : constants.translationsEn.errorDownloadVisitReport
            dispatch({
                type: controlappReducers.actionTypes.DOWNLOAD_VISIT_PDF_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal(false))
        })
}

export const setRoundIncidentData = (roundIncidentData) => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.SET_ROUND_INCIDENT_DATA,
        roundIncidentData: roundIncidentData,
    })
}

export const fetchRoundIncidentImage = (attachmentId) => (dispatch, getState) => {
    const language = generalAccessors.getLanguage(getState())
    dispatch({
        type: controlappReducers.actionTypes.FETCH_ROUND_INCIDENT_IMAGE_REQUEST,
        attachmentId: attachmentId
    })
    controlappApi.fetchRoundIncidentImage(attachmentId)
        .then((response) => {
            if (response.status === 200) {
                const roundIncidentImage = response.data.image
                dispatch({
                    type: controlappReducers.actionTypes.FETCH_ROUND_INCIDENT_IMAGE_SUCCESS,
                    roundIncidentImage: roundIncidentImage,
                })
            } else {
                let errorMessage =
                    language === 'es'
                        ? constants.translationsEs.errorDownloadImage
                        : constants.translationsEn.errorDownloadImage
                dispatch({
                    type: controlappReducers.actionTypes.FETCH_ROUND_INCIDENT_IMAGE_FAILURE,
                })
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal(false))
            }
        })
        .catch((error) => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorDownloadImage
                    : constants.translationsEn.errorDownloadImage
            dispatch({
                type: controlappReducers.actionTypes.FETCH_ROUND_INCIDENT_IMAGE_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal(false))
        })
}

export const resetRoundIncidentData = () => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.RESET_ROUND_INCIDENT_DATA,
    })
}

export const setRoundTasksData = (roundTasksData) => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.SET_ROUND_TASKS_DATA,
        roundTasksData: roundTasksData,
    })
}

export const resetRoundTasksData = () => (dispatch, getState) => {
    dispatch({
        type: controlappReducers.actionTypes.RESET_ROUND_TASKS_DATA,
    })
}

export const fetchRoundTasksData = (tasksRoundId, point) => (dispatch, getState) => {
    const language = generalAccessors.getLanguage(getState())
    const customerCode = generalAccessors.getSelectedCustomer(getState()).customer_code
    dispatch({
        type: controlappReducers.actionTypes.FETCH_ROUND_TASKS_REQUEST,
        tasksRoundId: tasksRoundId,
        point: point,
    })
    controlappApi.fetchRoundTasksData(tasksRoundId, point, customerCode)
        .then((response) => {
            if (response.status === 200) {
                const roundTasks = response.data.tasks
                dispatch({
                    type: controlappReducers.actionTypes.FETCH_ROUND_TASKS_SUCCESS,
                    roundTasks: roundTasks,
                })
            } else {
                let errorMessage =
                    language === 'es'
                        ? constants.translationsEs.errorFetchingRoundTasks
                        : constants.translationsEn.errorFetchingRoundTasks
                dispatch({
                    type: controlappReducers.actionTypes.FETCH_ROUND_TASKS_FAILURE,
                })
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal(false))
            }
        })
        .catch(() => {
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorFetchingRoundTasks
                    : constants.translationsEn.errorFetchingRoundTasks
            dispatch({
                type: controlappReducers.actionTypes.FETCH_ROUND_TASKS_FAILURE,
            })
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal(false))
        })
}
