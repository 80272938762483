const GrupoControlLogo = () => <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 64">
    <g>
        <path fill="#fff" d="m74.9,28.87c0,2.15-.34,3.77-1.02,4.85-1.18,1.84-3.31,2.76-6.4,2.76-1.91,0-3.48-.38-4.74-1.13-1.25-.76-1.88-1.68-1.88-2.78,0-.46.34-.68,1.03-.68h1.34c.31,0,.64.34.99,1.02.14.29.34.48.57.57.24.09.46.18.67.26.46.25,1.17.37,2.14.37,1.45,0,2.5-.38,3.14-1.15.6-.68.9-1.77.9-3.26v-1.77c-1.08,1.68-2.69,2.52-4.85,2.52s-3.79-.72-4.97-2.17c-1.12-1.37-1.68-3.14-1.68-5.31s.58-4.07,1.74-5.5c1.24-1.51,2.96-2.27,5.16-2.27,1.99,0,3.55.84,4.69,2.52v-1.09c0-.46.06-.74.17-.85.11-.11.41-.17.89-.17h1.06c.48,0,.77.06.89.17.11.11.17.4.17.85v12.24Zm-3.26-6.09c0-1.44-.32-2.59-.96-3.47-.7-1-1.75-1.5-3.14-1.5s-2.34.52-3.04,1.56c-.62.9-.93,2.05-.93,3.47s.3,2.54.9,3.44c.7,1.04,1.72,1.56,3.04,1.56s2.44-.51,3.17-1.53c.64-.9.96-2.07.96-3.53Z" />
        <path fill="#fff" d="m87.79,17.41c0,.7-.24,1.05-.72,1.03h-.87c-.67-.02-1.33.19-1.98.63-.66.44-1.14.98-1.45,1.6-.4.81-.59,2.12-.59,3.94v4.99c0,.45-.06.74-.17.85-.11.11-.41.17-.88.17h-1.26c-.47,0-.76-.06-.88-.17-.11-.11-.17-.4-.17-.85v-12.95c0-.46.06-.74.17-.85.11-.11.41-.17.89-.17h1.09c.48,0,.77.05.87.16.1.1.16.39.16.87v1.96c1.18-2.19,2.81-3.29,4.88-3.29.39,0,.65.07.76.2.11.13.17.41.17.82v1.09Z" />
        <path fill="#fff" d="m103.07,25.12c0,1.88-.67,3.35-2.02,4.41-1.24.99-2.84,1.49-4.78,1.49s-3.59-.44-4.75-1.34c-1.28-.99-1.93-2.47-1.93-4.44v-8.6c0-.46.06-.74.17-.85.11-.11.41-.17.88-.17h1.29c.47,0,.76.05.86.16.1.1.15.39.15.87v7.55c0,1.06.05,1.77.16,2.14.19.7.62,1.2,1.29,1.49.67.29,1.39.43,2.16.43,1.15,0,2.04-.66,2.67-1.99.33-.7.5-1.8.5-3.29v-6.34c0-.46.06-.74.17-.85.11-.11.41-.17.88-.17h1.26c.47,0,.76.06.88.17.11.11.17.4.17.85v8.48Z" />
        <path fill="#fff" d="m122.2,23.1c0,2.26-.56,4.11-1.68,5.56-1.22,1.57-2.94,2.36-5.14,2.36s-3.9-.79-5.05-2.36v6.49c0,.46-.06.74-.17.85-.11.11-.41.17-.88.17h-1.26c-.47,0-.76-.06-.88-.17-.11-.11-.17-.4-.17-.85v-18.51c0-.46.06-.74.17-.85.11-.11.41-.17.89-.17h1.09c.48,0,.77.05.87.16.1.1.16.39.16.87v.96c1.2-1.59,2.87-2.39,5-2.39s4,.77,5.28,2.3c1.18,1.45,1.77,3.31,1.77,5.59Zm-3.42.09c0-1.52-.33-2.76-.99-3.72-.77-1.1-1.86-1.66-3.29-1.66s-2.53.54-3.29,1.62c-.64.92-.96,2.14-.96,3.66s.33,2.78.99,3.72c.77,1.06,1.88,1.59,3.35,1.59,1.39,0,2.46-.53,3.23-1.59.64-.94.96-2.15.96-3.62Z" />
        <path fill="#fff" d="m139.88,23.16c0,2.4-.68,4.31-2.05,5.73-1.37,1.42-3.24,2.13-5.62,2.13s-4.25-.71-5.65-2.14c-1.41-1.43-2.11-3.32-2.11-5.68s.69-4.33,2.07-5.79c1.38-1.46,3.26-2.19,5.64-2.19s4.32.71,5.68,2.14c1.37,1.43,2.05,3.36,2.05,5.81Zm-3.42,0c0-1.54-.32-2.78-.96-3.72-.77-1.08-1.87-1.62-3.32-1.62s-2.56.54-3.32,1.62c-.66.94-.99,2.17-.99,3.69s.33,2.77.99,3.69c.77,1.06,1.88,1.59,3.35,1.59s2.52-.53,3.26-1.59c.66-.94.99-2.16.99-3.66Z" />
        <path fill="#fff" d="m164.38,25.61c0,.91-.47,1.93-1.4,3.04-1.3,1.57-3.16,2.36-5.56,2.36s-4.12-.71-5.45-2.13c-1.34-1.42-2-3.29-2-5.61s.67-4.32,2-5.82c1.34-1.5,3.18-2.25,5.54-2.25,2.88,0,4.92.99,6.12,2.98.44.73.65,1.35.65,1.86,0,.41-.35.62-1.06.62h-1.3c-.54,0-.87-.15-.99-.44-.37-.88-.8-1.5-1.27-1.87-.48-.37-1.17-.55-2.08-.55-1.41,0-2.48.55-3.23,1.66-.64.94-.96,2.16-.96,3.66s.29,2.69.87,3.62c.7,1.1,1.75,1.66,3.14,1.66,2.03,0,3.26-.97,3.7-2.92.08-.33.42-.5,1.03-.5h1.21c.7,0,1.06.21,1.06.62Z" />
        <path fill="#fff" d="m181.84,23.16c0,2.4-.68,4.31-2.05,5.73-1.37,1.42-3.24,2.13-5.62,2.13s-4.25-.71-5.65-2.14c-1.41-1.43-2.11-3.32-2.11-5.68s.69-4.33,2.07-5.79c1.38-1.46,3.26-2.19,5.64-2.19s4.32.71,5.68,2.14c1.37,1.43,2.05,3.36,2.05,5.81Zm-3.42,0c0-1.54-.32-2.78-.96-3.72-.77-1.08-1.87-1.62-3.32-1.62s-2.56.54-3.32,1.62c-.66.94-.99,2.17-.99,3.69s.33,2.77.99,3.69c.77,1.06,1.88,1.59,3.35,1.59s2.52-.53,3.26-1.59c.66-.94.99-2.16.99-3.66Z" />
        <path fill="#fff" d="m198.36,29.59c0,.46-.06.74-.17.85-.11.11-.41.17-.88.17h-1.29c-.45,0-.73-.06-.85-.17-.11-.11-.17-.4-.17-.85v-7.7c0-1.1-.1-1.91-.31-2.45-.38-.97-1.27-1.46-2.7-1.46-1.57,0-2.65.64-3.26,1.93-.33.7-.5,1.77-.5,3.2v6.49c0,.46-.06.74-.17.85-.11.11-.41.17-.88.17h-1.26c-.47,0-.76-.06-.88-.17-.11-.11-.17-.4-.17-.85v-12.95c0-.46.06-.74.17-.85.11-.11.41-.17.89-.17h1.09c.48,0,.77.05.87.16.1.1.16.39.16.87v1.18c1.33-1.74,3.02-2.61,5.09-2.61s3.57.7,4.44,2.11c.52.83.78,2.07.78,3.73v8.54Z" />
        <path fill="#fff" d="m209.64,17.01c0,.46-.06.74-.17.85-.11.11-.41.17-.89.17h-2.04v12.3c0,.23-.63.34-1.9.34-.97,0-1.46-.09-1.46-.28v-12.36h-1.49c-.48,0-.78-.05-.9-.16-.12-.1-.19-.39-.19-.87v-.37c0-.48.06-.77.19-.87s.42-.16.9-.16h1.49v-3.48c0-.46.06-.74.17-.85.11-.11.39-.17.85-.17h1.29c.47,0,.76.06.88.17.11.11.17.4.17.85v3.48h2.04c.48,0,.78.06.89.17.12.11.17.4.17.85v.37Z" />
        <path fill="#fff" d="m220.91,17.41c0,.7-.24,1.05-.72,1.03h-.87c-.67-.02-1.33.19-1.98.63-.66.44-1.14.98-1.45,1.6-.4.81-.59,2.12-.59,3.94v4.99c0,.45-.06.74-.17.85-.11.11-.41.17-.88.17h-1.26c-.47,0-.76-.06-.88-.17-.11-.11-.17-.4-.17-.85v-12.95c0-.46.06-.74.17-.85.11-.11.41-.17.89-.17h1.09c.48,0,.77.05.87.16.1.1.16.39.16.87v1.96c1.18-2.19,2.81-3.29,4.88-3.29.39,0,.65.07.76.2.11.13.17.41.17.82v1.09Z" />
        <path fill="#fff" d="m237.38,23.16c0,2.4-.68,4.31-2.05,5.73-1.37,1.42-3.24,2.13-5.62,2.13s-4.25-.71-5.65-2.14c-1.41-1.43-2.11-3.32-2.11-5.68s.69-4.33,2.07-5.79c1.38-1.46,3.26-2.19,5.64-2.19s4.32.71,5.68,2.14c1.37,1.43,2.05,3.36,2.05,5.81Zm-3.42,0c0-1.54-.32-2.78-.96-3.72-.77-1.08-1.87-1.62-3.32-1.62s-2.56.54-3.32,1.62c-.66.94-.99,2.17-.99,3.69s.33,2.77.99,3.69c.77,1.06,1.88,1.59,3.35,1.59s2.52-.53,3.26-1.59c.66-.94.99-2.16.99-3.66Z" />
        <path fill="#fff" d="m246.45,29.59c0,.5-.11.81-.34.93-.15.08-.51.12-1.09.12-2.67,0-4.15-1.1-4.44-3.29-.06-.45-.09-1.2-.09-2.24v-14.38c0-.46.06-.74.17-.85.11-.11.41-.17.88-.17h1.29c.47,0,.76.05.86.16.1.1.15.39.15.87v13.76c0,1.45,0,2.21.03,2.27.15.77.64,1.15,1.48,1.15.5,0,.81.05.93.16.12.1.17.39.17.87v.65Z" />
    </g>
    <g>
        <path fill="#fff" d="m62.19,46.47c.34,0,.58-.03.73-.1.14-.07.21-.2.21-.39,0-.15-.09-.28-.28-.39-.18-.11-.46-.24-.84-.38-.29-.11-.56-.22-.79-.34-.24-.12-.44-.26-.61-.42-.17-.16-.3-.36-.39-.58-.09-.23-.14-.5-.14-.81,0-.62.23-1.11.69-1.46.46-.36,1.09-.54,1.89-.54.4,0,.78.04,1.15.11.37.07.66.15.88.23l-.33,1.45c-.22-.07-.45-.14-.71-.2s-.54-.09-.86-.09c-.58,0-.88.16-.88.49,0,.07.01.14.04.2.02.06.07.12.15.17.07.05.18.11.31.18.13.06.29.13.49.21.41.15.75.3,1.01.45.27.15.48.31.63.48.15.17.26.36.33.57.06.21.09.45.09.72,0,.65-.24,1.14-.73,1.47-.49.33-1.18.5-2.07.5-.58,0-1.07-.05-1.46-.15-.39-.1-.66-.18-.81-.25l.31-1.51c.32.13.64.22.98.29.33.07.66.11.99.11Z" />
        <path fill="#fff" d="m65.94,44.57c0-.58.09-1.09.27-1.53.18-.44.41-.8.71-1.09s.63-.51,1.01-.66c.38-.15.77-.23,1.17-.23.93,0,1.67.29,2.21.86.54.57.81,1.41.81,2.52,0,.11,0,.23-.01.36,0,.13-.02.24-.02.34h-4.23c.04.38.22.69.54.91s.74.34,1.28.34c.34,0,.68-.03,1.01-.09s.6-.14.81-.23l.25,1.51c-.1.05-.23.1-.4.15-.17.05-.35.09-.56.13s-.42.07-.66.09c-.23.02-.47.04-.7.04-.59,0-1.11-.09-1.54-.26s-.8-.41-1.09-.72c-.29-.3-.5-.66-.64-1.08-.14-.42-.21-.87-.21-1.35Zm4.38-.71c0-.16-.04-.31-.08-.46-.05-.15-.12-.28-.21-.4-.1-.12-.22-.21-.36-.29-.15-.07-.33-.11-.54-.11s-.39.04-.54.11c-.15.07-.28.16-.38.28-.1.12-.18.25-.23.41-.05.15-.09.31-.12.47h2.46Z" />
        <path fill="#fff" d="m79.13,46.98c0,1.08-.27,1.87-.82,2.4-.55.52-1.39.78-2.53.78-.4,0-.8-.04-1.2-.11-.4-.07-.77-.16-1.11-.28l.33-1.56c.29.12.6.21.92.28s.69.1,1.09.1c.53,0,.91-.12,1.13-.35.22-.23.33-.53.33-.9v-.24c-.2.09-.41.16-.62.21-.21.05-.44.07-.69.07-.91,0-1.61-.27-2.09-.81s-.73-1.29-.73-2.26c0-.48.08-.92.23-1.32.15-.4.37-.74.66-1.02.29-.28.64-.5,1.06-.66s.89-.23,1.41-.23c.23,0,.46.01.69.03.24.02.47.05.71.08.23.03.46.07.67.12s.4.09.57.14v5.53Zm-4.09-2.68c0,1.04.42,1.56,1.26,1.56.19,0,.37-.02.54-.07s.31-.11.43-.18v-2.98c-.09-.02-.2-.03-.33-.04s-.27-.02-.44-.02c-.49,0-.86.16-1.1.49-.24.33-.36.74-.36,1.24Z" />
        <path fill="#fff" d="m86.38,47.58c-.32.09-.73.18-1.23.26-.5.08-1.03.12-1.58.12s-1.02-.08-1.39-.23c-.37-.15-.67-.36-.88-.63-.22-.27-.37-.59-.46-.97-.09-.38-.14-.79-.14-1.24v-3.67h1.86v3.44c0,.6.08,1.03.24,1.3.16.27.45.4.89.4.13,0,.28,0,.43-.02.15-.01.28-.03.4-.04v-5.08h1.86v6.35Z" />
        <path fill="#fff" d="m91.99,42.88c-.17-.04-.36-.09-.59-.13-.23-.05-.47-.07-.73-.07-.12,0-.26.01-.42.03-.16.02-.29.04-.37.07v5.03h-1.86v-6.23c.33-.12.73-.23,1.18-.33.45-.1.96-.16,1.52-.16.1,0,.22,0,.36.02.14.01.28.03.43.05s.28.05.43.07c.14.03.26.06.36.11l-.31,1.54Z" />
        <path fill="#fff" d="m95.23,39.32c0,.34-.11.61-.33.81-.22.2-.48.29-.78.29s-.56-.1-.78-.29c-.22-.2-.33-.46-.33-.81s.11-.61.33-.81c.22-.2.48-.29.78-.29s.56.1.78.29c.22.2.33.47.33.81Zm-.18,8.48h-1.86v-6.58h1.86v6.58Z" />
        <path fill="#fff" d="m102.49,47.59c-.17.05-.36.1-.58.14-.22.05-.44.09-.68.12-.24.03-.48.06-.73.08-.25.02-.48.03-.71.03-.54,0-1.03-.08-1.45-.24-.43-.16-.78-.39-1.08-.68s-.51-.65-.67-1.08-.23-.89-.23-1.42.07-1.02.2-1.45c.13-.43.33-.79.58-1.09s.56-.53.92-.69c.36-.16.78-.24,1.24-.24.26,0,.49.02.69.07.2.05.41.12.62.21v-2.98l1.86-.3v9.5Zm-4.22-3.13c0,.58.13,1.04.39,1.39s.64.53,1.15.53c.17,0,.32,0,.46-.02.14-.01.26-.03.35-.04v-3.39c-.12-.07-.27-.14-.46-.19-.19-.05-.38-.08-.57-.08-.88,0-1.33.6-1.33,1.8Z" />
        <path fill="#fff" d="m106.5,41.05c.55,0,1.01.06,1.38.19s.66.3.88.54c.22.23.38.52.47.85s.14.7.14,1.11v3.88c-.27.06-.64.13-1.11.21-.48.08-1.05.12-1.73.12-.43,0-.81-.04-1.16-.11-.35-.07-.64-.2-.89-.37-.25-.17-.44-.39-.58-.67-.13-.28-.2-.61-.2-1.01s.08-.71.23-.98c.15-.27.36-.48.62-.64s.55-.27.89-.34c.33-.07.68-.11,1.04-.11.24,0,.46.01.64.03.19.02.34.05.46.08v-.18c0-.32-.1-.57-.29-.76-.19-.19-.53-.29-1-.29-.32,0-.63.02-.94.07-.31.05-.58.11-.8.19l-.24-1.5c.11-.03.24-.07.41-.11.16-.04.34-.07.53-.1.19-.03.39-.05.61-.07s.43-.03.64-.03Zm.15,5.45c.18,0,.36,0,.53-.01.17,0,.3-.02.4-.04v-1.41c-.07-.02-.19-.03-.34-.05-.15-.02-.29-.02-.41-.02-.18,0-.34.01-.49.03s-.29.06-.41.12c-.12.06-.21.14-.28.24s-.1.23-.1.38c0,.29.1.49.29.61.2.11.46.17.81.17Z" />
        <path fill="#fff" d="m116.74,47.59c-.17.05-.36.1-.58.14-.22.05-.44.09-.68.12-.24.03-.48.06-.73.08-.25.02-.48.03-.71.03-.54,0-1.03-.08-1.45-.24-.43-.16-.78-.39-1.08-.68s-.51-.65-.67-1.08-.23-.89-.23-1.42.07-1.02.2-1.45c.13-.43.33-.79.58-1.09s.56-.53.92-.69c.36-.16.78-.24,1.24-.24.26,0,.49.02.69.07.2.05.41.12.62.21v-2.98l1.86-.3v9.5Zm-4.22-3.13c0,.58.13,1.04.39,1.39s.64.53,1.15.53c.17,0,.32,0,.46-.02.14-.01.26-.03.35-.04v-3.39c-.12-.07-.27-.14-.46-.19-.19-.05-.38-.08-.57-.08-.88,0-1.33.6-1.33,1.8Z" />
        <path fill="#fff" d="m122.56,41.59c.14-.09.36-.19.64-.29s.62-.15.99-.15c.47,0,.88.08,1.24.25s.67.4.92.7c.25.3.44.66.57,1.08.13.42.19.88.19,1.38,0,.53-.08,1-.23,1.42s-.37.78-.66,1.07-.63.52-1.03.68-.85.24-1.35.24c-.54,0-1.02-.04-1.44-.11-.42-.07-.76-.15-1.03-.23v-9.32l1.16-.2v3.49Zm0,5.2c.12.03.28.06.49.09.21.03.48.04.79.04.63,0,1.13-.21,1.5-.62.38-.41.56-1,.56-1.76,0-.33-.03-.65-.1-.94-.07-.29-.18-.54-.33-.76-.15-.21-.34-.38-.58-.5-.24-.12-.52-.18-.86-.18s-.61.05-.88.16c-.27.11-.47.22-.61.34v4.12Z" />
        <path fill="#fff" d="m127.73,49.02c.09.04.21.08.36.12.15.04.29.06.43.06.46,0,.82-.1,1.08-.31.26-.2.49-.54.7-.99-.53-1-1.02-2.06-1.47-3.18-.45-1.12-.83-2.26-1.13-3.41h1.25c.09.38.2.78.33,1.21.13.43.27.88.43,1.34s.33.92.51,1.38c.18.46.38.9.58,1.33.32-.88.59-1.74.83-2.6.23-.86.45-1.74.66-2.65h1.2c-.3,1.23-.63,2.4-1,3.53s-.76,2.19-1.19,3.17c-.17.38-.34.7-.52.97-.18.27-.38.49-.59.67-.22.18-.46.3-.73.39-.27.08-.58.13-.92.13-.09,0-.19,0-.29-.02-.1-.01-.2-.03-.29-.05-.1-.02-.18-.04-.26-.07-.08-.02-.14-.05-.17-.06l.21-.94Z" />
        <path fill="#fff" d="m140.2,47.96c-.53,0-.99-.08-1.38-.25-.4-.17-.73-.4-1-.7-.27-.3-.47-.66-.61-1.07s-.2-.87-.2-1.37.07-.96.22-1.38c.15-.42.35-.78.62-1.08.27-.3.59-.54.98-.71s.82-.26,1.29-.26c.29,0,.58.02.88.07s.57.13.84.24l-.26.99c-.18-.08-.38-.15-.61-.2-.23-.05-.47-.07-.73-.07-.65,0-1.15.2-1.49.61-.35.41-.52,1.01-.52,1.79,0,.35.04.67.12.96.08.29.2.54.38.75.17.21.39.37.66.48.27.11.59.17.98.17.31,0,.59-.03.84-.09s.45-.12.59-.19l.16.98c-.07.04-.16.08-.29.12-.13.04-.27.07-.43.1s-.33.05-.51.07c-.18.02-.35.03-.52.03Z" />
        <path fill="#fff" d="m148.66,44.55c0,.52-.08.98-.23,1.4-.15.42-.36.78-.63,1.08-.27.3-.59.53-.97.69-.38.16-.78.24-1.23.24s-.85-.08-1.23-.24c-.38-.16-.7-.39-.97-.69-.27-.3-.48-.66-.63-1.08-.15-.42-.23-.88-.23-1.4s.07-.97.23-1.4c.15-.42.36-.78.63-1.08.27-.3.59-.53.97-.69.38-.16.78-.24,1.23-.24s.85.08,1.23.24c.38.16.7.39.97.69.27.3.48.66.63,1.08.15.42.23.89.23,1.4Zm-1.21,0c0-.73-.16-1.32-.49-1.75-.33-.43-.78-.64-1.34-.64s-1.02.21-1.34.64c-.33.43-.49,1.01-.49,1.75s.16,1.32.49,1.75.78.64,1.34.64,1.02-.21,1.34-.64.49-1.01.49-1.75Z" />
        <path fill="#fff" d="m152.58,41.16c.1,0,.21,0,.34.02.13.01.26.03.38.05.13.02.24.04.34.06.1.02.18.04.23.06l-.2,1.01c-.09-.03-.24-.07-.46-.12-.21-.05-.49-.07-.82-.07-.22,0-.43.02-.64.07-.21.05-.35.08-.42.09v5.47h-1.16v-6.23c.27-.1.62-.19,1.03-.28.41-.09.87-.13,1.38-.13Z" />
        <path fill="#fff" d="m160.63,44.57c0,.49-.06.95-.19,1.36-.13.42-.32.78-.57,1.08-.25.3-.56.53-.92.7-.36.17-.78.25-1.24.25-.38,0-.71-.05-.99-.15s-.5-.2-.64-.29v2.6h-1.16v-8.62c.27-.07.62-.14,1.03-.22.41-.08.89-.12,1.43-.12.5,0,.95.08,1.35.24s.74.38,1.03.68c.28.29.5.65.66,1.07.15.42.23.89.23,1.42Zm-1.21,0c0-.76-.19-1.34-.56-1.75-.38-.41-.88-.61-1.5-.61-.35,0-.62.01-.82.04-.2.02-.35.05-.47.09v4.1c.14.12.35.23.61.34.27.11.56.16.88.16.33,0,.62-.06.86-.18s.43-.29.58-.5c.15-.21.26-.46.33-.75.07-.29.1-.6.1-.93Z" />
        <path fill="#fff" d="m167.9,44.55c0,.52-.08.98-.23,1.4-.15.42-.36.78-.63,1.08-.27.3-.59.53-.97.69-.38.16-.78.24-1.23.24s-.85-.08-1.23-.24c-.38-.16-.7-.39-.97-.69-.27-.3-.48-.66-.63-1.08-.15-.42-.23-.88-.23-1.4s.07-.97.23-1.4c.15-.42.36-.78.63-1.08.27-.3.59-.53.97-.69.38-.16.78-.24,1.23-.24s.85.08,1.23.24c.38.16.7.39.97.69.27.3.48.66.63,1.08.15.42.23.89.23,1.4Zm-1.21,0c0-.73-.16-1.32-.49-1.75-.33-.43-.78-.64-1.34-.64s-1.02.21-1.34.64c-.33.43-.49,1.01-.49,1.75s.16,1.32.49,1.75.78.64,1.34.64,1.02-.21,1.34-.64.49-1.01.49-1.75Z" />
        <path fill="#fff" d="m171.83,41.16c.1,0,.21,0,.34.02.13.01.26.03.38.05.13.02.24.04.34.06.1.02.18.04.23.06l-.2,1.01c-.09-.03-.24-.07-.46-.12-.21-.05-.49-.07-.82-.07-.22,0-.43.02-.64.07-.21.05-.35.08-.42.09v5.47h-1.16v-6.23c.27-.1.62-.19,1.03-.28.41-.09.87-.13,1.38-.13Z" />
        <path fill="#fff" d="m176.24,41.14c.47,0,.86.06,1.18.18.32.12.58.29.78.51.2.22.34.48.42.79.08.3.13.64.13,1.01v4.07c-.1.02-.24.04-.42.07-.18.03-.38.06-.61.08-.23.03-.47.05-.73.07-.26.02-.52.03-.78.03-.37,0-.7-.04-1.01-.11-.31-.07-.58-.19-.8-.36-.23-.16-.4-.38-.53-.64-.13-.27-.19-.59-.19-.96s.07-.67.22-.93c.15-.26.34-.47.59-.63.25-.16.54-.28.88-.35s.68-.11,1.05-.11c.12,0,.24,0,.36.02.13.01.24.03.36.05.11.02.21.04.29.06.08.02.14.03.18.04v-.33c0-.19-.02-.38-.06-.57-.04-.19-.12-.35-.23-.5-.11-.15-.26-.26-.44-.35s-.43-.13-.73-.13c-.38,0-.72.03-1.01.08-.29.05-.5.11-.64.17l-.14-.96c.15-.07.4-.13.75-.19.35-.06.73-.09,1.14-.09Zm.1,5.83c.28,0,.52,0,.73-.02s.39-.04.53-.07v-1.94c-.08-.04-.22-.08-.41-.11s-.42-.04-.68-.04c-.18,0-.36.01-.56.04-.2.02-.38.08-.54.16-.16.08-.3.19-.41.33s-.16.32-.16.54c0,.42.13.71.4.87.27.16.63.24,1.09.24Z" />
        <path fill="#fff" d="m183.38,47.96c-.53,0-.99-.08-1.38-.25-.4-.17-.73-.4-1-.7-.27-.3-.47-.66-.61-1.07s-.2-.87-.2-1.37.07-.96.22-1.38c.15-.42.35-.78.62-1.08.27-.3.59-.54.98-.71s.82-.26,1.29-.26c.29,0,.58.02.88.07s.57.13.84.24l-.26.99c-.18-.08-.38-.15-.61-.2-.23-.05-.47-.07-.73-.07-.65,0-1.15.2-1.49.61-.35.41-.52,1.01-.52,1.79,0,.35.04.67.12.96.08.29.2.54.38.75.17.21.39.37.66.48.27.11.59.17.98.17.31,0,.59-.03.84-.09s.45-.12.59-.19l.16.98c-.07.04-.16.08-.29.12-.13.04-.27.07-.43.1s-.33.05-.51.07c-.18.02-.35.03-.52.03Z" />
        <path fill="#fff" d="m186.84,40.12c-.21,0-.39-.07-.53-.21-.15-.14-.22-.32-.22-.56s.07-.42.22-.56c.15-.14.32-.21.53-.21s.39.07.53.21c.15.14.22.32.22.56s-.07.42-.22.56c-.15.14-.32.21-.53.21Zm.59,7.68h-1.16v-6.51h1.16v6.51Z" />
        <path fill="#fff" d="m195.06,44.55c0,.52-.08.98-.23,1.4-.15.42-.36.78-.63,1.08-.27.3-.59.53-.97.69-.38.16-.78.24-1.23.24s-.85-.08-1.23-.24c-.38-.16-.7-.39-.97-.69-.27-.3-.48-.66-.63-1.08-.15-.42-.23-.88-.23-1.4s.07-.97.23-1.4c.15-.42.36-.78.63-1.08.27-.3.59-.53.97-.69.38-.16.78-.24,1.23-.24s.85.08,1.23.24c.38.16.7.39.97.69.27.3.48.66.63,1.08.15.42.23.89.23,1.4Zm-1.21,0c0-.73-.16-1.32-.49-1.75-.33-.43-.78-.64-1.34-.64s-1.02.21-1.34.64c-.33.43-.49,1.01-.49,1.75s.16,1.32.49,1.75.78.64,1.34.64,1.02-.21,1.34-.64.49-1.01.49-1.75Zm-.33-5.72l-1.76,1.71-.59-.53,1.6-1.93.75.74Z" />
        <path fill="#fff" d="m196.58,41.49c.27-.07.62-.14,1.06-.21.44-.07.95-.11,1.53-.11.52,0,.95.07,1.29.22.34.15.61.35.82.61s.35.58.43.94c.08.37.13.77.13,1.21v3.65h-1.16v-3.4c0-.4-.03-.74-.08-1.03-.05-.28-.14-.51-.27-.69-.13-.18-.29-.3-.5-.38-.21-.08-.47-.12-.78-.12-.13,0-.25,0-.39.01-.13,0-.26.02-.38.03-.12.01-.23.03-.33.04-.1.02-.16.03-.21.04v5.49h-1.16v-6.32Z" />
        <path fill="#fff" d="m210.63,38.3l1.16-.2v9.52c-.27.08-.61.15-1.03.23s-.9.11-1.44.11c-.5,0-.95-.08-1.35-.24s-.74-.38-1.03-.68c-.28-.29-.5-.65-.66-1.07-.15-.42-.23-.89-.23-1.42s.06-.96.19-1.38c.13-.42.32-.78.57-1.08.25-.3.56-.53.92-.7s.78-.25,1.24-.25c.38,0,.71.05.99.15.29.1.5.2.64.29v-3.29Zm0,4.38c-.14-.12-.35-.23-.61-.34-.27-.11-.56-.16-.88-.16-.33,0-.62.06-.86.18-.24.12-.43.29-.58.5s-.26.46-.33.76-.1.6-.1.94c0,.76.19,1.34.56,1.76.38.41.88.62,1.5.62.32,0,.58-.01.79-.04.21-.03.38-.06.49-.09v-4.12Z" />
        <path fill="#fff" d="m214.26,40.12c-.21,0-.39-.07-.53-.21-.15-.14-.22-.32-.22-.56s.07-.42.22-.56c.15-.14.32-.21.53-.21s.39.07.53.21c.15.14.22.32.22.56s-.07.42-.22.56c-.15.14-.32.21-.53.21Zm.59,7.68h-1.16v-6.51h1.16v6.51Z" />
        <path fill="#fff" d="m216.74,41.49c.27-.07.62-.14,1.06-.21.44-.07.94-.11,1.52-.11.42,0,.77.06,1.05.17.28.11.52.28.71.49.06-.04.15-.1.28-.18s.28-.15.46-.22c.18-.07.39-.13.61-.19.23-.05.47-.08.73-.08.5,0,.91.07,1.23.22.32.15.57.35.74.62.18.27.3.58.36.95.06.37.09.77.09,1.2v3.65h-1.16v-3.4c0-.38-.02-.71-.06-.99-.04-.27-.11-.5-.21-.69-.1-.18-.25-.32-.43-.41-.18-.09-.41-.13-.69-.13-.39,0-.71.05-.97.16-.25.1-.43.2-.52.28.07.22.12.45.15.71.03.26.05.53.05.81v3.65h-1.16v-3.4c0-.38-.02-.71-.06-.99-.04-.27-.11-.5-.22-.69-.1-.18-.25-.32-.43-.41s-.41-.13-.68-.13c-.12,0-.24,0-.38.01-.13,0-.26.02-.38.03-.12.01-.23.03-.33.04s-.17.03-.2.04v5.49h-1.16v-6.32Z" />
        <path fill="#fff" d="m233.14,44.55c0,.52-.08.98-.23,1.4-.15.42-.36.78-.63,1.08-.27.3-.59.53-.97.69-.38.16-.78.24-1.23.24s-.85-.08-1.23-.24c-.38-.16-.7-.39-.97-.69-.27-.3-.48-.66-.63-1.08-.15-.42-.23-.88-.23-1.4s.07-.97.23-1.4c.15-.42.36-.78.63-1.08.27-.3.59-.53.97-.69.38-.16.78-.24,1.23-.24s.85.08,1.23.24c.38.16.7.39.97.69.27.3.48.66.63,1.08.15.42.23.89.23,1.4Zm-1.21,0c0-.73-.16-1.32-.49-1.75-.33-.43-.78-.64-1.34-.64s-1.02.21-1.34.64c-.33.43-.49,1.01-.49,1.75s.16,1.32.49,1.75.78.64,1.34.64,1.02-.21,1.34-.64.49-1.01.49-1.75Z" />
        <path fill="#fff" d="m235.83,41.59c.14-.09.36-.19.64-.29s.62-.15.99-.15c.47,0,.88.08,1.24.25s.67.4.92.7c.25.3.44.66.57,1.08.13.42.19.88.19,1.38,0,.53-.08,1-.23,1.42s-.37.78-.66,1.07-.63.52-1.03.68-.85.24-1.35.24c-.54,0-1.02-.04-1.44-.11-.42-.07-.76-.15-1.03-.23v-9.32l1.16-.2v3.49Zm0,5.2c.12.03.28.06.49.09.21.03.48.04.79.04.63,0,1.13-.21,1.5-.62.38-.41.56-1,.56-1.76,0-.33-.03-.65-.1-.94-.07-.29-.18-.54-.33-.76-.15-.21-.34-.38-.58-.5-.24-.12-.52-.18-.86-.18s-.61.05-.88.16c-.27.11-.47.22-.61.34v4.12Z" />
        <path fill="#fff" d="m244.02,41.14c.47,0,.86.06,1.18.18.32.12.58.29.78.51.2.22.34.48.42.79.08.3.13.64.13,1.01v4.07c-.1.02-.24.04-.42.07-.18.03-.38.06-.61.08-.23.03-.47.05-.73.07-.26.02-.52.03-.78.03-.37,0-.7-.04-1.01-.11-.31-.07-.58-.19-.8-.36-.23-.16-.4-.38-.53-.64-.13-.27-.19-.59-.19-.96s.07-.67.22-.93c.15-.26.34-.47.59-.63.25-.16.54-.28.88-.35s.68-.11,1.05-.11c.12,0,.24,0,.36.02.13.01.24.03.36.05.11.02.21.04.29.06.08.02.14.03.18.04v-.33c0-.19-.02-.38-.06-.57-.04-.19-.12-.35-.23-.5-.11-.15-.26-.26-.44-.35s-.43-.13-.73-.13c-.38,0-.72.03-1.01.08-.29.05-.5.11-.64.17l-.14-.96c.15-.07.4-.13.75-.19.35-.06.73-.09,1.14-.09Zm.1,5.83c.28,0,.52,0,.73-.02s.39-.04.53-.07v-1.94c-.08-.04-.22-.08-.41-.11s-.42-.04-.68-.04c-.18,0-.36.01-.56.04-.2.02-.38.08-.54.16-.16.08-.3.19-.41.33s-.16.32-.16.54c0,.42.13.71.4.87.27.16.63.24,1.09.24Z" />
    </g>
    <path fill="#fff" d="m36.75,51.66c0,2.4-1.95,4.34-4.34,4.34s-4.35-1.95-4.35-4.34,1.95-4.34,4.35-4.34,4.34,1.95,4.34,4.34M20.26,19.64v14.56c0,2.69,2.18,4.86,4.86,4.86h14.56c2.69,0,4.86-2.18,4.86-4.86v-14.56c0-2.69-2.18-4.86-4.86-4.86h-14.56c-2.69,0-4.86,2.18-4.86,4.86m-6.78-.53c0-6.14,4.98-11.12,11.12-11.12h15.61c6.14,0,11.11,4.98,11.11,11.12v15.61c0,6.14-4.98,11.12-11.11,11.12h-15.61c-6.14,0-11.12-4.98-11.12-11.12v-15.61Z" />
</svg>

export default GrupoControlLogo
