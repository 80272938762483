import * as generalAccessors from '../general/accessors'
import * as sarebReducers from './reducers'
import * as notificationActions from '../notification/actions'
import * as api from './api'
import * as constants from '../../helpers/constants'

export const fetchProvinces = () => (dispatch, getState) => {
    dispatch({
        type: sarebReducers.actionTypes.FETCH_PROVINCES_REQUEST,
    })
    dispatch(resetSarebState())
    const language = generalAccessors.getLanguage(getState())
    api.getProvinces()
        .then((response) => {
            dispatch({
                type: sarebReducers.actionTypes.FETCH_PROVINCES_SUCCESS,
                cities: response.data.payload.cities,
                provinces: response.data.payload.provinces,
            })
        })
        .catch((error) => {
            dispatch({
                type: sarebReducers.actionTypes.FETCH_PROVINCES_FAILURE,
            })
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorFetchProvinces
                    : constants.translationsEn.errorFetchProvinces
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const selectProvince = (selectedProvince) => (dispatch, getState) => {
    dispatch({
        type: sarebReducers.actionTypes.SELECT_PROVINCE,
        selectedProvince: selectedProvince,
    })
    if (selectedProvince) {
        dispatch(fetchCities(selectedProvince))
    }
}

export const fetchCities = (selectedProvince) => (dispatch, getState) => {
    dispatch({
        type: sarebReducers.actionTypes.FETCH_CITIES_BY_PROVINCE_REQUEST,
        selectedProvince,
    })
    const language = generalAccessors.getLanguage(getState())
    api.getCities(selectedProvince)
        .then((response) => {
            dispatch({
                type: sarebReducers.actionTypes
                    .FETCH_CITIES_BY_PROVINCE_SUCCESS,
                cities: response.data.payload.cities,
            })
        })
        .catch((error) => {
            dispatch({
                type: sarebReducers.actionTypes
                    .FETCH_CITIES_BY_PROVINCE_FAILURE,
            })
            let errorMessage =
                language === 'es'
                    ? constants.translationsEs.errorFetchCities
                    : constants.translationsEn.errorFetchCities
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
        })
}

export const uploadRegistrationRequestSpreadsheet =
    (uploadFormData) => (dispatch, getState) => {
        const language = generalAccessors.getLanguage(getState())
        dispatch({
            type: sarebReducers.actionTypes
                .UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_REQUEST,
            uploadFormData,
        })
        api.uploadRegistrationRequestSpreadsheet(uploadFormData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: sarebReducers.actionTypes
                            .UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_SUCCESS,
                    })
                    let successMessage =
                        language === 'es'
                            ? constants.translationsEs
                                  .successUploadRegistrationRequestSpreadsheet
                            : constants.translationsEn
                                  .successUploadRegistrationRequestSpreadsheet
                    dispatch(notificationActions.setSuccess(successMessage))
                    dispatch(notificationActions.showModal())
                    dispatch(setBlockFormSubmission(true))
                }
            })
            .catch((error) => {
                dispatch({
                    type: sarebReducers.actionTypes
                        .UPLOAD_REGISTRATION_REQUEST_SPREADSHEET_FAILURE,
                })
                let errorMessage = null
                if (error.response.status === 422) {
                    errorMessage = Object.values(
                        error.response.data.errors
                    ).map((error) => {
                        return language === 'es'
                            ? constants.translationsEs[error[0]] + '\n'
                            : constants.translationsEn[error[0]] + '\n'
                    })
                } else {
                    errorMessage =
                        language === 'es'
                            ? constants.translationsEs
                                  .errorUploadRegistrationRequestSpreadsheet
                            : constants.translationsEn
                                  .errorUploadRegistrationRequestSpreadsheet
                }
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal())
            })
    }

export const submitRegistrationRequestForm =
    (registrationRequestFormData) => (dispatch, getState) => {
        const language = generalAccessors.getLanguage(getState())
        dispatch({
            type: sarebReducers.actionTypes
                .SUBMIT_REGISTRATION_REQUEST_FORM_REQUEST,
            registrationRequestFormData,
        })
        api.postRegistrationRequestForm(registrationRequestFormData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: sarebReducers.actionTypes
                            .SUBMIT_REGISTRATION_REQUEST_FORM_SUCCESS,
                    })
                    let successMessage =
                        language === 'es'
                            ? constants.translationsEs
                                  .successSubmitRegistrationRequestForm
                            : constants.translationsEn
                                  .successSubmitRegistrationRequestForm
                    dispatch(notificationActions.setSuccess(successMessage))
                    dispatch(notificationActions.showModal())
                    dispatch(setBlockFormSubmission(true))
                }
            })
            .catch((error) => {
                dispatch({
                    type: sarebReducers.actionTypes
                        .SUBMIT_REGISTRATION_REQUEST_FORM_FAILURE,
                })
                let errorMessage = null
                if (error.response && error.response.status === 422) {
                    errorMessage = Object.values(
                        error.response.data.errors
                    ).map((error) => {
                        return language === 'es'
                            ? constants.translationsEs[error[0]] + '\n'
                            : constants.translationsEn[error[0]] + '\n'
                    })
                } else {
                    errorMessage =
                        language === 'es'
                            ? constants.translationsEs
                                  .errorSubmitRegistrationRequestForm
                            : constants.translationsEn
                                  .errorSubmitRegistrationRequestForm
                }
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal())
            })
    }

export const setBlockFormSubmission =
    (blockFormSubmission) => (dispatch, getState) => {
        dispatch({
            type: sarebReducers.actionTypes.SET_BLOCK_FORM_SUBMISSION,
            blockFormSubmission: blockFormSubmission,
        })
    }

export const resetSarebState = () => (dispatch, getState) => {
    dispatch({
        type: sarebReducers.actionTypes.RESET_SAREB_STATE,
    })
}
