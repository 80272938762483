import { useEffect } from 'react'
import { Cancel } from '@mui/icons-material'
import { useTheme } from '@material-ui/core/styles'
import { Box, Button, Card, CardContent, LinearProgress, Modal, ThemeProvider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappAccessors from '../../store/controlapp/accessors'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'

const ControlappImageModal = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const language = useSelector(generalAccessors.getLanguage)
    const idRegImage = useSelector(controlappAccessors.getIdRegImage)
    const image = useSelector(controlappAccessors.getImage)
    const open = useSelector(generalAccessors.getControlappImageModalOpen)
    const loading = useSelector(controlappAccessors.getLoadingIncidentImage)
    const handleControlappImageModalClose = () => {
        dispatch(controlappActions.setImageRegisterId(null))
    }
    useEffect(() => {
        if (idRegImage) {
            dispatch(controlappActions.fetchImage(idRegImage))
        }
    }, [dispatch, idRegImage])
    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleControlappImageModalClose}
            >
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        boxShadow: 24,
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    style={{ backgroundColor: theme.palette.primary.main }}
                >
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography color={theme.palette.background.default} variant='h4'>{language === 'es' ? constants.translationsEs.image : constants.translationsEn.image}</Typography>
                    </CardContent>
                    {image && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={image} alt={language === 'es' ? constants.translationsEs.image + ' ' + constants.translationsEs.incident : constants.translationsEn.incident + ' ' + constants.translationsEn.image} style={{ maxHeight: '500px', maxWidth: '500px', height: 'auto', width: 'auto' }} />
                            </Box>
                        </CardContent>
                    )}
                    {loading && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.loadingRoundIncidentImage : constants.translationsEn.loadingRoundIncidentImage}</Typography>
                            <LinearProgress sx={{ mt: 5, mb: 5 }} color='primary' />
                        </CardContent>
                    )}
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.background.default }}>
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={handleControlappImageModalClose}
                            startIcon={<Cancel />}
                        >
                            {language === 'es' ? constants.translationsEs.close : constants.translationsEn.close}
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </ThemeProvider>
    )
}

export default ControlappImageModal
