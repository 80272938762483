import jwtDecode from 'jwt-decode'
import { Cookies } from 'react-cookie'

import * as constants from '../../helpers/constants'
import configureStore, { browserHistory } from '../../store/configureStore'
import * as authApi from './api'
import * as authReducers from './reducers'
import * as generalAccessors from '../general/accessors'
import * as generalActions from '../general/actions'
import * as notificationActions from '../notification/actions'
import * as authAccessors from '../auth/accessors'

export const login = (username, password) => (dispatch, getState) => {
    dispatch({
        type: authReducers.actionTypes.LOGIN_REQUEST,
        username,
        password,
    })
    const cookies = new Cookies()
    return authApi
        .login(username, password)
        .then((response) => {
            const companies = jwtDecode(response.data).data
            const language = jwtDecode(response.data).language.locale
            const userName = jwtDecode(response.data).username
            const accessToken = jwtDecode(response.data).access_token
            const screens = jwtDecode(response.data).screens
            const expiresIn = jwtDecode(response.data).expires_in
            let now = new Date()
            const expires = new Date(now.setSeconds(now.getSeconds() + expiresIn))
            const selectedCompany = companies[0]
            dispatch({
                type: authReducers.actionTypes.LOGIN_SUCCESS,
                token: response.data,
                expires: expires,
            })
            dispatch(generalActions.setConfiguration(language, companies, userName, screens))
            dispatch(generalActions.selectCompany(selectedCompany))
            cookies.set('access_token', accessToken)
            browserHistory.push(constants.paths.home)
        })
        .catch((error) => {
            dispatch({
                type: authReducers.actionTypes.LOGIN_FAILURE,
            })
            let language = 'en'
            if (!navigator.language.startsWith('en')) {
                language = 'es'
            }
            let errorMessage = language === 'es'
                ? constants.translationsEs.errorGeneral
                : constants.translationsEn.errorGeneral
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        errorMessage = language === 'es'
                            ? constants.translationsEs.errorLogin
                            : constants.translationsEn.errorLogin
                        break
                    default:
                        break;
                }
            }
            dispatch(notificationActions.setError(errorMessage))
            dispatch(notificationActions.showModal())
            cookies.remove('access_token')
        })
}

export const refreshConfiguration = () => (dispatch, getState) => {
    dispatch({
        type: authReducers.actionTypes.REFRESH_CONFIGURATION_REQUEST,
    })
    const expired = authAccessors.getExpired(getState())
    if (!expired) {
        const cookies = new Cookies()
        return authApi
            .refreshConfiguration()
            .then((response) => {
                const companies = jwtDecode(response.data).data
                const accessToken = jwtDecode(response.data).access_token
                const screens = jwtDecode(response.data).screens
                const language = generalAccessors.getLanguage(getState()) // When language is changed in the back-end when switching language, revert this change to correctly update the configuration
                const userName = jwtDecode(response.data).username
                const expiresIn = jwtDecode(response.data).expires_in
                let now = new Date()
                const expires = new Date(now.setSeconds(now.getSeconds() + expiresIn))
                dispatch({
                    type: authReducers.actionTypes.REFRESH_CONFIGURATION_SUCCESS,
                    token: response.data,
                    expires: expires,
                })
                dispatch(generalActions.setConfiguration(language, companies, userName, screens))
                dispatch(generalActions.selectCompany())
                cookies.set('access_token', accessToken)
                return Promise.resolve()
            })
            .catch((error) => {
                dispatch({
                    type: authReducers.actionTypes.REFRESH_CONFIGURATION_FAILURE,
                })
                const language = generalAccessors.getLanguage(getState())
                let errorMessage = language === 'es'
                    ? constants.translationsEs.errorRefreshConfiguration
                    : constants.translationsEn.errorRefreshConfiguration
                if (
                    error.response &&
                    error.response.status === 400 &&
                    error.response.data === 'refresh token expired'
                ) {
                    errorMessage = language === 'es'
                        ? constants.translationsEs.errorSessionExpired
                        : constants.translationsEn.errorSessionExpired
                    dispatch(logout())
                }
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal())
                return Promise.reject()
            })
    } else {
        const language = generalAccessors.getLanguage(getState())
        dispatch(notificationActions.setError(
            language === 'es'
                ? constants.translationsEs.errorSessionExpired
                : constants.translationsEn.errorSessionExpired
        )
        )
        dispatch(notificationActions.showModal())
        return Promise.reject()
    }
}

export const logout = () => (dispatch, getState) => {
    const cookies = new Cookies()
    cookies.remove('access_token')
    const { persistor } = configureStore()
    persistor.pause();
    persistor.flush().then(() => {
        return persistor.purge()
    })
    browserHistory.push(constants.paths.login)
}

export const resetAuthState = () => (dispatch, getState) => {
    dispatch({
        type: authReducers.actionTypes.RESET_AUTH_STATE,
    })
}
