import { actionTypes } from './reducers'
import * as api from './api'
import * as constants from '../../helpers/constants'
import * as generalAccessors from '../general/accessors'
import * as notificationActions from '../notification/actions'

export const selectInstallation =
    (selectedInstallation) => (dispatch, getState) => {
        dispatch({
            type: actionTypes.SELECT_INSTALLATION,
            selectedInstallation: selectedInstallation,
        })
    }

export const fetchInstallationsByCustomer =
    (selectedCustomer) => (dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCH_INSTALLATIONS_BY_CUSTOMER_REQUEST,
            selectedCustomer: selectedCustomer,
        })
        dispatch(resetInstallationSelector())
        const language = generalAccessors.getLanguage(getState())
        api.getInstallationsByCustomer(selectedCustomer)
            .then((response) => {
                dispatch({
                    type: actionTypes.FETCH_INSTALLATIONS_BY_CUSTOMER_SUCCESS,
                    installations: response.data.payload.installations,
                })
            })
            .catch((error) => {
                let errorMessage =
                    language === 'es'
                        ? constants.translationsEs
                              .errorFetchInstallationsByCustomer
                        : constants.translationsEn
                              .errorFetchInstallationsByCustomer
                dispatch({
                    type: actionTypes.FETCH_INSTALLATIONS_BY_CUSTOMER_FAILURE,
                })
                dispatch(notificationActions.setError(errorMessage))
                dispatch(notificationActions.showModal())
            })
    }

export const resetInstallationSelector = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.RESET_INSTALLATION_SELECTOR,
    })
}
