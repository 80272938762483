import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { es, enGB } from 'date-fns/locale'
import * as constants from '../helpers/constants'
import * as tableActions from '../store/table/actions'
import * as generalAccessors from '../store/general/accessors'
import * as authAccessors from '../store/auth/accessors'
import * as webcontrolActions from '../store/webcontrol/actions'
import * as webcontrolAccessors from '../store/webcontrol/accessors'
import * as tableAccessors from '../store/table/accessors'

const WebcontrolFilters = () => {
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const startDate = useSelector(tableAccessors.getStartDate)
    const endDate = useSelector(tableAccessors.getEndDate)
    const maxEndDate = useSelector(tableAccessors.getMaxEndDate)
    const showDatePickers = useSelector(generalAccessors.getShowDatePickers)
    const installations = useSelector(webcontrolAccessors.getInstallations)
    const selectedInstallation = useSelector(webcontrolAccessors.getSelectedInstallation)
    const loading = useSelector(webcontrolAccessors.getLoading)
    const loadingAuth = useSelector(authAccessors.getLoading)
    const handleInstallationChange = (event, values) => {
        dispatch(
            webcontrolActions.selectInstallation(values)
        )
    }
    const handleStartDateChange = (date) => {
        dispatch(tableActions.selectStartDate(date))
    }
    const handleEndDateChange = (date) => {
        dispatch(tableActions.selectEndDate(date))
    }
    const TextFieldComp = (params) => (
        <TextField {...params} fullWidth size='small' variant='outlined' />
    )
    return (
        <Grid container spacing={1}>
            <Grid container item spacing={1} justify='space-between'>
                <Grid item xs={12}>
                    <Autocomplete
                        id='installation'
                        options={installations}
                        getOptionLabel={(option) => option.subscriber_code + ' - ' + option.address}
                        getOptionSelected={(option, value) =>
                            value.value === option.value
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.installation
                                        : constants.translationsEn.installation
                                }
                                variant='outlined'
                            />
                        )}
                        onChange={handleInstallationChange}
                        value={selectedInstallation}
                        size='small'
                        loading={loading}
                        loadingText={language === 'es' ? constants.translationsEs.loading : constants.translationsEn.loading}
                    />
                </Grid>
            </Grid>
            {showDatePickers && (
                <Grid container item spacing={1} justify='space-between'>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={language === 'es' ? es : enGB}
                    >
                        <Grid item xs={6}>
                            <DatePicker
                                value={startDate}
                                onChange={handleStartDateChange}
                                disableFuture
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.startDate
                                        : constants.translationsEn.startDate
                                }
                                format={
                                    language === 'es'
                                        ? constants.translationsEs.dateFormat
                                        : constants.translationsEn.dateFormat
                                }
                                TextFieldComponent={TextFieldComp}
                                disabled={loadingAuth || loading}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                value={endDate}
                                onChange={handleEndDateChange}
                                maxDate={maxEndDate}
                                minDate={startDate}
                                maxDateMessage={
                                    language === 'es'
                                        ? constants.translationsEs.errorMaxDate
                                        : constants.translationsEn.errorMaxDate
                                }
                                minDateMessage={
                                    language === 'es'
                                        ? constants.translationsEs.errorMinDate
                                        : constants.translationsEn.errorMinDate
                                }
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.endDate
                                        : constants.translationsEn.endDate
                                }
                                format={
                                    language === 'es'
                                        ? constants.translationsEs.dateFormat
                                        : constants.translationsEn.dateFormat
                                }
                                TextFieldComponent={TextFieldComp}
                                disabled={loadingAuth || loading}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )}
        </Grid>
    )
}

export default WebcontrolFilters
