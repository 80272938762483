import * as axios from '../../helpers/axios'
import * as constants from '../../helpers/constants'

export const fetchTableData = (filters, viewName) => {
    switch (viewName) {
        case constants.viewNames.EVENTS:
            return axios.runPost('auth/webcontrol/get_table_data', filters)
        case constants.viewNames.EVALUATIONS:
            return axios.runPost('auth/evaluations/get_evaluation_data', filters)
        case constants.viewNames.INCIDENTS:
            return axios.runPost('auth/incidents/get_data', filters)
        case constants.viewNames.VISITS:
            return axios.runPost('auth/visits/get_table_data', filters)
        case constants.viewNames.ROUNDS:
            return axios.runPost('auth/rounds/get_round_data', filters)
        case constants.viewNames.SURVEILLANCE:
            return axios.runPost(
                'auth/surveillances/get_surveillance_data ',
                filters
            )
        case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
            return axios.runPost(
                'auth/surveillances/get_discontinuous_surveillance_data',
                filters
            )
        case constants.viewNames.KEYRINGS:
            return axios.runPost('auth/trace_app/get_data', filters)
        case constants.viewNames.VISITOR_CONTROL:
            return axios.runPost(
                'auth/visitor_control/visitor_list/get_data',
                filters
            )
        default:
            return
    }
}

export const fetchDocument = (filters, viewName, format) => {
    if (format === constants.formats.PDF) {
        switch (viewName) {
            case constants.viewNames.EVENTS:
                return axios.runPost('auth/webcontrol/download_pdf', filters)
            case constants.viewNames.EVALUATIONS:
                return axios.runPost('auth/evaluations/download_pdf', filters)
            case constants.viewNames.INCIDENTS:
                return axios.runPost('auth/incidents/download_pdf', filters)
            case constants.viewNames.VISITS:
                return axios.runPost('auth/visits/download_pdf', filters)
            case constants.viewNames.ROUNDS:
                return axios.runPost('auth/rounds/download_pdf', filters)
            case constants.viewNames.SURVEILLANCE:
                return axios.runPost('auth/surveillances/download_pdf', filters)
            case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
                return axios.runPost(
                    'auth/discontinous_surveillances/download_pdf',
                    filters
                )
            case constants.viewNames.KEYRINGS:
                return axios.runPost('auth/trace_app/download_pdf', filters)
            case constants.viewNames.VISITOR_CONTROL:
                return axios.runPost(
                    'auth/visitor_control/visitor_list/download_pdf',
                    filters
                )
            default:
                return
        }
    } else if (format === constants.formats.EXCEL) {
        switch (viewName) {
            case constants.viewNames.EVENTS:
                return axios.runPost('auth/webcontrol/download_excel', filters)
            case constants.viewNames.EVALUATIONS:
                return axios.runPost('auth/evaluations/download_excel', filters)
            case constants.viewNames.INCIDENTS:
                return axios.runPost('auth/incidents/download_excel', filters)
            case constants.viewNames.VISITS:
                return axios.runPost('auth/visits/download_excel', filters)
            case constants.viewNames.ROUNDS:
                return axios.runPost('auth/rounds/download_excel', filters)
            case constants.viewNames.SURVEILLANCE:
                return axios.runPost(
                    'auth/surveillances/download_excel',
                    filters
                )
            case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
                return axios.runPost(
                    'auth/discontinous_surveillances/download_excel',
                    filters
                )
            case constants.viewNames.KEYRINGS:
                return axios.runPost('auth/trace_app/download_excel', filters)
            case constants.viewNames.VISITOR_CONTROL:
                return axios.runPost(
                    'auth/visitor_control/visitor_list/download_excel',
                    filters
                )
            default:
                return
        }
    }
}
