const initialState = {
    // DATA
    data: [],
    startDate: new Date(),
    endDate: new Date(),
    sortBy: null,
    sortDescending: false,
    pageSize: 10,
    page: 1,
    filtered: [],
    // CONTROL
    loading: false,
}

export const actionTypes = {
    RESET_TABLE: 'RESET_TABLE',
    DOWNLOAD_DOCUMENT_REQUEST: 'DOWNLOAD_DOCUMENT_REQUEST',
    DOWNLOAD_DOCUMENT_SUCCESS: 'DOWNLOAD_DOCUMENT_SUCCESS',
    DOWNLOAD_DOCUMENT_FAILURE: 'DOWNLOAD_DOCUMENT_FAILURE',
    FETCH_TABLE_DATA_REQUEST: 'FETCH_TABLE_DATA_REQUEST',
    FETCH_TABLE_DATA_SUCCESS: 'FETCH_TABLE_DATA_SUCCESS',
    FETCH_TABLE_DATA_FAILURE: 'FETCH_TABLE_DATA_FAILURE',
    SELECT_END_DATE: 'SELECT_END_DATE',
    SELECT_START_DATE: 'SELECT_START_DATE',
    SET_COLUMN_FILTERS: 'SET_COLUMN_FILTERS',
    SET_PAGE: 'SET_PAGE',
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    SET_SORTING: 'SET_SORTING',
}

const table = (state = initialState, action) => {
    switch (action.type) {
        // RESET / SET / SELECT
        case actionTypes.RESET_TABLE:
            return {
                ...initialState,
            }
        case actionTypes.SELECT_START_DATE:
            return {
                ...state,
                startDate: action.startDate,
            }
        case actionTypes.SELECT_END_DATE:
            return {
                ...state,
                endDate: action.endDate,
            }
            case actionTypes.SET_PAGE:
            return {
                ...state,
                page: action.page,
            }
        case actionTypes.SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case actionTypes.SET_SORTING:
            return {
                ...state,
                sortBy: action.sortBy,
                sortDescending: action.sortDescending,
            }
        case actionTypes.SET_COLUMN_FILTERS:
            return {
                ...state,
                filtered: action.filtered,
            }
        // REQUEST
        case actionTypes.DOWNLOAD_DOCUMENT_REQUEST:
        case actionTypes.FETCH_TABLE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        // SUCCESS
        case actionTypes.FETCH_TABLE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
            }
        case actionTypes.DOWNLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        // FAILURE
        case actionTypes.DOWNLOAD_DOCUMENT_FAILURE:
        case actionTypes.FETCH_TABLE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // DEFAULT
        default:
            return state
    }
}

export default table
