import React from 'react'
import Icon from '@mdi/react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Collapse, Grid, Tooltip, Typography } from '@material-ui/core'
import {
    mdiArrowDecision,
    mdiViewDashboard,
    mdiAlarm,
    mdiWalk,
    mdiMagnify,
    mdiMapMarkerPath,
    mdiCellphone,
    mdiClipboardText,
    mdiCloudUpload,
    mdiBadgeAccountOutline,
    mdiKeyChange,
    mdiChevronUp,
    mdiChevronDown,
    mdiMenuLeft,
    mdiMenuRight,
    mdiAccount,
    mdiHumanMaleMale,
} from "@mdi/js";
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as generalAccessors from '../store/general/accessors'
import * as authAccessors from '../store/auth/accessors'
import * as generalActions from '../store/general/actions'
import { useTheme } from '@material-ui/core/styles'
import { browserHistory } from '../store/configureStore'

const Sidebar = (props) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles(theme)
    const canAccessSarebServicersApp = useSelector(
        authAccessors.getCanAccessSarebServicersApp
    )
    const canReadSurveillanceTool = useSelector(
        authAccessors.getCanReadSurveillanceTool
    )
    const canReadRoundsTool = useSelector(authAccessors.getCanReadRoundsTool)
    const canReadDiscontinuousSurveillanceTool = useSelector(
        authAccessors.getCanReadDiscontinuousSurveillanceTool
    )
    const canReadEvaluationsTool = useSelector(
        authAccessors.getCanReadEvaluationsTool
    )
    const canReadEventsTool = useSelector(authAccessors.getCanReadEventsTool)
    const canReadIncidentsTool = useSelector(
        authAccessors.getCanReadIncidentsTool
    )
    const canReadRegistrationRequestsTool = useSelector(
        authAccessors.getCanReadRegistrationRequestsTool
    )
    const canReadKeyringsTool = useSelector(
        authAccessors.getCanReadKeyringsTool
    )
    const canReadVisitorControlTool = useSelector(
        authAccessors.getCanReadVisitorControlTool
    )
    const canReadVisitsTool = useSelector(authAccessors.getCanReadVisitsTool)

    const language = useSelector(generalAccessors.getLanguage)
    const isLoadingAuth = useSelector(authAccessors.getLoading)
    const isLoadingGeneral = useSelector(generalAccessors.getLoading)
    const isLoading = isLoadingAuth || isLoadingGeneral
    const isSarebMenuOpen = useSelector(generalAccessors.getSarebMenuOpen)
    const viewName = useSelector(generalAccessors.getViewName)
    const isDrawerOpen = useSelector(generalAccessors.getDrawerOpen)
    const handleSarebMenuClick = () => {
        dispatch(generalActions.setSarebMenuOpen(!isSarebMenuOpen))
    }
    const selectedCustomer = useSelector(generalAccessors.getSelectedCustomer)

    return (
        <div className={classes.sideBar}>
            {isDrawerOpen ? (
                <Box
                    bgcolor='lightgrey'
                    color='secondary.main'
                    onClick={() => props.onChangeCustomer()}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography
                                className={classes.customerSelectorSideButton}
                                align='center'
                            >
                                {selectedCustomer
                                    ? selectedCustomer.name
                                    : 'Selecciona cliente'}
                            </Typography>
                        </Grid>
                        <Grid
                            className={classes.customerSelectorSideArrow}
                            item
                            xs={3}
                        >
                            <Icon path={props.showCustomerSelector ? mdiMenuLeft : mdiMenuRight} className={classes.customerSelectorSideArrowIcon} size={1} />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <ListItem
                    disabled={isLoading}
                    button
                    onClick={() => props.onChangeCustomer()}
                    className={classes.gutters}
                >
                    <Tooltip
                        title={
                            selectedCustomer
                                ? 'Usuario: ' + selectedCustomer.name
                                : ''
                        }
                    >
                        <ListItemIcon>
                            <Icon path={mdiAccount} className={classes.iconButton} size={1} />
                        </ListItemIcon>
                    </Tooltip>
                </ListItem>
            )}
            <List>
                <ListItem
                    disabled={isLoading}
                    button
                    onClick={() => browserHistory.push(constants.paths.home)}
                    selected={viewName === constants.viewNames.HOME}
                    className={classes.gutters}
                >
                    <Tooltip
                        title={
                            language === 'es'
                                ? constants.viewNamesEs.home
                                : constants.viewNamesEn.home
                        }
                    >
                        <ListItemIcon>
                            <Icon path={mdiViewDashboard} className={classes.iconButton} size={1} />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                        primary={
                            <Typography
                                color='secondary'
                                className={
                                    viewName === constants.viewNames.HOME
                                        ? classes.itemListSelect
                                        : ''
                                }
                            >
                                {language === 'es'
                                    ? constants.viewNamesEs.home
                                    : constants.viewNamesEn.home}
                            </Typography>
                        }
                    />
                </ListItem>
                {canReadSurveillanceTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(
                                constants.paths.surveillanceServices
                            )
                        }
                        selected={viewName === constants.viewNames.SURVEILLANCE}
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs.surveillanceServices
                                    : constants.viewNamesEn.surveillanceServices
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiCellphone} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames.SURVEILLANCE
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs
                                            .surveillanceServices
                                        : constants.viewNamesEn
                                            .surveillanceServices}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadDiscontinuousSurveillanceTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(
                                constants.paths
                                    .discontinuousSurveillanceServices
                            )
                        }
                        selected={
                            viewName ===
                            constants.viewNames.DISCONTINUOUS_SURVEILLANCE
                        }
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs
                                        .discontinuousSurveillanceServices
                                    : constants.viewNamesEn
                                        .discontinuousSurveillanceServices
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiArrowDecision} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames
                                                .DISCONTINUOUS_SURVEILLANCE
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs
                                            .discontinuousSurveillanceServices
                                        : constants.viewNamesEn
                                            .discontinuousSurveillanceServices}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadRoundsTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(constants.paths.rounds)
                        }
                        selected={viewName === constants.viewNames.ROUNDS}
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs.rounds
                                    : constants.viewNamesEn.rounds
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiMapMarkerPath} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName === constants.viewNames.ROUNDS
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs.rounds
                                        : constants.viewNamesEs.rounds}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadIncidentsTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(constants.paths.incidents)
                        }
                        selected={viewName === constants.viewNames.INCIDENTS}
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs.incidents
                                    : constants.viewNamesEn.incidents
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiWalk} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames.INCIDENTS
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs.incidents
                                        : constants.viewNamesEn.incidents}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadVisitsTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(constants.paths.visits)
                        }
                        selected={viewName === constants.viewNames.VISITS}
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs.visits
                                    : constants.viewNamesEn.visits
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiHumanMaleMale} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames.VISITS
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs.visits
                                        : constants.viewNamesEn.visits}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadEvaluationsTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(constants.paths.evaluations)
                        }
                        selected={viewName === constants.viewNames.EVALUATIONS}
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs.evaluations
                                    : constants.viewNamesEn.evaluations
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiMagnify} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames.EVALUATIONS
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs.evaluations
                                        : constants.viewNamesEn.evaluations}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadKeyringsTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(
                                constants.paths.keyrings
                            )
                        }
                        className={classes.gutters}
                        selected={
                            viewName ===
                            constants.viewNames.KEYRINGS
                        }
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs
                                        .keyrings
                                    : constants.viewNamesEn
                                        .keyrings
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiKeyChange} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames
                                                .KEYRINGS
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {language === 'es'
                                        ? constants.viewNamesEs
                                            .keyrings
                                        : constants.viewNamesEn
                                            .keyrings}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadVisitorControlTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() =>
                            browserHistory.push(
                                constants.paths.visitorControl
                            )
                        }
                        className={classes.gutters}
                        selected={
                            viewName ===
                            constants.viewNames.visitorControl
                        }
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.translationsEs
                                        .visitorList
                                    : constants.translationsEn
                                        .visitorList
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiBadgeAccountOutline} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                    className={
                                        viewName ===
                                            constants.viewNames
                                                .VISITOR_CONTROL
                                            ? classes.itemListSelect
                                            : ''
                                    }
                                >
                                    {
                                        language === 'es'
                                            ? constants
                                                .translationsEs
                                                .visitorList
                                            : constants
                                                .translationsEn
                                                .visitorList
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canReadEventsTool && (
                    <ListItem
                        disabled={isLoading}
                        button
                        onClick={() => {
                            browserHistory.push(
                                constants.paths.events
                            )
                        }}
                        selected={
                            viewName ===
                            constants.viewNames.EVENTS
                        }
                        className={classes.gutters}
                    >
                        <Tooltip
                            title={
                                language === 'es'
                                    ? constants.viewNamesEs
                                        .events
                                    : constants.viewNamesEn
                                        .events
                            }
                        >
                            <ListItemIcon>
                                <Icon path={mdiAlarm} className={classes.iconButton} size={1} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            primary={
                                <Typography
                                    color='secondary'
                                >
                                    {
                                        language === 'es'
                                            ? constants.viewNamesEs
                                                .events
                                            : constants.viewNamesEn
                                                .events
                                    }
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {canAccessSarebServicersApp && (
                    <div>
                        <ListItem
                            button
                            onClick={handleSarebMenuClick}
                            className={classes.gutters}
                        >
                            <Tooltip
                                title={
                                    language === 'es'
                                        ? constants.viewNamesEs.sarebRequests
                                        : constants.viewNamesEn.sarebRequests
                                }>
                                <ListItemIcon className={classes.iconButton}>
                                    {isSarebMenuOpen ? (
                                        <Icon path={mdiChevronUp} className={classes.expandButtonSide} size={1} />
                                    ) : (
                                        <Icon path={mdiChevronDown} className={classes.expandButtonSide} size={1} />
                                    )}
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText
                                primary={
                                    <Typography
                                        color='secondary'
                                        className={classes.expandMenuItemText}
                                    >
                                        {
                                            language === 'es'
                                                ? constants.viewNamesEs.sarebRequests
                                                : constants.viewNamesEn.sarebRequests
                                        }
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Collapse
                            in={isSarebMenuOpen}
                            timeout='auto'
                            unmountOnExit
                        >
                            {canReadRegistrationRequestsTool && (
                                <List component='div' disablePadding>
                                    <ListItem
                                        disabled={isLoading}
                                        button
                                        onClick={() =>
                                            browserHistory.push(
                                                constants.paths
                                                    .registrationRequestForm
                                            )
                                        }
                                        className={classes.gutters}
                                        selected={
                                            viewName ===
                                            constants.viewNames
                                                .REGISTRATION_REQUEST_FORM
                                        }
                                    >
                                        <Tooltip
                                            title={
                                                language === 'es'
                                                    ? constants.viewNamesEs
                                                        .registrationRequestForm
                                                    : constants.viewNamesEn
                                                        .registrationRequestForm
                                            }
                                        >
                                            <ListItemIcon>
                                                <Icon path={mdiClipboardText} className={classes.iconButton} size={1} />
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    color='secondary'
                                                    className={
                                                        viewName ===
                                                            constants.viewNames
                                                                .REGISTRATION_REQUEST_FORM
                                                            ? classes.itemListSelect
                                                            : ''
                                                    }
                                                >
                                                    {language === 'es'
                                                        ? constants.viewNamesEs
                                                            .registrationRequestForm
                                                        : constants.viewNamesEn
                                                            .registrationRequestForm}{' '}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem
                                        disabled={isLoading}
                                        button
                                        onClick={() =>
                                            browserHistory.push(
                                                constants.paths
                                                    .registrationRequestUpload
                                            )
                                        }
                                        className={classes.gutters}
                                        selected={
                                            viewName ===
                                            constants.viewNames
                                                .REGISTRATION_REQUEST_SPREADSHEET_FORM
                                                .registrationRequests
                                        }
                                    >
                                        <Tooltip
                                            title={
                                                language === 'es'
                                                    ? constants.viewNamesEs
                                                        .registrationRequestUpload
                                                    : constants.viewNamesEn
                                                        .registrationRequestUpload
                                            }
                                        >
                                            <ListItemIcon>
                                                <Icon path={mdiCloudUpload} className={classes.iconButton} size={1} />
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    color='secondary'
                                                    className={
                                                        viewName ===
                                                            constants.viewNames
                                                                .REGISTRATION_REQUEST_SPREADSHEET_FORM
                                                            ? classes.itemListSelect
                                                            : ''
                                                    }
                                                >
                                                    {language === 'es'
                                                        ? constants
                                                            .translationsEs
                                                            .registrationRequestUpload
                                                        : constants
                                                            .translationsEn
                                                            .registrationRequestUpload}{' '}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            )}
                        </Collapse>
                    </div>
                )}
            </List>
        </div>
    )
}

export default Sidebar
