import * as axios from '../../helpers/axios'

export const getProvinces = () => {
    return axios.runGet('auth/sareb_servicers/get_provinces')
}

export const getCities = (selectedProvince) => {
    return axios.runGet(
        'auth/sareb_servicers/get_cities_by_province?selectedProvince=' +
            selectedProvince.province_id
    )
}

export const postRegistrationRequestForm = (registrationRequestFormData) => {
    let params = {
        assetId: registrationRequestFormData.assetId,
        servicerCode: registrationRequestFormData.servicer.customer_code,
        address: registrationRequestFormData.address,
        cp: registrationRequestFormData.city.zip,
        refCatastral: registrationRequestFormData.refCatastral,
        city: registrationRequestFormData.city.name,
        province: registrationRequestFormData.province.name,
        ccaa: registrationRequestFormData.province.ccaa,
        requestType: registrationRequestFormData.requestType.id,
        launchDate: registrationRequestFormData.launchDate,
        promotionCode: registrationRequestFormData.promotionCode,
        observations: registrationRequestFormData.observations,
        noticeNumber: registrationRequestFormData.noticeNumber,
        servicerId: registrationRequestFormData.servicerId,
    }
    return axios.runPost(
        'auth/sareb_servicers/submit_registration_request_form',
        params
    )
}

export const uploadRegistrationRequestSpreadsheet = (uploadFormData) => {
    let formData = new FormData()
    formData.append(
        'registrationRequestSpreadsheet',
        uploadFormData.spreadSheet
    )
    formData.append('servicerCode', uploadFormData.servicer.customer_code)
    formData.append('requestType', uploadFormData.requestType.id)
    return axios.runPost(
        'auth/sareb_servicers/upload_registration_request_spreadsheet',
        formData
    )
}
