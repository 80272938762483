import React, { forwardRef } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useStyles } from "../styles/Styles"

const Spinner = forwardRef((props, ref) => {
    const classes = useStyles()
    return (
        <div className={classes.spinner}>
            <CircularProgress ref={ref} />
        </div>
    )
})

export default Spinner
