import Container from '@material-ui/core/Container'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../components/DataTable'
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as notificationActions from '../store/notification/actions'
import * as authActions from '../store/auth/actions'
import * as authAccessors from '../store/auth/accessors'
import * as generalAccessors from '../store/general/accessors'
import * as generalActions from '../store/general/actions'
import ControlappFilters from '../components/ControlappFilters'
import * as controlappActions from '../store/controlapp/actions'

const RoundsScreen = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const canReadRoundsTool = useSelector(authAccessors.getCanReadRoundsTool)
    const language = useSelector(generalAccessors.getLanguage)
    const selectedCustomer = useSelector(generalAccessors.getSelectedCustomer)

    useEffect(() => {
        dispatch(generalActions.setViewName(constants.viewNames.ROUNDS))
        if (canReadRoundsTool) {
            dispatch(authActions.refreshConfiguration())
                .then(() => dispatch(controlappActions.fetchDropdownData()))
                .catch((error) => null)
        } else {
            dispatch(
                notificationActions.setError(
                    language === 'es'
                        ? constants.translationsEs.errorPermissionsView
                        : constants.translationsEn.errorPermissionsView
                )
            )
        }
    }, [dispatch, canReadRoundsTool, language, selectedCustomer])

    return canReadRoundsTool ? (
        <Container maxWidth='xl' className={classes.container}>
            <h1>
                {language === 'es'
                    ? constants.viewNamesEs.rounds
                    : constants.viewNamesEn.rounds}
            </h1>
            <ControlappFilters />
            <DataTable />
        </Container>
    ) : null
}

export default RoundsScreen
