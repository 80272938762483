import React from 'react'
import { Switch, Route, Router } from 'react-router'
import { useSelector } from 'react-redux'
import ProtectedRoute from './ProtectedRoute'
import { getAuthenticated } from '../../store/auth/accessors'
import * as constants from '../../helpers/constants'
import DiscontinuousSurveillanceServicesScreen from '../../screens/DiscontinuousSurveillanceServicesScreen'
import EvaluationsScreen from '../../screens/EvaluationsScreen'
import HomeScreen from '../../screens/HomeScreen'
import IncidentsScreen from '../../screens/IncidentsScreen'
import LoginScreen from '../../screens/LoginScreen'
import RegistrationRequestForm from '../../screens/RegistrationRequestForm'
import RegistrationRequestSpreadsheetUploadForm from '../../screens/RegistrationRequestSpreadsheetUploadForm'
import RoundsScreen from '../../screens/RoundsScreen'
import SurveillanceServicesScreen from '../../screens/SurveillanceServicesScreen'
import KeyringsScreen from '../../screens/KeyringsScreen'
import VisitorListScreen from '../../screens/VisitorListScreen'
import EventsScreen from '../../screens/EventsScreen'
import { browserHistory } from '../../store/configureStore'
import VisitsScreen from '../../screens/VisitsScreen'

const AppRouter = () => {
    const isAuthenticated = useSelector(getAuthenticated)
    return (
        <Router history={browserHistory}>
            <Switch>
                <ProtectedRoute
                    exact
                    path={constants.paths.home}
                    component={HomeScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.discontinuousSurveillanceServices}
                    component={DiscontinuousSurveillanceServicesScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.evaluations}
                    component={EvaluationsScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.incidents}
                    component={IncidentsScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.visits}
                    component={VisitsScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.rounds}
                    component={RoundsScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.surveillanceServices}
                    component={SurveillanceServicesScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.registrationRequestForm}
                    component={RegistrationRequestForm}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.registrationRequestUpload}
                    component={RegistrationRequestSpreadsheetUploadForm}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.keyrings}
                    component={KeyringsScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.visitorControl}
                    component={VisitorListScreen}
                    isAuthenticated={isAuthenticated}
                />
                <ProtectedRoute
                    exact
                    path={constants.paths.events}
                    component={EventsScreen}
                    isAuthenticated={isAuthenticated}
                />
                <Route
                    exact
                    path={constants.paths.login}
                    component={LoginScreen}
                />
            </Switch>
        </Router>
    )
}

export default AppRouter
