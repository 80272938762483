import Container from '@material-ui/core/Container'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../components/DataTable'
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as notificationActions from '../store/notification/actions'
import * as authActions from '../store/auth/actions'
import * as authAccessors from '../store/auth/accessors'
import * as generalAccessors from '../store/general/accessors'
import * as generalActions from '../store/general/actions'
import * as visitorListActions from '../store/visitorlist/actions'
import * as visitorListAccesors from '../store/visitorlist/accessors'
import * as tableActions from '../store/table/actions'
import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const VisitorListScreen = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const canReadVisitorControlTool = useSelector(authAccessors.getCanReadVisitorControlTool)
    const language = useSelector(generalAccessors.getLanguage)
    const buildingSelector = useSelector(visitorListAccesors.getBuildingSelector)
    const selectedBuilding = useSelector(visitorListAccesors.getSelectedBuilding)

    useEffect(() => {
        dispatch(generalActions.setViewName(constants.viewNames.VISITOR_CONTROL))
        dispatch(tableActions.resetTable())
        dispatch(visitorListActions.resetVisitorlistSelectors())
        if (canReadVisitorControlTool) {
            dispatch(authActions.refreshConfiguration())
                .then(() => dispatch(visitorListActions.fetchBuildings()))
                .catch((error) => null)
        } else {
            dispatch(
                notificationActions.setError(
                    language === 'es'
                        ? constants.translationsEs.errorPermissionsView
                        : constants.translationsEn.errorPermissionsView
                )
            )
        }
        return function cleanup() {
            dispatch(tableActions.resetTable())
            dispatch(visitorListActions.resetVisitorlistSelectors())
        }
    }, [dispatch, canReadVisitorControlTool, language])

    const handleBuildingChange = (event, values) => {
        dispatch(
            visitorListActions.selectBuilding(
                values ? (values.id - 1).toString() : null
            )
        )
    }

    return canReadVisitorControlTool ? (
        <Container maxWidth='xl' className={classes.container}>
            <h1>
                {language === 'es'
                    ? constants.translationsEs.visitorList
                    : constants.translationsEn.visitorList}
            </h1>
            <Grid container spacing={1} justify='space-between'>
                <Grid item xs={12}>
                    <Autocomplete
                        id='building'
                        options={buildingSelector}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                            value.value === option.value
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    language === 'es'
                                        ? constants.translationsEs.building
                                        : constants.translationsEn.building
                                }
                                variant='outlined'
                            />
                        )}
                        onChange={handleBuildingChange}
                        value={selectedBuilding}
                        size='small'
                    />
                </Grid>
            </Grid>
            <DataTable />
        </Container>
    ) : null
}

export default VisitorListScreen
