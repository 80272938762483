export const getBuildingSelector = (state) => {
    return state.visitorlist.buildingSelector
}

export const getSelectedBuilding = (state) => {
    return state.visitorlist.selectedBuilding
}

export const getLoading = (state) => {
    return state.visitorlist.loading
}
