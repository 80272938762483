import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Divider,
  FormLabel,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  TextField,
  Button,
} from '@material-ui/core'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from '../../styles/Styles'
import { useTheme } from '@material-ui/core/styles'
import GoogleMapLink from '../columnActionButtons/GoogleMapLink'
import Icon from '@mdi/react'
import { mdiFilePdfBox, mdiEye } from '@mdi/js'
import * as constants from '../../helpers/constants'
import * as generalAccessors from '../../store/general/accessors'
import * as controlappAccessors from '../../store/controlapp/accessors'
import * as controlappActions from '../../store/controlapp/actions'
import { Card, CardContent, LinearProgress, Modal, ThemeProvider, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';

const IncidentDetailModal = (props) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const incidentData = useSelector(controlappAccessors.getIncidentData)
  const isSarebCompany = incidentData.sareb_company
  const language = useSelector(generalAccessors.getLanguage)
  const open = useSelector(generalAccessors.getIncidentDetailModalOpen)
  const loading = useSelector(controlappAccessors.getLoadingIncidentData)
  const loadingPdf = useSelector(controlappAccessors.getLoading)
  const hasError = useSelector(controlappAccessors.getHasError)
  const handleIncidentDetailModalClose = () => {
    dispatch(controlappActions.clearIncidentData())
  }
  const handleIncidentImageActionButtonClick = (incidentImage) => {
    var image = new Image()
    image.src = incidentImage
    image.height = window.innerHeight
    var w = window.open('');
    w.document.write(image.outerHTML);
  }
  const handleDownloadPdfButtonPress = () => {
    dispatch(controlappActions.downloadIncidentPdf())
  }

  /* Funciones para el uso de las Tabs */
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
    )
  }

  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event, newTab) => {
    setSelectedTab(newTab)
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={handleIncidentDetailModalClose}
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            boxShadow: 24,
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
          }}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              color={theme.palette.background.default}
              variant='h4'
            >
              {language === 'es' ? constants.translationsEs.incidentData : constants.translationsEn.incidentData}{' '}
              {incidentData.incident ? incidentData.incident.id : ''}{incidentData.incident && ' ('}{incidentData.incident && (language === 'es' ? constants.translationsEs.subscriberCode : constants.translationsEn.subscriberCode)}{' '}{incidentData.incident && incidentData.incident.subscriber_code}{incidentData.incident && ')'}
            </Typography>
          </CardContent>
          {loading && (
            <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
              <Typography variant='h5'>{language === 'es' ? constants.translationsEs.loadingIncidentData : constants.translationsEn.loadingIncidentData}</Typography>
              <LinearProgress sx={{ mt: 5, mb: 5 }} color='primary' />
            </CardContent>
          )}
          {hasError && (
            <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
              <Typography variant='h5'>{language === 'es' ? constants.translationsEs.errorIncidentData : constants.translationsEn.errorIncidentData}</Typography>
              <Typography variant='h6'>{language === 'es' ? constants.translationsEs.pleaseTryAgain : constants.translationsEn.pleaseTryAgain}</Typography>
            </CardContent>
          )}
          {incidentData.incident && (
            <CardContent sx={{ backgroundColor: theme.palette.background.default, minHeight: 460 }}>
              <AppBar position='relative'>
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  variant='fullWidth'
                >
                  <Tab label={
                    language === 'es'
                      ? constants.translationsEs.property
                      : constants.translationsEn.property
                  }
                  />
                  <Tab label={
                    language === 'es'
                      ? constants.translationsEs.event
                      : constants.translationsEn.event
                  }
                  />
                  {incidentData.incident && incidentData.incident.watchman_name && (
                    <Tab label={
                      language === 'es'
                        ? constants.translationsEs.surveillance
                        : constants.translationsEn.surveillance
                    }
                    />
                  )}
                  {incidentData.fileBlobData && incidentData.fileBlobData.length && (
                    <Tab label={
                      language === 'es'
                        ? constants.translationsEs.images
                        : constants.translationsEn.images
                    }
                    />
                  )}
                </Tabs>
              </AppBar>

              {/* Datos del Inmueble */}
              <TabPanel value={selectedTab} index={0}>
                <h3>{
                  language === 'es'
                    ? constants.translationsEs.propertyData
                    : constants.translationsEn.propertyData
                }</h3>
                <Grid container spacing={1}>
                  <Grid container item xs={12} spacing={3}>
                    {isSarebCompany && (
                      <Grid item xs={12} md={4}>
                        <TextField
                          value={incidentData.asset_information.sareb_code ?? language === 'es'
                            ? constants.translationsEs.notAvailable
                            : constants.translationsEn.notAvailable}
                          label={
                            language === 'es'
                              ? constants.translationsEs.sarebCode
                              : constants.translationsEn.sarebCode
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={false}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        value={incidentData.service ? incidentData.service.description : ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.serviceName
                            : constants.translationsEn.serviceName
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={incidentData.asset_information.address ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.address
                            : constants.translationsEn.address
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        value={incidentData.asset_information.city ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.city
                            : constants.translationsEn.city
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={incidentData.asset_information.province ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.province
                            : constants.translationsEn.province
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </TabPanel>
              {/* Datos del Evento */}
              <TabPanel value={selectedTab} index={1}>
                <h3>{
                  language === 'es'
                    ? constants.translationsEs.eventData
                    : constants.translationsEn.eventData
                }</h3>
                <Grid container spacing={1}>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={incidentData.incident.incident_start_date ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.triggerDate
                            : constants.translationsEn.triggerDate
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={incidentData.incident.incident_start_hour + ' h.' ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.triggerTime
                            : constants.translationsEn.triggerTime
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={incidentData.incident.incident_cause_number ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.triggerCount
                            : constants.translationsEn.triggerCount
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={incidentData.incident.device_activated ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.activatedDevice
                            : constants.translationsEn.activatedDevice
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={incidentData.incident.incident_cause ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.incidentCause
                            : constants.translationsEn.incidentCause
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={incidentData.incident.camera_screen ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.cameraScreen
                            : constants.translationsEn.cameraScreen
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  {incidentData.incident && incidentData.incident.watcher_arrived_time && (
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={6} md={4}>
                        <TextField
                          value={incidentData.incident.watcher_arrived_date ?? ''}
                          label={
                            language === 'es'
                              ? constants.translationsEs.watchmanArrivalDate
                              : constants.translationsEn.watchmanArrivalDate
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          value={incidentData.incident.watcher_arrived_time ?? ''}
                          label={
                            language === 'es'
                              ? constants.translationsEs.watchmanArrivalTime
                              : constants.translationsEn.watchmanArrivalTime
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormLabel filled>
                          {language === 'es'
                            ? constants.translationsEs.watchmanArrivalGeolocation
                            : constants.translationsEn.watchmanArrivalGeolocation}
                          <GoogleMapLink
                            latitude={incidentData.incident.arriving_latitude}
                            longitude={incidentData.incident.arriving_longitude}
                          />
                        </FormLabel>
                      </Grid>
                    </Grid>
                  )}
                  {incidentData.incident && incidentData.incident.watchman_finished_date && (
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={6} md={4}>
                        <TextField
                          value={incidentData.incident.watchman_finished_date ?? ''}
                          label={
                            language === 'es'
                              ? constants.translationsEs.watchmanCompletionDate
                              : constants.translationsEn.watchmanCompletionDate
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          value={
                            incidentData.incident.watchman_finished_datetime ?? ''
                          }
                          label={
                            language === 'es'
                              ? constants.translationsEs.watchmanCompletionTime
                              : constants.translationsEn.watchmanCompletionTime
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormLabel filled>
                          {language === 'es'
                            ? constants.translationsEs.watchmanCompletionGeolocation
                            : constants.translationsEn
                              .watchmanCompletionGeolocation}
                          <GoogleMapLink
                            latitude={incidentData.incident.finished_latitude}
                            longitude={incidentData.incident.finished_longitude}
                          />
                        </FormLabel>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>

              {/* Datos de Vigilancia */}
              <TabPanel value={selectedTab} index={2}>
                <h3>{
                  language === 'es'
                    ? constants.translationsEs.surveillanceReport
                    : constants.translationsEn.surveillanceReport
                }</h3>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.watchman_name ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.watchmanName
                          : constants.translationsEn.watchmanName
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.watchman_tip ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.watchmanTip
                          : constants.translationsEn.watchmanTip
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.key_used ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.keyringCustody
                          : constants.translationsEn.keyringCustody
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.intrusion ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.isIntrusion
                          : constants.translationsEn.isIntrusion
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={incidentData.data_incident.property_data ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.propertyDamage
                          : constants.translationsEn.propertyDamage
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.system_verified ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.isSystemVerified
                          : constants.translationsEn.isSystemVerified
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.trespassing ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.accessedInterior
                          : constants.translationsEn.accessedInterior
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={incidentData.incident.system_damage ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.systemDamage
                          : constants.translationsEn.systemDamage
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={
                        incidentData.incident.watchman_police_sent_id ?? ''
                      }
                      label={
                        language === 'es'
                          ? constants.translationsEs.dispatchedPolice
                          : constants.translationsEn.dispatchedPolice
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  {incidentData.incident.police_option_id && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={incidentData.incident.police_option_id ?? ''}
                        label={
                          language === 'es'
                            ? constants.translationsEs.policeForceOption
                            : constants.translationsEn.policeForceOption
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth={true}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={
                        incidentData.incident.closing_verification_option_id ??
                        ''
                      }
                      label={
                        language === 'es'
                          ? constants.translationsEs.perimeterVerificationOption
                          : constants.translationsEn.perimeterVerificationOption
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                {incidentData.incident.closing_verification && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={incidentData.incident.closing_verification ?? ''}
                      label={
                        language === 'es'
                          ? constants.translationsEs.perimeterVerificationText
                          : constants.translationsEn.perimeterVerificationText
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth={true}
                      multiline={true}
                    />
                  </Grid>
                )}
              </TabPanel>

              {/* Galeria de Fotos */}
              <TabPanel value={selectedTab} index={3}>
                <h3>{
                  language === 'es'
                    ? constants.translationsEs.photoGallery
                    : constants.translationsEn.photoGallery
                }</h3>
                {incidentData.fileBlobData && (
                  <div className={classes.gridListRoot}>
                    <GridList className={classes.gridList} cols={3} spacing={10}>
                      {incidentData.fileBlobData.map((image) => (
                        <GridListTile key={image.filename}>
                          <img src={image.blobData} alt={image.filename} />
                          <GridListTileBar
                            title={image.filename}
                            classes={{
                              root: classes.gridListTitleBar,
                              title: classes.gridListTitle,
                            }}
                            actionIcon={
                              <IconButton
                                onClick={() =>
                                  handleIncidentImageActionButtonClick(
                                    image.blobData
                                  )
                                }
                              >
                                <Icon
                                  path={mdiEye}
                                  className={classes.gridListTitle}
                                  size={1}
                                />
                              </IconButton>
                            }
                          />
                        </GridListTile>
                      ))}
                    </GridList>
                  </div>
                )}
              </TabPanel>
            </CardContent>
          )}
          <CardContent sx={{ display: 'flex', justifyContent: 'space-around', backgroundColor: theme.palette.background.default }}>
            {incidentData.incident && (
              <LoadingButton
                onClick={handleDownloadPdfButtonPress}
                variant='contained'
                color='secondary'
                size='small'
                startIcon={<Icon path={mdiFilePdfBox} size={1} />}
                loading={loadingPdf}
              >
                {
                  language === 'es'
                    ? constants.translationsEs.downloadPdf
                    : constants.translationsEn.downloadPdf
                }</LoadingButton>
            )}
            <Button
              variant='contained' color='primary' size='small'
              onClick={handleIncidentDetailModalClose}
              startIcon={<Cancel />}
            >
              {language === 'es' ? constants.translationsEs.close : constants.translationsEn.close}
            </Button>
          </CardContent>
        </Card>
      </Modal>
    </ThemeProvider>
  )
}

export default IncidentDetailModal
