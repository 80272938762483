import { useTheme } from '@material-ui/core/styles'
import { Cancel } from '@mui/icons-material'
import { Box, Button, Card, CardContent, LinearProgress, Modal, ThemeProvider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappAccessors from '../../store/controlapp/accessors'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'
import * as generalActions from '../../store/general/actions'

const ControlappRoundIncidentTaskModal = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const language = useSelector(generalAccessors.getLanguage)
    const open = useSelector(generalAccessors.getControlappRoundIncidentModalOpen)
    const roundIncidentData = useSelector(controlappAccessors.getRoundIncidentData)
    const roundIncidentImage = useSelector(controlappAccessors.getRoundIncidentImage)
    const loading = useSelector(controlappAccessors.getLoadingRoundIncidentData)
    const hasError = useSelector(controlappAccessors.getHasError)
    const handleCloseModal = () => {
        dispatch(generalActions.setRoundIncidentModalOpen(false))
        dispatch(controlappActions.resetRoundIncidentData())
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleCloseModal}
            >
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        boxShadow: 24,
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    style={{ backgroundColor: theme.palette.primary.main }}
                >
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography color={theme.palette.background.default} variant='h4'>{language === 'es' ? constants.translationsEs.roundIncidentDetails : constants.translationsEn.roundIncidentDetails}{roundIncidentData.point}</Typography>
                    </CardContent>
                    <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                        {roundIncidentData.description !== null && <Typography variant='h5'>{language === 'es' ? constants.translationsEs.description : constants.translationsEn.description}</Typography>}
                        {roundIncidentData.description !== null && <Typography>{roundIncidentData.description}</Typography>}
                        {roundIncidentData.observations !== null && <Typography variant='h5'>{language === 'es' ? constants.translationsEs.observations : constants.translationsEn.observations}</Typography>}
                        {roundIncidentData.observations !== null && <Typography>{roundIncidentData.observations}</Typography>}
                    </CardContent>
                    {roundIncidentImage !== '' && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.image : constants.translationsEn.image}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={roundIncidentImage.blobData}
                                    alt={language === 'es' ? constants.translationsEs.image + ' ' + constants.translationsEs.incident : constants.translationsEn.incident + ' ' + constants.translationsEn.image}
                                    style={{ maxHeight: '500px', maxWidth: '500px', height: 'auto', width: 'auto' }}
                                />
                            </Box>
                        </CardContent>
                    )}
                    {hasError && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.errorDownloadImage : constants.translationsEn.errorDownloadImage}</Typography>
                            <Typography variant='h6'>{language === 'es' ? constants.translationsEs.pleaseTryAgain : constants.translationsEn.pleaseTryAgain}</Typography>
                        </CardContent>
                    )}
                    {loading && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.loadingRoundIncidentImage : constants.translationsEn.loadingRoundIncidentImage}</Typography>
                            <LinearProgress sx={{ mt: 5, mb: 5 }} color='primary' />
                        </CardContent>
                    )}
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.background.default }}>
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={handleCloseModal}
                            startIcon={<Cancel />}
                        >
                            {language === 'es' ? constants.translationsEs.close : constants.translationsEn.close}
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </ThemeProvider>
    )
}

export default ControlappRoundIncidentTaskModal
