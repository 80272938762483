import { Redirect, Route } from "react-router"
import { paths } from "../../helpers/constants"

const ProtectedRoute = (props) => {
    const { component: Component, isAuthenticated, ...rest } = props
    if (!isAuthenticated) {
        return <Redirect to={paths.login} />
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default ProtectedRoute
