import React, { useState, useEffect, useCallback } from 'react'

import { Search as SearchIcon, Calculate as ExcelIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material'
import { Box, Button, IconButton, ThemeProvider, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import MaterialReactTable, { MRT_FullScreenToggleButton as FullScreenToggleButton, MRT_ShowHideColumnsButton as ShowHideColumnsButton, MRT_ToggleFiltersButton as ToggleFiltersButton } from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useTheme } from '@material-ui/core/styles'

import * as constants from '../helpers/constants'
import * as generalAccessors from '../store/general/accessors'
import * as authAccessors from '../store/auth/accessors'
import * as tableAccessors from '../store/table/accessors'
import * as controlappAccessors from '../store/controlapp/accessors'
import * as webcontrolAccessors from '../store/webcontrol/accessors'
import * as tableActions from '../store/table/actions'
import { useStyles } from '../styles/Styles'
import usePrevious from '../hooks/usePrevious';
import EvaluationReportLink from './columnActionButtons/EvaluationReportLink'
import GoogleMapLink from './columnActionButtons/GoogleMapLink'
import IncidentLink from './columnActionButtons/IncidentLink'
import ImageLink from './columnActionButtons/ImageLink'
import RoundIncidentLink from './columnActionButtons/RoundIncidentLink'
import RoundTasksLink from './columnActionButtons/RoundTasksLink'
import VisitLink from './columnActionButtons/VisitLink'

const DataTable = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles(theme)
    const language = useSelector(generalAccessors.getLanguage)
    const viewName = useSelector(generalAccessors.getViewName)
    const prevViewName = usePrevious(viewName)
    const data = useSelector(tableAccessors.getData)
    const total = useSelector(tableAccessors.getTotal)
    const isLoading = useSelector(tableAccessors.getLoading)
    const loadingControlapp = useSelector(controlappAccessors.getLoading)
    const loadingWebcontrol = useSelector(webcontrolAccessors.getLoading)
    const loadingDropdowns = loadingControlapp || loadingWebcontrol
    const loadingAuth = useSelector(authAccessors.getLoading)
    const exportButtonsDisabled = useSelector(tableAccessors.getExportButtonsDisabled)
    const searchButtonDisabled = useSelector(tableAccessors.getSearchButtonDisabled)
    const [isRefetching, setIsRefetching] = useState(false)
    const [columnFilters, setColumnFilters] = useState([])
    const [sorting, setSorting] = useState([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    })
    const [columns, setColumns] = useState([])

    // Enable the ENTER key to fetch table data
    const handleUserKeyPress = useCallback(
        (event) => {
            if (event.keyCode === 13 || event.which === 13) {
                if (true) {
                    dispatch(tableActions.fetchTableData())
                }
            }
        },
        [dispatch]
    )
    useEffect(() => {
        switch (viewName) { // TODO: Make responsive for smaller screen sizes (auto hide specific columns)
            case constants.viewNames.EVENTS:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: constants.columnNames.date,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.event
                                : constants.translationsEn.event,
                        accessorKey: constants.columnNames.event,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let eventString = ''
                            switch (row.original.event) {
                                case '*BA':
                                case 'BA':
                                case 'BA1':
                                case 'BA2':
                                case 'BA24H':
                                case 'BA3':
                                case 'BA4':
                                case 'BA5':
                                case 'BA6':
                                case 'BA7':
                                case 'BA8':
                                case 'BA9':
                                case 'BAG3':
                                case 'BAL':
                                case 'BALT':
                                case 'BAOP':
                                case 'BAV':
                                case 'LTBA':
                                case 'BR':
                                case 'BR1':
                                case 'BR2':
                                case 'BR3':
                                case 'BR4':
                                case 'BR5':
                                case 'BR6':
                                case 'BR7':
                                case 'BR8':
                                case 'BR9':
                                    eventString = language === 'es' ? constants.translationsEs.eventGroups.BABR : constants.translationsEn.eventGroups.BABR
                                    break
                                case 'HA':
                                    eventString = language === 'es' ? constants.translationsEs.eventGroups.HA : constants.translationsEn.eventGroups.HA
                                    break
                                case '*PA':
                                case 'PA':
                                case 'PA1':
                                case 'PA2':
                                case 'PA3':
                                case 'PA4':
                                case 'PA5':
                                    eventString = language === 'es' ? constants.translationsEs.eventGroups.PA : constants.translationsEn.eventGroups.PA
                                    break
                                case 'OP':
                                    eventString = language === 'es' ? constants.translationsEs.events.OP : constants.translationsEn.events.OP
                                    break
                                case 'CL':
                                    eventString = language === 'es' ? constants.translationsEs.events.CL : constants.translationsEn.events.CL
                                    break
                                case 'OA':
                                    eventString = language === 'es' ? constants.translationsEs.events.OA : constants.translationsEn.events.OA
                                    break
                                case 'CA':
                                    eventString = language === 'es' ? constants.translationsEs.events.CA : constants.translationsEn.events.CA
                                    break
                                case 'AT':
                                case 'AR':
                                    eventString = language === 'es' ? constants.translationsEs.eventGroups.ATAR : constants.translationsEn.eventGroups.ATAR
                                    break
                                case 'TA':
                                case 'TAV':
                                case 'TR':
                                    eventString = language === 'es' ? constants.translationsEs.eventGroups.TA : constants.translationsEn.eventGroups.TA
                                    break
                                case 'BB':
                                case 'BU':
                                    eventString = language === 'es' ? constants.translationsEs.eventGroups.BBBU : constants.translationsEn.eventGroups.BBBU
                                    break
                                default:
                                    eventString = ''
                                    break
                            }
                            return (<Typography variant='body2' sx={{ fontWeight: 'bold' }}>{eventString}</Typography>)
                        },
                        filterVariant: 'select',
                        filterSelectOptions: [
                            { text: language === 'es' ? constants.translationsEs.eventGroups.BABR : constants.translationsEn.eventGroups.BABR, value: 'BABR' },
                            { text: language === 'es' ? constants.translationsEs.eventGroups.HA : constants.translationsEn.eventGroups.HA, value: 'HA' },
                            { text: language === 'es' ? constants.translationsEs.eventGroups.PA : constants.translationsEn.eventGroups.PA, value: 'PA' },
                            { text: language === 'es' ? constants.translationsEs.eventGroups.OPCL : constants.translationsEn.eventGroups.OPCL, value: 'OPCL' },
                            { text: language === 'es' ? constants.translationsEs.eventGroups.ATAR : constants.translationsEn.eventGroups.ATAR, value: 'ATAR' },
                            { text: language === 'es' ? constants.translationsEs.eventGroups.TA : constants.translationsEn.eventGroups.TA, value: 'TA' },
                            { text: language === 'es' ? constants.translationsEs.eventGroups.BBBU : constants.translationsEn.eventGroups.BBBU, value: 'BBBU' },
                        ],
                    },
                    {
                        header: 'Area',
                        accessorKey: constants.columnNames.area,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' color={theme.palette.secondary.main}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.zone
                                : constants.translationsEn.zone,
                        accessorKey: constants.columnNames.zone,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' color={theme.palette.primary.main}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.observations
                                : constants.translationsEn.observations,
                        accessorKey: constants.columnNames.observations,
                    },
                ])
                break
            case constants.viewNames.EVALUATIONS:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.service
                                : constants.translationsEn.service,
                        accessorKey: constants.columnNames.center,
                        enableSorting: false,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{ padding: '5px', fontSize: 10 }}>
                                    <span>{row.original.center}</span><br />
                                    <span>{row.original.customer_contract}</span><br />
                                    <span style={{ fontWeight: 'bold', fontSize: 13 }}>{row.original.customer_contract_service}</span>
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: constants.columnNames.date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.inspectionCode
                                : constants.translationsEn.inspectionCode,
                        accessorKey: constants.columnNames.inspection_code,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    whiteSpace: 'unset',
                                    fontSize: 18,
                                    color: theme.palette.secondary.main,
                                    fontWeight: 600,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header: 'Inspector',
                        accessorKey: constants.columnNames.inspection_user,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    whiteSpace: 'unset',
                                    fontSize: 12,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.actions
                                : constants.translationsEn.actions,
                        accessorKey: constants.columnNames.actions,
                        enableSorting: false,
                        enableColumnFilter: false,
                        minSize: 70,
                        maxSize: 70,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let evaluationId = row.original.id
                            return (
                                <EvaluationReportLink evaluationId={evaluationId} />
                            )
                        },
                    },
                ])
                break
            case constants.viewNames.INCIDENTS:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.service
                                : constants.translationsEn.service,
                        accessorKey: constants.columnNames.center,
                        enableSorting: false,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{ padding: '5px', fontSize: 10 }}>
                                    <span>{row.original.center}</span><br />
                                    <span style={{ fontWeight: 'bold', fontSize: 13 }}>{row.original.customer_contract}</span><br />
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: constants.columnNames.date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.arrived_date
                                : constants.translationsEn.arrived_date,
                        accessorKey: constants.columnNames.arrived_date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.secondary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.finished_date
                                : constants.translationsEn.finished_date,
                        accessorKey: constants.columnNames.finished_date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.subscriberCode
                                : constants.translationsEn.subscriberCode,
                        accessorKey: constants.columnNames.subscriber_code,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    'whiteSpace': 'unset',
                                    fontSize: 18,
                                    fontWeight: 600,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },

                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.dispatchedWatchman
                                : constants.translationsEn.dispatchedWatchman,
                        accessorKey: constants.columnNames.watchman_sent,
                        id: constants.columnNames.watchman_sent,
                        filterVariant: 'select',
                        filterSelectOptions: [
                            {
                                text: language === 'es'
                                    ? constants.translationsEs.yes
                                    : constants.translationsEn.yes, value: '1'
                            },
                            {
                                text: 'No', value: '2'
                            },
                        ],
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            const watchmanSent = row.original.watchman_sent
                            let color = 'black'
                            if (watchmanSent === 'Si') {
                                color = theme.palette.success.main
                            } else {
                                color = theme.palette.error.main
                            }
                            return <Typography variant='h6' color={color}>{watchmanSent}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.dispatchedPolice
                                : constants.translationsEn.dispatchedPolice,
                        accessorKey: constants.columnNames.police_sent,
                        id: constants.columnNames.police_sent,
                        filterVariant: 'select',
                        filterSelectOptions: [
                            {
                                text: language === 'es'
                                    ? constants.translationsEs.yes
                                    : constants.translationsEn.yes, value: '1'
                            },
                            {
                                text: 'No', value: '2'
                            },
                        ],
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            const policeSent = row.original.police_sent
                            let color = 'black'
                            if (policeSent === 'Si') {
                                color = theme.palette.success.main
                            } else {
                                color = theme.palette.error.main
                            }
                            return <Typography variant='h6' color={color}>{policeSent}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.actions
                                : constants.translationsEn.actions,
                        accessorKey: constants.columnNames.actions,
                        enableSorting: false,
                        enableColumnFilter: false,
                        minSize: 70,
                        maxSize: 70,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let incidentId = row.original.incident_id
                            return <IncidentLink incidentId={incidentId} />
                        },
                    },
                ])
                break
            case constants.viewNames.VISITS:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.service
                                : constants.translationsEn.service,
                        accessorKey: 'customer_contract',
                        enableSorting: false,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{ padding: '5px', fontSize: 10 }}>
                                    <span>{(row.original.customer_contract && row.original.customer_contract.center) && row.original.customer_contract.center.name}</span><br />
                                    <span style={{ fontWeight: 'bold', fontSize: 13 }}>{row.original.customer_contract && row.original.customer_contract.description}</span><br />
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: 'start_date',
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.arrived_date
                                : constants.translationsEn.arrived_date,
                        accessorKey: 'arriving_datetime',
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.secondary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.finished_date
                                : constants.translationsEn.finished_date,
                        accessorKey: 'finished_datetime',
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.subscriberCode
                                : constants.translationsEn.subscriberCode,
                        accessorKey: 'sbn_code',
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    'whiteSpace': 'unset',
                                    fontSize: 18,
                                    fontWeight: 600,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.actions
                                : constants.translationsEn.actions,
                        accessorKey: constants.columnNames.actions,
                        enableSorting: false,
                        enableColumnFilter: false,
                        minSize: 70,
                        maxSize: 70,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let visitId = row.original.id
                            return <VisitLink visitId={visitId} />
                        },
                    },
                ])
                break
            case constants.viewNames.ROUNDS:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.service
                                : constants.translationsEn.service,
                        accessorKey: constants.columnNames.center,
                        enableSorting: false,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{ padding: '5px', fontSize: 10 }}>
                                    <span>{row.original.center}</span><br />
                                    <span>{row.original.customer_contract_service}</span><br />
                                    <span style={{ fontWeight: 'bold', fontSize: 13 }}>{
                                    row.original.center_code + '-' +
                                    row.original.customer_contract_code + '-' + 
                                    row.original.customer_contract_service_code}</span>
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: constants.columnNames.date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.round
                                : constants.translationsEn.round,
                        accessorKey: constants.columnNames.round_name,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    whiteSpace: 'unset',
                                    fontSize: 12,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    color: theme.palette.secondary.main,
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.watchman
                                : constants.translationsEn.watchman,
                        accessorKey: constants.columnNames.person_name,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    whiteSpace: 'unset',
                                    fontSize: 12,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.checkpoint
                                : constants.translationsEn.checkpoint,
                        accessorKey: constants.columnNames.point,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    whiteSpace: 'unset',
                                    fontSize: 18,
                                    color: theme.palette.primary.main,
                                    fontWeight: 600,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'start',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.checkpointDescription
                                : constants.translationsEn.checkpointDescription,
                        accessorKey: constants.columnNames.description,
                        enableSorting: false,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    whiteSpace: 'unset',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'start'
                                }}>
                                    <div style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>{row.original.description}</div>
                                    <div>{row.original.round_name2}</div>
                                </div>
                            )
                        }

                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.actions
                                : constants.translationsEn.actions,
                        accessorKey: constants.columnNames.actions,
                        enableSorting: false,
                        enableColumnFilter: false,
                        minSize: 210,
                        maxSize: 210,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let latitude = row.original.latitud
                            let longitude = row.original.longitude
                            let showRoundIncidentLink = row.original.incident_description || row.original.incident_image || row.original.incident_observation
                            let showRoundTasksLink = row.original.tasks_round_id
                            return (
                                <>
                                    <GoogleMapLink
                                        latitude={latitude}
                                        longitude={longitude}
                                    />
                                    {showRoundIncidentLink && <RoundIncidentLink originalRowData={row.original} />}
                                    {showRoundTasksLink && <RoundTasksLink originalRowData={row.original} />}
                                </>
                            )
                        },
                    },
                ])
                break
            case constants.viewNames.SURVEILLANCE:
            case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.service
                                : constants.translationsEn.service,
                        accessorKey: constants.columnNames.customer_contract_service,
                        enableSorting: false,
                        enableColumnFilter: false,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{ padding: '5px', fontSize: 10 }}>
                                    <span>{row.original.center}</span><br />
                                    <span>{row.original.customer_contract}</span><br />
                                    <span style={{ fontWeight: 'bold', fontSize: 13 }}>{row.original.service_code}</span>
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: constants.columnNames.date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.watchman
                                : constants.translationsEn.watchman,
                        accessorKey: constants.columnNames.person_name,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    'whiteSpace': 'unset',
                                    fontSize: 12,
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header: language === 'es'
                            ? constants.translationsEs.trackType
                            : constants.translationsEn.trackType,
                        accessorKey: 'trackType',
                        enableSorting: false,
                        enableColumnFilter: true,
                        filterVariant: 'select',
                        filterSelectOptions: [
                            { text: 'Entrada de servicio', value: 'ENT' },
                            { text: 'Fin de servicio', value: 'SAL' },
                            { text: 'Novedad', value: 'NOV' },
                            { text: 'Incidencia', value: 'I' },
                        ],
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            const trackType = row.original.trackType
                            let trackTypeText = ''
                            let color = 'black'
                            switch (trackType) {
                                case 'ENT':
                                    color = theme.palette.success.main
                                    trackTypeText = 'Entrada de servicio'
                                    break
                                case 'SAL':
                                    color = theme.palette.error.main
                                    trackTypeText = 'Fin de servicio'
                                    break
                                case 'NOV':
                                    color = theme.palette.warning.main
                                    trackTypeText = 'Novedad'
                                    break
                                default:
                                    trackTypeText = 'Incidencia'
                            }
                            return <Typography variant='h6' color={color}>{trackTypeText}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.incident
                                : constants.translationsEn.incident,
                        accessorKey: constants.columnNames.incident,
                        enableSorting: false,
                        enableColumnFilter: true,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.secondary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue()}
                                </div>
                            )
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.actions
                                : constants.translationsEn.actions,
                        enableSorting: false,
                        enableColumnFilter: false,
                        minSize: 140,
                        maxSize: 140,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let imageLink = <ImageLink idRegImage={row.original.id_reg} />
                            let latitude = row.original.latitud
                            let longitude = row.original.longitude
                            return (
                                <div>
                                    <GoogleMapLink
                                        latitude={latitude}
                                        longitude={longitude}
                                    />
                                    {row.original.image_url && imageLink}
                                </div>
                            )
                        },
                    },
                ])
                break
            case constants.viewNames.KEYRINGS:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.keyringNfcTag
                                : constants.translationsEn.keyringNfcTag,
                        accessorKey: constants.columnNames.keyring_nfc,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' sx={{ fontWeight: 'bold' }}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.boxNfcTag
                                : constants.translationsEn.boxNfcTag,
                        accessorKey: constants.columnNames.box_nfc,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' color={theme.palette.primary.main}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.compartment
                                : constants.translationsEn.compartment,
                        accessorKey: constants.columnNames.compartment_code,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' color={theme.palette.secondary.main}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.subscriberCode
                                : constants.translationsEn.subscriberCode,
                        accessorKey: constants.columnNames.subscriber_code,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' sx={{ fontWeight: 'bold' }}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.description
                                : constants.translationsEn.description,
                        accessorKey: constants.columnNames.description,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' color={theme.palette.primary.main}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.numKeys
                                : constants.translationsEn.numKeys,
                        accessorKey: constants.columnNames.num_keys,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return <Typography variant='body2' color={theme.palette.secondary.main} sx={{ fontWeight: 'bold' }}>{cell.getValue()}</Typography>
                        },
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.keyringType
                                : constants.translationsEn.keyringType,
                        accessorKey: constants.columnNames.keyring_type,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            switch (renderedCellValue) {
                                case 1:
                                    return language === 'es'
                                        ? constants.translationsEs.complete
                                        : constants.translationsEn.complete
                                case 2:
                                    return language === 'es'
                                        ? constants.translationsEs.common
                                        : constants.translationsEn.common
                                case 3:
                                    return language === 'es'
                                        ? constants.translationsEs.private
                                        : constants.translationsEn.private
                                default:
                                    return
                            }
                        },
                        filterVariant: 'select',
                        filterSelectOptions: [
                            {
                                text: language === 'es' ? constants.translationsEs.complete : constants.translationsEn.complete, value: '1'
                            },
                            { text: language === 'es' ? constants.translationsEs.common : constants.translationsEn.common, value: '2' },
                            {
                                text: language === 'es'
                                    ? constants.translationsEs.private
                                    : constants.translationsEn.private, value: '3'
                            },
                        ],
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.keysStatus
                                : constants.translationsEn.keysStatus,
                        accessorKey: constants.columnNames.keys_status,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            let keysStatusText = ''
                            let keysStatusTextColor = 'black'
                            switch (renderedCellValue) {
                                case 1:
                                    keysStatusText = language === 'es'
                                        ? constants.translationsEs.valid
                                        : constants.translationsEn.valid
                                    keysStatusTextColor = theme.palette.success.main
                                    break
                                case 2:
                                    keysStatusText = language === 'es'
                                        ? constants.translationsEs.semiValid
                                        : constants.translationsEn.semiValid
                                    keysStatusTextColor = theme.palette.warning.main
                                    break
                                case 3:
                                    keysStatusText = language === 'es'
                                        ? constants.translationsEs.invalid
                                        : constants.translationsEn.invalid
                                    keysStatusTextColor = theme.palette.error.main
                                    break
                                case 4:
                                    keysStatusText = language === 'es'
                                        ? constants.translationsEs.unspecified
                                        : constants.translationsEn.unspecified
                                    keysStatusTextColor = 'grey'
                                    break
                                default:
                                    keysStatusText = ''
                                    keysStatusTextColor = 'black'
                            }
                            return <Typography variant='body2' color={keysStatusTextColor}>{keysStatusText}</Typography>
                        },
                        filterVariant: 'select',
                        filterSelectOptions: [
                            { text: language === 'es' ? constants.translationsEs.valid : constants.translationsEn.valid, value: '1' },
                            { text: language === 'es' ? constants.translationsEs.semiValid : constants.translationsEn.semiValid, value: '2' },
                            { text: language === 'es' ? constants.translationsEs.invalid : constants.translationsEn.invalid, value: '3' },
                            { text: language === 'es' ? constants.translationsEs.unspecified : constants.translationsEn.unspecified, value: '4' },
                        ],
                    },
                ])
                break
            case constants.viewNames.VISITOR_CONTROL:
                setColumns([
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.cardNumber
                                : constants.translationsEn.cardNumber,
                        accessorKey: constants.columnNames.card_number,
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.companyName
                                : constants.translationsEn.companyName,
                        accessorKey: constants.columnNames.company_name,
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.visitor
                                : constants.translationsEn.visitor,
                        accessorKey: constants.columnNames.visitor_name,
                    },
                    {
                        header: 'DNI',
                        accessorKey: constants.columnNames.dni,
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.visitMotive
                                : constants.translationsEn.visitMotive,
                        accessorKey: constants.columnNames.visit_motive,
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.visited
                                : constants.translationsEn.visited,
                        accessorKey: constants.columnNames.visited_name,
                    },
                    {
                        header:
                            language === 'es'
                                ? constants.translationsEs.date
                                : constants.translationsEn.date,
                        accessorKey: constants.columnNames.date,
                        Cell: ({ cell, column, renderedCellValue, row, table }) => {
                            return (
                                <div style={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    {cell.getValue().substr(0, 19)}
                                </div>
                            )
                        },
                    },
                ])
                break
            default:
                setColumns([])
                break
        }
    }, [viewName, classes, language, theme])

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress)
        return () => {
            window.removeEventListener('keydown', handleUserKeyPress)
        }
    }, [handleUserKeyPress])

    // When changing any of the table filter, pagination or ordering options, fetch table data. 
    useEffect(() => {
        if (data.length) {
            setIsRefetching(true)
        }
        dispatch(tableActions.setColumnFilters(columnFilters))
        dispatch(tableActions.setPage(pagination.pageIndex + 1))
        dispatch(tableActions.setPageSize(pagination.pageSize))
        if (sorting.length) {
            dispatch(tableActions.sortByColumn(sorting[0].id, sorting[0].desc))
        }
        // Will not fetch table data right after entering a new view
        if (prevViewName === viewName) {
            dispatch(tableActions.fetchTableData())
        }
        setIsRefetching(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilters,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        dispatch,
    ])

    const handleSearchButtonClick = () => {
        dispatch(tableActions.fetchTableData())
    }
    const handlePdfButtonClick = () => {
        dispatch(tableActions.downloadDocument(constants.formats.PDF))
    }
    const handleExcelButtonClick = () => {
        dispatch(tableActions.downloadDocument(constants.formats.EXCEL))
    }

    return (
        <div style={{ marginTop: '10px' }}>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableGlobalFilter={false}
                    localization={language === 'es' ? MRT_Localization_ES : MRT_Localization_EN}
                    muiLinearProgressProps={({ isTopToolbar }) => ({
                        color: 'primary',
                        variant: 'indeterminate',
                        sx: {
                            display: isTopToolbar ? 'block' : 'none',
                        },
                    })}
                    muiTableBodyProps={{
                        sx: {
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: '#f5f5f5',
                            },
                        },
                    }}
                    enableDensityToggle={false}
                    manualFiltering={true}
                    manualSorting={true}
                    manualPagination={true}
                    onColumnFiltersChange={setColumnFilters}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    enableMultiSort={false}
                    rowCount={total}
                    state={{
                        columnFilters,
                        isLoading,
                        pagination,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip title={language === 'es' ? constants.translationsEs.downloadPdf : constants.translationsEn.downloadPdf}>
                                <span>
                                    <IconButton onClick={handlePdfButtonClick} disabled={exportButtonsDisabled || loadingDropdowns}>
                                        <PdfIcon sx={{ color: '#F40F02' }} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={language === 'es' ? constants.translationsEs.downloadExcel : constants.translationsEn.downloadExcel}>
                                <span>
                                    <IconButton onClick={handleExcelButtonClick} disabled={exportButtonsDisabled || loadingDropdowns}>
                                        <ExcelIcon sx={{ color: '#008000' }} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <ShowHideColumnsButton table={table} color='primary' />
                            <ToggleFiltersButton table={table} color='primary' />
                            <FullScreenToggleButton table={table} color='primary' />
                        </>
                    )}
                    renderTopToolbarCustomActions={({ table }) => (
                        <>
                            <Tooltip title={language === 'es' ? constants.translationsEs.searchButtonText : constants.translationsEn.searchButtonText}>
                                <Box sx={{
                                    display: 'flex',
                                    mt: '3px'
                                }}>
                                    <Button size='small' variant='contained' color='primary' onClick={handleSearchButtonClick} disabled={searchButtonDisabled || loadingAuth || loadingDropdowns} startIcon={<SearchIcon />}>
                                        {language === 'es' ? constants.translationsEs.searchButtonText : constants.translationsEn.searchButtonText}
                                    </Button>
                                </Box>
                            </Tooltip>
                        </>
                    )}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: '4px',
                            border: '1px solid #e0e0e0',
                        },
                    }}
                    muiTableHeadCellFilterTextFieldProps={{
                        spellCheck: false,
                    }}
                    layoutMode='grid-no-grow'
                    enableColumnResizing={false}
                />
            </ThemeProvider>
        </div>
    )
}

export default DataTable
