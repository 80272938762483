import { filterByObject } from '../../helpers/objects'
import * as generalReducers from './reducers'
import * as generalAccessors from './accessors'
import * as tableActions from '../table/actions'
import * as visitorlistReducers from '../visitorlist/reducers'
import * as controlappReducers from '../controlapp/reducers'
import * as sarebReducers from '../sareb/reducers'
import * as webcontrolReducers from '../webcontrol/reducers'

export const selectCompany = (selectedCompany) => (dispatch, getState) => {
    const companies = generalAccessors.getCompanies(getState())
    const oldSelectedCustomer = generalAccessors.getSelectedCustomer(getState())
    if (!selectedCompany) {
        const oldSelectedCompany = generalAccessors.getSelectedCompany(getState())
        companies.forEach((company) => {
            if (oldSelectedCompany && company['company_code'] === oldSelectedCompany['company_code']) {
                selectedCompany = oldSelectedCompany
            }
        })
        if (!selectedCompany) {
            selectedCompany = companies[0]
        }
    }
    const companyCode = selectedCompany.company_code
    let filteredCompanies = []
    let filteredCustomers = []
    if (companyCode) {
        const companyFilter = { company_code: companyCode }
        filteredCompanies = filterByObject(companyFilter, companies)
    }
    filteredCompanies.forEach((company) => {
        company.customers.forEach((customer) => {
            filteredCustomers.push(customer)
        })
    })
    dispatch({
        type: generalReducers.actionTypes.SELECT_COMPANY,
        selectedCompany,
    })
    dispatch(setFilteredCustomers(filteredCustomers))
    let selectedCustomer = filteredCustomers[0]
    if (oldSelectedCustomer) {
        filteredCustomers.forEach((customer) => {
            if (customer['customer_code'] === oldSelectedCustomer['customer_code']) {
                selectedCustomer = oldSelectedCustomer
            }
        })
    }
    dispatch(selectCustomer(selectedCustomer))
}

export const selectCustomer = (selectedCustomer) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SELECT_CUSTOMER,
        selectedCustomer,
    })
}

export const selectLanguage = (language) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SELECT_LANGUAGE,
        language: language,
    })
}

export const setDrawerOpen = (drawerOpen) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_DRAWER_OPEN,
        drawerOpen: drawerOpen,
    })
}

export const setLanguageSelectorOpen =
    (languageSelectorOpen) => (dispatch, getState) => {
        dispatch({
            type: generalReducers.actionTypes.SET_LANGUAGE_SELECTOR_OPEN,
            languageSelectorOpen: languageSelectorOpen,
        })
    }

export const setSarebMenuOpen = (sarebMenuOpen) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_SAREB_MENU_OPEN,
        sarebMenuOpen: sarebMenuOpen,
    })
}

export const setTraceAppMenuOpen =
    (traceAppMenuOpen) => (dispatch, getState) => {
        dispatch({
            type: generalReducers.actionTypes.SET_TRACEAPP_MENU_OPEN,
            traceAppMenuOpen: traceAppMenuOpen,
        })
    }

export const setCustomerMenuOpen =
    (customerMenuOpen) => (dispatch, getState) => {
        dispatch({
            type: generalReducers.actionTypes.SET_CUSTOMER_MENU_OPEN,
            customerMenuOpen: customerMenuOpen,
        })
    }

export const setViewName = (viewName) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_VIEW_NAME,
        viewName: viewName,
    })
    dispatch(tableActions.resetTable())
}

export const setVisitorControlMenuOpen =
    (visitorControlMenuOpen) => (dispatch, getState) => {
        dispatch({
            type: generalReducers.actionTypes.SET_VISITOR_CONTROL_MENU_OPEN,
            visitorControlMenuOpen: visitorControlMenuOpen,
        })
    }

export const setWebcontrolMenuOpen =
    (webcontrolMenuOpen) => (dispatch, getState) => {
        dispatch({
            type: generalReducers.actionTypes.SET_WEBCONTROL_MENU_OPEN,
            webcontrolMenuOpen: webcontrolMenuOpen,
        })
    }

export const setFilteredCustomers = (filteredCustomers) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_FILTERED_CUSTOMERS,
        filteredCustomers: filteredCustomers,

    })
}

export const setConfiguration = (language, data, userName, screens) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_CONFIGURATION,
        language,
        data,
        userName,
        screens,
    })
}

export const setScreens = (screens) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_SCREENS,
        screens,
    })
}

export const resetModals = () => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.RESET_MODALS,
    })
}

export const resetLoadingStates = () => (dispatch, getState) => {
    dispatch({
        type: visitorlistReducers.actionTypes.RESET_LOADING,
    })
    dispatch({
        type: controlappReducers.actionTypes.RESET_LOADING,
    })
    dispatch({
        type: sarebReducers.actionTypes.RESET_LOADING,
    })
    dispatch({
        type: webcontrolReducers.actionTypes.RESET_LOADING,
    })
}

export const setRoundTaskModalOpen = (controlappRoundTasksModalOpen) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_CONTROLAPP_ROUND_TASKS_MODAL_OPEN,
        controlappRoundTasksModalOpen: controlappRoundTasksModalOpen,
    })
}

export const setRoundIncidentModalOpen = (controlappRoundIncidentModalOpen) => (dispatch, getState) => {
    dispatch({
        type: generalReducers.actionTypes.SET_CONTROLAPP_ROUND_INCIDENT_MODAL_OPEN,
        controlappRoundIncidentModalOpen: controlappRoundIncidentModalOpen,
    })
}
