import { Button, Container, Grid, TextField } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as authAccessors from '../store/auth/accessors'
import * as generalAccessors from '../store/general/accessors'
import * as generalActions from '../store/general/actions'
import * as sarebAccessors from '../store/sareb/accessors'
import * as sarebActions from '../store/sareb/actions'
import Icon from '@mdi/react'
import {
    mdiCloudUpload,
    mdiCloudDownload,
    mdiHistory
} from "@mdi/js";
import { downloadFile } from '../helpers/downloads'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Autocomplete } from '@material-ui/lab'

const RegistrationRequestSpreadsheetUploadForm = () => {
    const dispatch = useDispatch()
    const canAccessSarebServicersApp = useSelector(
        authAccessors.getCanAccessSarebServicersApp
    )
    const canUseregistrationRequests = useSelector(
        authAccessors.getCanReadRegistrationRequestsTool
    )
    const blockFormSubmission = useSelector(
        sarebAccessors.getBlockFormSubmission
    )
    const classes = useStyles()
    const language = useSelector(generalAccessors.getLanguage)
    const servicers = useSelector(generalAccessors.getFilteredCustomers)
    const requestTypes = [
        {
            id: 1,
            name:
                language === 'es'
                    ? constants.translationsEs.ordinary
                    : constants.translationsEn.ordinary,
        },
        {
            id: 2,
            name:
                language === 'es'
                    ? constants.translationsEs.extraordinary
                    : constants.translationsEn.extraordinary,
        },
        {
            id: 3,
            name:
                language === 'es'
                    ? constants.translationsEs.launch
                    : constants.translationsEn.launch,
        },
    ]
    useEffect(() => {
        dispatch(sarebActions.resetSarebState())
        dispatch(
            generalActions.setViewName(
                constants.viewNames.REGISTRATION_REQUEST_SPREADSHEET_FORM
            )
        )
        return function cleanup() {
            dispatch(sarebActions.resetSarebState())
        }
    }, [dispatch])
    const handleSampleDownloadPress = () => {
        downloadFile(
            process.env.PUBLIC_URL + '/plantilla_sistemas_solicitados.xlsx',
            'plantilla_sistemas_solicitados.xlsx'
        )
    }
    const handleResetButtonPress = () => {
        dispatch(sarebActions.resetSarebState())
        formik.resetForm()
    }
    const validationSchema = yup.object({
        servicer: yup
            .object()
            .shape({
                name: yup.string().required(),
                customer_code: yup.string().required(),
            })
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.servicerRequired
                    : constants.translationsEn.validation.servicerRequired
            )
            .nullable(),
        requestType: yup
            .object()
            .shape({
                name: yup.string().required(),
                id: yup.string().required(),
            })
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.requestTypeRequired
                    : constants.translationsEn.validation.requestTypeRequired
            )
            .nullable(),
        spreadSheet: yup
            .mixed()
            .required(
                language === 'es'
                    ? constants.translationsEs.validation.spreadsheetRequired
                    : constants.translationsEn.validation.spreadsheetRequired
            )
            .test(
                'fileFormat',
                language === 'es'
                    ? constants.translationsEs.validation.spreadsheetFormat
                    : constants.translationsEn.validation.spreadsheetFormat,
                (values) => {
                    return (
                        values &&
                        values.type ===
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    )
                }
            ),
    })
    const formik = useFormik({
        initialValues: {
            servicer: null,
            requestType: null,
            spreadSheet: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sarebActions.uploadRegistrationRequestSpreadsheet(values))
        },
    })
    return canAccessSarebServicersApp && canUseregistrationRequests ? (
        <Container maxWidth='xl' className={classes.container}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid container item>
                        <h1>
                            {language === 'es'
                                ? constants.viewNamesEs
                                      .registrationRequestUpload
                                : constants.viewNamesEn
                                      .registrationRequestUpload}
                        </h1>
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={6}>
                            <Autocomplete
                                id='servicers'
                                size='small'
                                options={servicers}
                                value={formik.values.servicer}
                                getOptionLabel={(servicer) =>
                                    servicer ? servicer.name : ''
                                }
                                getOptionSelected={(option, value) => {
                                    return value.value === option.value
                                }}
                                onOpen={formik.handleBlur}
                                onChange={(e, selectedServicer) =>
                                    formik.setFieldValue(
                                        'servicer',
                                        selectedServicer || null
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id='servicer'
                                        label='Servicer'
                                        fullWidth
                                        variant='outlined'
                                        error={Boolean(
                                            formik.touched.servicer &&
                                                formik.errors.servicer
                                        )}
                                        helperText={
                                            formik.touched.servicer &&
                                            formik.errors.servicer
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id='requestTypes'
                                size='small'
                                options={requestTypes}
                                value={formik.values.requestType}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => {
                                    return value.value === option.value
                                }}
                                onOpen={formik.handleBlur}
                                onChange={(e, selectedRequestType) =>
                                    formik.setFieldValue(
                                        'requestType',
                                        selectedRequestType || null
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id='requestType'
                                        label={
                                            language === 'es'
                                                ? constants.translationsEs
                                                      .requestType
                                                : constants.translationsEn
                                                      .requestType
                                        }
                                        fullWidth
                                        variant='outlined'
                                        error={Boolean(
                                            formik.touched.requestType &&
                                                formik.errors.requestType
                                        )}
                                        helperText={
                                            formik.touched.requestType &&
                                            formik.errors.requestType
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} justify='space-between'>
                        <Grid item xs={12}>
                            <TextField
                                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                id='spreadSheet'
                                type='file'
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'spreadSheet',
                                        event.currentTarget.files[0] || null
                                    )
                                }}
                                fullWidth
                                variant='outlined'
                                size='small'
                                error={Boolean(
                                    formik.touched.spreadSheet &&
                                        formik.errors.spreadSheet
                                )}
                                helperText={
                                    formik.touched.spreadSheet &&
                                    formik.errors.spreadSheet
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify='space-between'>
                        <Grid item xs={4}>
                            <Button
                                variant='contained'
                                color='default'
                                disabled={blockFormSubmission}
                                type='submit'
                                startIcon={<Icon path={mdiCloudUpload} size={1} />}
                            >
                                {language === 'es'
                                    ? constants.translationsEs.upload
                                    : constants.translationsEn.upload}
                            </Button>
                            {blockFormSubmission && (
                                <Button
                                    variant='contained'
                                    color='default'
                                    onClick={handleResetButtonPress}
                                    startIcon={<Icon path={mdiHistory} size={1} />}
                                >
                                    {language === 'es'
                                        ? constants.translationsEs.reset
                                        : constants.translationsEn.reset}
                                </Button>
                            )}
                        </Grid>
                        <Grid container item xs={2} justify='flex-end'>
                            <Button
                                variant='contained'
                                color='default'
                                onClick={handleSampleDownloadPress}
                                startIcon={<Icon path={mdiCloudDownload} size={1} />}
                            >
                                {language === 'es'
                                    ? constants.translationsEs.sample
                                    : constants.translationsEn.sample}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Container>
    ) : null
}

export default RegistrationRequestSpreadsheetUploadForm
