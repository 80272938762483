import { mdiMapMarker } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Tooltip, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as generalAccessors from '../../store/general/accessors'

const GoogleMapLink = (props) => {
    const { latitude, longitude } = props
    const language = useSelector(generalAccessors.getLanguage)
    const theme = useTheme()
    return latitude && longitude ? (
        <a
            href={
                'https://maps.google.com/maps?z=12&t=m&q=loc:' +
                latitude +
                '+' +
                longitude
            }
            target='_blank'
            rel='noopener noreferrer'
        >
            <Tooltip title={language === 'es' ? constants.translationsEs.location : constants.translationsEn.location}>
                <Button>
                    <Icon path={mdiMapMarker} size={1} color={theme.palette.primary.main} />
                </Button>
            </Tooltip>
        </a>
    ) : null
}

export default GoogleMapLink
