const initialState = {
    error: null,
    success: null,
    showModal: false,
}

export const actionTypes = {
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
    CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
    SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
    CLEAR_SUCCESS_MESSAGE: "CLEAR_SUCCESS_MESSAGE",
    CLEAR_MESSAGES: "CLEAR_MESSAGES",
    SHOW_NOTIFICATION_MODAL: "SHOW_NOTIFICATION_MODAL",
    HIDE_NOTIFICATION_MODAL: "HIDE_NOTIFICATION_MODAL",
}

const notification = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ERROR_MESSAGE:
            return {
                error: action.error,
                success: null,
            }
        case actionTypes.CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                error: null,
            }
        case actionTypes.SET_SUCCESS_MESSAGE:
            return {
                error: null,
                success: action.success,
            }
        case actionTypes.CLEAR_SUCCESS_MESSAGE:
            return {
                ...state,
                success: null,
            }
        case actionTypes.CLEAR_MESSAGES:
            return {
                ...state,
                success: null,
                error: null,
            }
        case actionTypes.SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                showModal: true,
            }
        case actionTypes.HIDE_NOTIFICATION_MODAL:
            return {
                ...state,
                showModal: false,
            }
        default:
            return state
    }
}

export default notification
