export const getSelectedInstallation = (state) => {
    return state.webcontrol.selectedInstallation
}

export const getInstallations = (state) => {
    return state.webcontrol.installations
}

export const getLoading = (state) => {
    return state.webcontrol.loading
}

export const getCurrentFilterValues = (state) => {
    const subscriberCode = state.webcontrol.selectedInstallation.subscriber_code
    return {
        subscriberCode,
    }
}
