import { mdiListStatus } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Tooltip, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'
import * as generalActions from '../../store/general/actions'

const RoundTasksLink = (props) => {
    const { originalRowData } = props
    const dispatch = useDispatch()
    const language = useSelector(generalAccessors.getLanguage)
    const theme = useTheme()
    const handleRoundTasksLinkButtonPress = () => {
        dispatch(controlappActions.setRoundTasksData({
            point: originalRowData.point,
        }))
        dispatch(controlappActions.fetchRoundTasksData(originalRowData.tasks_round_id, originalRowData.point))
        dispatch(generalActions.setRoundTaskModalOpen(true))
    }
    return (
        <Tooltip title={language === 'es' ? constants.translationsEs.tasks : constants.translationsEn.tasks}>
            <Button onClick={handleRoundTasksLinkButtonPress}>
                <Icon path={mdiListStatus} size={1} color={theme.palette.primary.main} />
            </Button>
        </Tooltip>
    )
}

export default RoundTasksLink
