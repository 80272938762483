const initialState = {
    centers: [],
    filteredContracts: [],
    filteredServices: [],
    selectedCenter: null,
    selectedContract: null,
    selectedService: null,
    evaluationReport: null,
    idRegImage: null,
    image: null,
    incidentData: [],
    loading: false,
    visitData: [],
    roundIncidentData: [],
    roundIncidentImage: '',
    loadingRoundIncidentData: false,
    roundTasksData: [],
    roundTasks: [],
    loadingRoundTasksData: false,
    hasError: false,
    roundTaskData: [],
    loadingIncidentImage: false,
    loadingIncidentData: false,
    loadingVisitData: false,
}

export const actionTypes = {
    SELECT_CENTER: 'SELECT_CENTER',
    SELECT_CONTRACT: 'SELECT_CONTRACT',
    SELECT_SERVICE: 'SELECT_SERVICE',
    UPDATE_SERVICES_DROPDOWN: 'UPDATE_SERVICES_DROPDOWN',
    UPDATE_CONTRACTS_DROPDOWN: 'UPDATE_CONTRACTS_DROPDOWN',
    FETCH_DROPDOWN_DATA_REQUEST: 'FETCH_DROPDOWN_DATA_REQUEST',
    FETCH_DROPDOWN_DATA_SUCCESS: 'FETCH_DROPDOWN_DATA_SUCCESS',
    FETCH_DROPDOWN_DATA_FAILURE: 'FETCH_DROPDOWN_DATA_FAILURE',
    FETCH_EVALUATION_REPORT_REQUEST: 'FETCH_EVALUATION_REPORT_REQUEST',
    FETCH_EVALUATION_REPORT_SUCCESS: 'FETCH_EVALUATION_REPORT_SUCCESS',
    FETCH_EVALUATION_REPORT_FAILURE: 'FETCH_EVALUATION_REPORT_FAILURE',
    SET_IMAGE_REGISTER_ID: 'SET_IMAGE_REGISTER_ID',
    FETCH_IMAGE_REQUEST: 'FETCH_IMAGE_REQUEST',
    FETCH_IMAGE_SUCCESS: 'FETCH_IMAGE_SUCCESS',
    FETCH_IMAGE_FAILURE: 'FETCH_IMAGE_FAILURE',
    RESET_IMAGE: 'RESET_IMAGE',
    FETCH_INCIDENT_DATA_REQUEST: 'FETCH_INCIDENT_DATA_REQUEST',
    FETCH_INCIDENT_DATA_SUCCESS: 'FETCH_INCIDENT_DATA_SUCCESS',
    FETCH_INCIDENT_DATA_FAILURE: 'FETCH_INCIDENT_DATA_FAILURE',
    CLEAR_INCIDENT_DATA: 'CLEAR_INCIDENT_DATA',
    RESET_CONTROLAPP_SELECTORS: 'RESET_CONTROLAPP_SELECTORS',
    DOWNLOAD_INCIDENT_PDF_REQUEST: 'DOWNLOAD_INCIDENT_PDF_REQUEST',
    DOWNLOAD_INCIDENT_PDF_SUCCESS: 'DOWNLOAD_INCIDENT_PDF_SUCCESS',
    DOWNLOAD_INCIDENT_PDF_FAILURE: 'DOWNLOAD_INCIDENT_PDF_FAILURE',
    RESET_LOADING: 'RESET_LOADING',
    FETCH_VISIT_DATA_REQUEST: 'FETCH_VISIT_DATA_REQUEST',
    FETCH_VISIT_DATA_SUCCESS: 'FETCH_VISIT_DATA_SUCCESS',
    FETCH_VISIT_DATA_FAILURE: 'FETCH_VISIT_DATA_FAILURE',
    CLEAR_VISIT_DATA: 'CLEAR_VISIT_DATA',
    DOWNLOAD_VISIT_PDF_REQUEST: 'DOWNLOAD_VISIT_PDF_REQUEST',
    DOWNLOAD_VISIT_PDF_SUCCESS: 'DOWNLOAD_VISIT_PDF_SUCCESS',
    DOWNLOAD_VISIT_PDF_FAILURE: 'DOWNLOAD_VISIT_PDF_FAILURE',
    SET_ROUND_INCIDENT_DATA: 'SET_ROUND_INCIDENT_DATA',
    FETCH_ROUND_INCIDENT_IMAGE_REQUEST: 'FETCH_ROUND_INCIDENT_IMAGE_REQUEST',
    FETCH_ROUND_INCIDENT_IMAGE_SUCCESS: 'FETCH_ROUND_INCIDENT_IMAGE_SUCCESS',
    FETCH_ROUND_INCIDENT_IMAGE_FAILURE: 'FETCH_ROUND_INCIDENT_IMAGE_FAILURE',
    RESET_ROUND_INCIDENT_DATA: 'RESET_ROUND_INCIDENT_DATA',
    SET_ROUND_TASKS_DATA: 'SET_ROUND_TASKS_DATA',
    RESET_ROUND_TASKS_DATA: 'RESET_ROUND_TASKS_DATA',
    FETCH_ROUND_TASKS_REQUEST: 'FETCH_ROUND_TASKS_REQUEST',
    FETCH_ROUND_TASKS_SUCCESS: 'FETCH_ROUND_TASKS_SUCCESS',
    FETCH_ROUND_TASKS_FAILURE: 'FETCH_ROUND_TASKS_FAILURE',
}


const controlapp = (state = initialState, action) => {
    switch (action.type) {
        // SET/SELECT
        case actionTypes.SELECT_CENTER:
            return {
                ...state,
                selectedCenter: action.selectedCenter,
            }
        case actionTypes.SELECT_CONTRACT:
            return {
                ...state,
                selectedContract: action.selectedContract,
            }
        case actionTypes.SELECT_SERVICE:
            return {
                ...state,
                selectedService: action.selectedService,
            }
        case actionTypes.UPDATE_CONTRACTS_DROPDOWN:
            return {
                ...state,
                filteredContracts: action.filteredContracts,
            }
        case actionTypes.UPDATE_SERVICES_DROPDOWN:
            return {
                ...state,
                filteredServices: action.filteredServices,
            }
        case actionTypes.SET_IMAGE_REGISTER_ID:
            return {
                ...state,
                idRegImage: action.idRegImage
            }
        case actionTypes.RESET_CONTROLAPP_SELECTORS:
            return {
                ...state,
                selectedCenter: null,
                selectedContract: null,
                selectedService: null,
            }
        case actionTypes.RESET_IMAGE:
            return {
                ...state,
                image: null,
            }
        case actionTypes.CLEAR_INCIDENT_DATA:
            return {
                ...state,
                incidentData: [],
            }
        case actionTypes.CLEAR_VISIT_DATA:
            return {
                ...state,
                visitData: [],
            }
        case actionTypes.SET_ROUND_INCIDENT_DATA:
            return {
                ...state,
                roundIncidentData: action.roundIncidentData,
            }
        case actionTypes.RESET_ROUND_INCIDENT_DATA:
            return {
                ...state,
                roundIncidentImage: '',
                roundIncidentData: [],
            }
        case actionTypes.SET_ROUND_TASKS_DATA:
            return {
                ...state,
                roundTasksData: action.roundTasksData,
            }
        case actionTypes.RESET_ROUND_TASKS_DATA:
            return {
                ...state,
                roundTasksData: [],
                roundTasks: [],
            }
        // FETCH REQUEST
        case actionTypes.FETCH_DROPDOWN_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                centers: [],
            }
        case actionTypes.FETCH_EVALUATION_REPORT_REQUEST:
        case actionTypes.DOWNLOAD_INCIDENT_PDF_REQUEST:
        case actionTypes.DOWNLOAD_VISIT_PDF_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_INCIDENT_DATA_REQUEST:
            return {
                ...state,
                incidentData: [],
                loadingIncidentData: true,
                hasError: false,
            }
        case actionTypes.FETCH_VISIT_DATA_REQUEST:
            return {
                ...state,
                visitData: [],
                loadingVisitData: true,
                hasError: false,
            }
        case actionTypes.FETCH_ROUND_INCIDENT_IMAGE_REQUEST:
            return {
                ...state,
                roundIncidentImage: '',
                loadingRoundIncidentData: true,
                hasError: false,
            }
        case actionTypes.FETCH_ROUND_TASKS_REQUEST:
            return {
                ...state,
                roundTasks: [],
                loadingRoundTasksData: true,
                hasError: false,
            }
        case actionTypes.FETCH_IMAGE_REQUEST:
            return {
                ...state,
                incidentImage: null,
                loadingIncidentImage: true,
            }
        // FETCH SUCCESS
        case actionTypes.FETCH_DROPDOWN_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                centers: action.centers,
                filteredContracts: action.filteredContracts,
                filteredServices: action.filteredServices,
            }
        case actionTypes.FETCH_EVALUATION_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                evaluationReport: action.evaluationReport,
            }
        case actionTypes.FETCH_IMAGE_SUCCESS:
            return {
                ...state,
                image: action.image,
                loadingIncidentImage: false,
            }
        case actionTypes.FETCH_INCIDENT_DATA_SUCCESS:
            return {
                ...state,
                incidentData: action.incidentData,
                loadingIncidentData: false,
            }
        case actionTypes.DOWNLOAD_INCIDENT_PDF_SUCCESS:
        case actionTypes.DOWNLOAD_VISIT_PDF_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.FETCH_VISIT_DATA_SUCCESS:
            return {
                ...state,
                visitData: action.visitData,
                loadingVisitData: false,
            }
        case actionTypes.FETCH_ROUND_INCIDENT_IMAGE_SUCCESS:
            return {
                ...state,
                roundIncidentImage: action.roundIncidentImage,
                loadingRoundIncidentData: false,
            }
        case actionTypes.FETCH_ROUND_TASKS_SUCCESS:
            return {
                ...state,
                roundTasks: action.roundTasks,
                loadingRoundTasksData: false,
            }
        // FETCH FAILURE
        case actionTypes.FETCH_DROPDOWN_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                filteredContracts: [],
                filteredServices: [],
            }
        case actionTypes.FETCH_EVALUATION_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                evaluationReport: null,
            }
        case actionTypes.FETCH_IMAGE_FAILURE:
            return {
                ...state,
                image: null,
                loadingIncidentImage: false,
            }
        case actionTypes.FETCH_INCIDENT_DATA_FAILURE:
            return {
                ...state,
                incidentData: [],
                loadingIncidentData: false,
                hasError: true,
            }
        case actionTypes.FETCH_VISIT_DATA_FAILURE:
            return {
                ...state,
                visitData: [],
                loadingVisitData: false,
                hasError: true,
            }
        case actionTypes.DOWNLOAD_INCIDENT_PDF_FAILURE:
        case actionTypes.DOWNLOAD_VISIT_PDF_FAILURE:
        case actionTypes.RESET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.FETCH_ROUND_INCIDENT_IMAGE_FAILURE:
            return {
                ...state,
                loadingRoundIncidentData: false,
                hasError: true,
            }
        case actionTypes.FETCH_ROUND_TASKS_FAILURE:
            return {
                ...state,
                loadingRoundTasksData: false,
                hasError: true,
            }
        // DEFAULT
        default:
            return state
    }
}

export default controlapp
