export const getShowModal = (state) => {
    return state.notification.showModal
}

export const getError = (state) => {
    return state.notification.error
}

export const getSuccess = (state) => {
    return state.notification.success
}
