import { useEffect } from 'react'
import Container from '@material-ui/core/Container'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as notificationActions from '../store/notification/actions'
import * as generalActions from '../store/general/actions'
import * as generalAccessors from '../store/general/accessors'
import CarouselHome from '../components/carousel/CarouselHome'
import PowerbiHomeScreen from './PowerbiHomeScreen'

const HomeScreen = () => {
    const classes = useStyles()
    const language = useSelector(generalAccessors.getLanguage)
    const dispatch = useDispatch()
    const showPowerbiHomeScreen = useSelector(
        generalAccessors.getShowPowerbiHomeScreen
    )
    useEffect(() => {
        dispatch(notificationActions.clearMessages())
        dispatch(generalActions.setViewName(constants.viewNames.HOME))
    }, [dispatch])
    return (
        <Container maxWidth='xl' className={classes.container}>
            <h1>
                {language === 'es'
                    ? constants.viewNamesEs.home
                    : constants.viewNamesEn.home}
            </h1>
            {showPowerbiHomeScreen ? <PowerbiHomeScreen /> : <CarouselHome />}
        </Container>
    )
}
export default HomeScreen
