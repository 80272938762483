import * as axios from '../../helpers/axios'
import * as constants from '../../helpers/constants'

export const fetchEvaluationReport = (evaluationId) => {
    return axios.runGet('auth/evaluations/download_inspection/' + evaluationId)
}

export const fetchImage = (idRegImage, selectedCompany, selectedCustomer) => {
    const companyCode = selectedCompany.company_code
    const customerCode = selectedCustomer.customer_code
    return axios.runGet(
        'auth/surveillances/get_tracking_image/' +
        idRegImage +
        '?selectedCompany=' +
        companyCode +
        '&selectedCustomer=' +
        customerCode
    )
}

export const fetchIncidentData = (incidentId, selectedCompany, selectedCustomer) => {
    const companyCode = selectedCompany.company_code
    const customerCode = selectedCustomer.customer_code
    return axios.runGet(
        'auth/incidents/get_watchman_information/' +
        incidentId +
        '?selectedCompany=' +
        companyCode +
        '&selectedCustomer=' +
        customerCode
    )
}

export const fetchDropdownData = (
    selectedCompany,
    selectedCustomer,
    viewName
) => {
    const companyCode = selectedCompany.company_code
    const customerCode = selectedCustomer.customer_code
    switch (viewName) {
        case constants.viewNames.ROUNDS:
            return axios.runGet(
                'auth/services/rounds?selectedCompany=' +
                companyCode +
                '&selectedCustomer=' +
                customerCode
            )
        case constants.viewNames.INCIDENTS:
        case constants.viewNames.VISITS:
            return axios.runGet(
                'auth/services/incidents?selectedCompany=' +
                companyCode +
                '&selectedCustomer=' +
                customerCode
            )
        case constants.viewNames.EVALUATIONS:
            return axios.runGet(
                'auth/services/evaluations?selectedCompany=' +
                companyCode +
                '&selectedCustomer=' +
                customerCode
            )
        case constants.viewNames.SURVEILLANCE:
            return axios.runGet(
                'auth/services/surveillance?selectedCompany=' +
                companyCode +
                '&selectedCustomer=' +
                customerCode
            )
        case constants.viewNames.DISCONTINUOUS_SURVEILLANCE:
            return axios.runGet(
                'auth/services/discontinuous_surveillance?selectedCompany=' +
                companyCode +
                '&selectedCustomer=' +
                customerCode
            )
        case constants.viewNames.KEYRINGS:
            return axios.runGet(
                'auth/services/traceapp?selectedCompany=' +
                companyCode +
                '&selectedCustomer=' +
                customerCode
            )
        default:
            return
    }
}

export const downloadIncidentPdf = (incidentId, selectedCompany, selectedCustomer) => {
    const params = {
        selectedCompany,
        selectedCustomer,
        incidentId,
    }
    return axios.runPost('auth/incidents/download_incident_pdf', params)
}

export const fetchVisitData = (visitId, selectedCompany, selectedCustomer) => {
    const companyCode = selectedCompany.company_code
    const customerCode = selectedCustomer.customer_code
    return axios.runGet(
        'auth/visits/get_modal_data/' +
        visitId +
        '?selectedCompany=' +
        companyCode +
        '&selectedCustomer=' +
        customerCode
    )
}

export const downloadVisitPdf = (visitId, selectedCompany, selectedCustomer) => {
    const params = {
        selectedCompany,
        selectedCustomer,
    }
    return axios.runPost('auth/visits/download_visit_pdf/' + visitId, params)
}

export const fetchRoundIncidentImage = (attachmentId) => {
    return axios.runGet('auth/rounds/get_round_incident_image/' + attachmentId)
}

export const fetchRoundTasksData = (tasksRoundId, point, customerCode) => { 
    const params = {
        tasks_round_id: tasksRoundId,
        point: point,
        customer_code: customerCode,
    }
    return axios.runPost('auth/rounds/get_round_tasks', params)
}
