const initialState = {
    installations: [],
    selectedInstallation: null,
    loading: false,
}

export const actionTypes = {
    SELECT_INSTALLATION: 'SELECT_INSTALLATION',
    FETCH_INSTALLATIONS_BY_CUSTOMER_REQUEST: 'FETCH_INSTALLATIONS_BY_CUSTOMER_REQUEST',
    FETCH_INSTALLATIONS_BY_CUSTOMER_SUCCESS: 'FETCH_INSTALLATIONS_BY_CUSTOMER_SUCCESS',
    FETCH_INSTALLATIONS_BY_CUSTOMER_FAILURE: 'FETCH_INSTALLATIONS_BY_CUSTOMER_FAILURE',
    RESET_INSTALLATION_SELECTOR: 'RESET_INSTALLATION_SELECTOR',
    RESET_LOADING: 'RESET_LOADING',
}

const webcontrol = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_INSTALLATION:
            return {
                ...state,
                selectedInstallation: action.selectedInstallation,
            }
        case actionTypes.FETCH_INSTALLATIONS_BY_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                installations: [],
            }
        case actionTypes.FETCH_INSTALLATIONS_BY_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                installations: action.installations,
            }
        case actionTypes.FETCH_INSTALLATIONS_BY_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.RESET_INSTALLATION_SELECTOR:
            return {
                ...state,
                selectedInstallation: null,
            }
        case actionTypes.RESET_LOADING:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export default webcontrol
