import slide1 from '../assets/img/slide_portalcliente_001.png';
import slide2 from '../assets/img/slide_portalcliente_002.png';
import slide3 from '../assets/img/slide_portalcliente_003.png';
import slide4 from '../assets/img/slide_portalcliente_004.png';
import slide5 from '../assets/img/slide_portalcliente_005.png';
import slide6 from '../assets/img/slide_portalcliente_006.png';


const SlideDataHome = [

  {
    "id": 1,
    "title": "TraceApp",
    "img": slide1
  },

  {
    "id": 2,
    "title": "Acudas",
    "img": slide2
  },

  {
    "id": 3,
    "title": "Informes e inspecciones",
    "img": slide3
  },

  {
    "id": 4,
    "title": "Informes de Servicio",
    "img": slide4
  },

  {
    "id": 5,
    "title": "Control de Visitas",
    "img": slide5
  },

  {
    "id": 6,
    "title": "Acudas",
    "img": slide6
  }
]

export default SlideDataHome;