import { useTheme } from '@material-ui/core/styles'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Card, CardContent, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Modal, ThemeProvider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as constants from '../../helpers/constants'
import * as controlappAccessors from '../../store/controlapp/accessors'
import * as controlappActions from '../../store/controlapp/actions'
import * as generalAccessors from '../../store/general/accessors'
import * as generalActions from '../../store/general/actions'

const ControlappRoundTasksModal = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const language = useSelector(generalAccessors.getLanguage)
    const open = useSelector(generalAccessors.getControlappRoundTasksModalOpen)
    const roundTaskData = useSelector(controlappAccessors.getRoundTasksData)
    const roundTasks = useSelector(controlappAccessors.getRoundTasks)
    const loading = useSelector(controlappAccessors.getLoadingRoundTasksData)
    const hasError = useSelector(controlappAccessors.getHasError)
    const handleCloseModal = () => {
        dispatch(generalActions.setRoundTaskModalOpen(false))
        dispatch(controlappActions.resetRoundTasksData())
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleCloseModal}
            >
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        boxShadow: 24,
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    style={{ backgroundColor: theme.palette.primary.main }}
                >
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography color={theme.palette.background.default} variant='h4'>{language === 'es' ? constants.translationsEs.roundTasksDetails : constants.translationsEn.roundTasksDetails}{roundTaskData.point}</Typography>
                    </CardContent>
                    {(roundTasks && roundTasks.length > 0) && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <List>
                                {roundTasks.map((taskData) => (
                                    <ListItem>
                                        <ListItemText>
                                            <Typography variant='span' sx={{ fontSize: '16px' }}>{taskData.description}{' '}</Typography>
                                            <Typography variant='span' sx={{ fontSize: '12px' }}>{taskData.completed ? '(' + taskData.completed_at + ' - ' + taskData.completed_by + ')' : ''}</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            {taskData.completed ? <CheckCircleIcon color='success' /> : <CancelIcon color='grey' />}
                                        </ListItemIcon>
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    )}
                    {hasError && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.errorFetchingRoundTasks : constants.translationsEn.errorFetchingRoundTasks}</Typography>
                            <Typography variant='h6'>{language === 'es' ? constants.translationsEs.pleaseTryAgain : constants.translationsEn.pleaseTryAgain}</Typography>
                        </CardContent>
                    )}
                    {loading && (
                        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant='h5'>{language === 'es' ? constants.translationsEs.loadingTasks : constants.translationsEn.loadingTasks}</Typography>
                            <LinearProgress sx={{ mt: 5, mb: 5 }} color='primary' />
                        </CardContent>
                    )}
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.background.default }}>
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={handleCloseModal}
                            startIcon={<CancelIcon />}
                        >
                            {language === 'es' ? constants.translationsEs.close : constants.translationsEn.close}
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </ThemeProvider>
    )
}

export default ControlappRoundTasksModal
