import { isAfter } from 'date-fns'
import jwtDecode from 'jwt-decode'
import * as constants from '../../helpers/constants'

export const getAuthenticated = (state) => {
    return state.auth.authenticated
}

export const getLoading = (state) => {
    return state.auth.loading
}

export const canAccessApp = (state, appCode) => {
    let can = false
    if (state.auth.token) {
        const decodedToken = jwtDecode(state.auth.token)
        if (Object.keys(decodedToken.permissions).length > 0) {
            if (appCode in decodedToken.permissions) {
                can = true
            }
        }
    }
    return can
}

export const getCanAccessControlApp = (state) => {
    return canAccessApp(state, constants.appNames.CONTROLAPP)
}

export const getCanAccessWebcontrolApp = (state) => {
    return canAccessApp(state, constants.appNames.WEBCONTROL)
}

export const getCanAccessEvaluationsApp = (state) => {
    return canAccessApp(state, constants.appNames.EVALUATIONS)
}

export const getCanAccessSarebServicersApp = (state) => {
    return canAccessApp(state, constants.appNames.SAREB_SERVICERS)
}

export const getCanAccessTraceApp = (state) => {
    return canAccessApp(state, constants.appNames.TRACEAPP)
}

export const getCanAccessVisitorControlApp = (state) => {
    return canAccessApp(state, constants.appNames.VISITOR_CONTROL)
}

export const canUseTool = (state, appCode, toolCode, actionCode) => {
    let can = false
    if (state.auth.token) {
        const decodedToken = jwtDecode(state.auth.token)
        if (canAccessApp(state, appCode)) {
            const permissions = decodedToken.permissions[appCode]
            permissions.forEach((permission) => {
                if (
                    permission.tool_code === toolCode &&
                    permission[actionCode] === 1
                ) {
                    can = true
                }
            })
        }
    }
    return can
}

export const getCanReadSurveillanceTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.CONTROLAPP,
        constants.toolCodes.surveillanceServices,
        constants.actionCodes.read
    )
}

export const getCanReadRoundsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.CONTROLAPP,
        constants.toolCodes.rounds,
        constants.actionCodes.read
    )
}

export const getCanReadDiscontinuousSurveillanceTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.CONTROLAPP,
        constants.toolCodes.discontinuousSurveillanceServices,
        constants.actionCodes.read
    )
}

export const getCanReadEvaluationsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.EVALUATIONS,
        constants.toolCodes.evaluations,
        constants.actionCodes.read
    )
}

export const getCanReadEventsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.WEBCONTROL,
        constants.toolCodes.events,
        constants.actionCodes.read
    )
}

export const getCanReadIncidentsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.CONTROLAPP,
        constants.toolCodes.incidents,
        constants.actionCodes.read
    )
}

export const getCanReadVisitsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.CONTROLAPP,
        constants.toolCodes.visits,
        constants.actionCodes.read
    )
}

export const getCanReadRegistrationRequestsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.SAREB_SERVICERS,
        constants.toolCodes.registrationRequests,
        constants.actionCodes.read
    )
}

export const getCanReadKeyringsTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.TRACEAPP,
        constants.toolCodes.keyrings,
        constants.actionCodes.read
    )
}

export const getCanReadVisitorControlTool = (state) => {
    return canUseTool(
        state,
        constants.appNames.VISITOR_CONTROL,
        constants.toolCodes.visitorControl,
        constants.actionCodes.read
    )
}

export const getExpired = (state) => {
    const now = new Date()
    return isAfter(now, new Date(state.auth.expires))
}
