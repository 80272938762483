import { createMuiTheme } from '@material-ui/core/styles'

export const grupoControltheme = createMuiTheme({
  palette: {
    primary: {
      light: '#AC4B62',
      main: '#9B2743',
      dark: '#4E1422',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#1b2E46',
      main: '#17263A',
      dark: '#101A28',
      contrastText: '#FFFFFF',
    },
  }
})
