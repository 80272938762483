import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Icon from '@mdi/react'
import { mdiLockOutline } from "@mdi/js";
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Spinner from '../components/Spinner'
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as authAccessors from '../store/auth/accessors'
import * as authActions from '../store/auth/actions'
import { grupoControltheme } from '../themes/grupocontrol'
import { ThemeProvider } from '@material-ui/core/styles'

const LoginScreen = () => {
    const classes = useStyles(grupoControltheme)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const isLoading = useSelector(authAccessors.getLoading)
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        switch (name) {
            case 'username':
                setUsername(value)
                break
            case 'password':
                setPassword(value)
                break
            default:
                break
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        dispatch(authActions.login(username, password))
    }
    let language = 'en'
    if (!navigator.language.startsWith('en')) {
        language = 'es'
    }
    useEffect(() => {
        dispatch(authActions.resetAuthState())
    }, [dispatch])
    return (
        <ThemeProvider theme={grupoControltheme}>
            <Container component='main' maxWidth='xs'>
                {isLoading && <Spinner />}
                <div className={classes.paper}>
                    <Typography component='h1' variant='h5'>
                        <Avatar className={classes.avatar}>
                            <Icon path={mdiLockOutline} size={1} />
                        </Avatar>
                        {language === 'es'
                            ? constants.translationsEs.signIn
                            : constants.translationsEn.signIn}
                    </Typography>
                    <form
                        method='POST'
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='username'
                            label={
                                language === 'es'
                                    ? constants.translationsEs
                                        .userNameEmailAddress
                                    : constants.translationsEn
                                        .userNameEmailAddress
                            }
                            name='username'
                            value={username}
                            onChange={handleChangeInput}
                            autoComplete='user-name'
                            autoFocus
                        />

                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label={
                                language === 'es'
                                    ? constants.translationsEs.password
                                    : constants.translationsEn.password
                            }
                            type='password'
                            value={password}
                            onChange={handleChangeInput}
                            id='password'
                            autoComplete='current-password'
                        />

                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                        >
                            {language === 'es'
                                ? constants.translationsEs.signIn
                                : constants.translationsEn.signIn}
                        </Button>
                    </form>
                </div>
            </Container>
        </ThemeProvider>
    )
}

export default LoginScreen
