import React from "react"

import { useSelector } from "react-redux"
import { ThemeProvider } from '@material-ui/core/styles'

import Main from "./containers/Main"
import { grupoControltheme } from "./themes/grupocontrol"
import { dimobaServiciosTheme } from "./themes/dimobaServicios"
import { getSelectedCompany } from "./store/general/accessors"

function App() {
	const selectedCompany = useSelector(getSelectedCompany)
	let currentTheme = grupoControltheme
	if (selectedCompany) {
		switch (selectedCompany.company_code) {
			case '02':
				currentTheme = dimobaServiciosTheme
				break
			default:
				break
		}
	}

	return (
		<ThemeProvider theme={currentTheme}>
			<Main />
		</ThemeProvider>
	)
}

export default App
