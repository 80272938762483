const initialState = {
    loading: false,
    authenticated: false,
    token: null,
    expires: null,
}

export const actionTypes = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    REFRESH_CONFIGURATION_REQUEST: "REFRESH_CONFIGURATION_REQUEST",
    REFRESH_CONFIGURATION_SUCCESS: "REFRESH_CONFIGURATION_SUCCESS",
    REFRESH_CONFIGURATION_FAILURE: "REFRESH_CONFIGURATION_FAILURE",

    RESET_AUTH_STATE: 'RESET_AUTH_STATE',
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
        case actionTypes.REFRESH_CONFIGURATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                loading: false,
                token: action.token,
                expires: action.expires,
            }
        case actionTypes.REFRESH_CONFIGURATION_SUCCESS:
            return {
                ...state,
                token: action.token,
                loading: false,
                authenticated: true,
                expires: action.expires,
            }
        case actionTypes.LOGIN_FAILURE:
        case actionTypes.REFRESH_CONFIGURATION_FAILURE:
        case actionTypes.RESET_AUTH_STATE:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default auth
