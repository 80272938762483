import { createMuiTheme } from '@material-ui/core/styles'

export const dimobaServiciosTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#80A3DD',
      main: '#0047BA',
      dark: '#00245D',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#1b2E46',
      main: '#17263A',
      dark: '#101A28',
      contrastText: '#FFFFFF',
    },
  }
})
