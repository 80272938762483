import React from 'react'
import { useStyles } from '../../styles/Styles'
import SliceDataHome from './SlideDataHome'
import Slider from 'infinite-react-carousel'


const CarouselHome = () => {

  const classes = useStyles()

  return <section className={classes.carouselSlider}>

      <Slider dots autoplay autoplaySpeed={6000} adaptiveHeight className={classes.carouselSliderContent}>
        {
          SliceDataHome.map((SliceDataHome) => <div key={SliceDataHome.id} >
            <img src={SliceDataHome.img} alt={SliceDataHome.title} className={classes.carouselSliderItem}/>
          </div>)
        }

      </Slider>

  </section> 

}

export default CarouselHome