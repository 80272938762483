import * as notificationReducers from "./reducers"
import * as generalReducers from '../general/reducers'

export const setError = (error) => (dispatch, getState) => {
    dispatch({
        type: notificationReducers.actionTypes.SET_ERROR_MESSAGE,
        error: error,
    })
}

export const setSuccess = (success) => (dispatch, getState) => {
    dispatch({
        type: notificationReducers.actionTypes.SET_SUCCESS_MESSAGE,
        success: success,
    })
}

export const clearMessages = () => (dispatch, getState) => {
    dispatch({
        type: notificationReducers.actionTypes.CLEAR_MESSAGES,
    })
}

export const showModal = (resetModals = true) => (dispatch, getState) => {
    if (resetModals) {
        dispatch({
            type: generalReducers.actionTypes.RESET_MODALS,
        })
    }
    dispatch({
        type: notificationReducers.actionTypes.SHOW_NOTIFICATION_MODAL,
    })
    setTimeout(() => {
        dispatch(hideModal())
    }, 5000)
}

export const hideModal = () => (dispatch, getState) => {
    dispatch({
        type: notificationReducers.actionTypes.HIDE_NOTIFICATION_MODAL,
    })
}
