import Container from '@material-ui/core/Container'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../components/DataTable'
import { useStyles } from '../styles/Styles'
import * as constants from '../helpers/constants'
import * as notificationActions from '../store/notification/actions'
import * as authActions from '../store/auth/actions'
import * as authAccessors from '../store/auth/accessors'
import * as generalAccessors from '../store/general/accessors'
import * as generalActions from '../store/general/actions'
import * as webcontrolActions from '../store/webcontrol/actions'
import WebcontrolFilters from '../components/WebcontrolFilters'

const EventsScreen = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const canReadEventsTool = useSelector(authAccessors.getCanReadEventsTool)
    const language = useSelector(generalAccessors.getLanguage)
    const selectedCustomer = useSelector(generalAccessors.getSelectedCustomer)

    useEffect(() => {
        dispatch(generalActions.setViewName(constants.viewNames.EVENTS))
        if (canReadEventsTool) {
            dispatch(authActions.refreshConfiguration())
                .then(() => dispatch(webcontrolActions.fetchInstallationsByCustomer(selectedCustomer)))
                .catch((error) => null)
        } else {
            dispatch(
                notificationActions.setError(
                    language === 'es'
                        ? constants.translationsEs.errorPermissionsView
                        : constants.translationsEn.errorPermissionsView
                )
            )
        }
    }, [dispatch, canReadEventsTool, language, selectedCustomer])

    return canReadEventsTool ? (
        <Container maxWidth='xl' className={classes.container}>
            <h1>
                {language === 'es'
                    ? constants.viewNamesEs.events
                    : constants.viewNamesEn.events}
            </h1>
            <WebcontrolFilters />
            <DataTable />
        </Container>
    ) : null
}

export default EventsScreen
