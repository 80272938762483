import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import auth from './auth/reducers'
import general from './general/reducers'
import table from './table/reducers'
import notification from './notification/reducers'
import sareb from './sareb/reducers'
import webcontrol from './webcontrol/reducers'
import controlapp from './controlapp/reducers'
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import visitorlist from './visitorlist/reducers'

export const browserHistory = createBrowserHistory()
const encryptor = encryptTransform({
    secretKey: 'Mycerecefo0',
})
const persistConfig = {
    key: 'root',
    storage: storage,
    transforms: [encryptor],
    blacklist: [
        'auth',
        'general',
        'controlapp',
        'notification',
        'sareb',
        'table',
        'visitorlist',
        'webcontrol',
    ],
}
const authPersistConfig = {
    key: 'auth',
    storage: storage,
    transforms: [encryptor],
    blacklist: ['loading'],
}
const generalPersistConfig = {
    key: 'general',
    storage: storage,
    transforms: [encryptor],
    blacklist: [
        'controlappRoundTasksModalOpen',
        'controlappRoundIncidentModalOpen',
        'controlappImageModalOpen',
        'customerMenuOpen',
        'incidentDetailModalOpen',
        'languageSelectorOpen',
        'sarebMenuOpen',
        'traceAppMenuOpen',
        'visitDetailModalOpen',
        'visitorControlMenuOpen',
        'webcontrolMenuOpen',
    ],
}

const configureStore = () => {
    const reducers = {
        auth: persistReducer(authPersistConfig, auth),
        controlapp: controlapp,
        general: persistReducer(generalPersistConfig, general),
        notification: notification,
        sareb: sareb,
        table: table,
        visitorlist: visitorlist,
        webcontrol: webcontrol,
    }
    let middlewares = [thunk, routerMiddleware(browserHistory)]
    if (process.env.NODE_ENV === `development`) {
        const logger = createLogger({
            collapsed: true,
        })
        middlewares.push(logger)
    }
    const combinedReducers = combineReducers(reducers)
    const persistedReducer = persistReducer(persistConfig, combinedReducers)
    const store = createStore(persistedReducer, applyMiddleware(...middlewares))
    const persistor = persistStore(store)
    return { store, persistor }
}

export default configureStore
