import * as constants from '../../helpers/constants'
import { filterByObject } from '../../helpers/objects'


export const getDrawerOpen = (state) => {
    return state.general.drawerOpen
}

export const getLanguageSelectorOpen = (state) => {
    return state.general.languageSelectorOpen
}

export const getLanguage = (state) => {
    return state.general.language
}

export const getLoading = (state) => {
    return state.general.loading
}

export const getCompanies = (state) => {
    return state.general.companies
}

export const getSelectedCompany = (state) => {
    return state.general.selectedCompany
}

export const getFilteredCustomers = (state) => {
    return state.general.filteredCustomers
}

export const getSelectedCustomer = (state) => {
    return state.general.selectedCustomer
}

export const getIncidentDetailModalOpen = (state) => {
    return state.general.incidentDetailModalOpen
}

export const getCompanyChangeDisabled = (state) => {
    return state.general.companies.length < 2
}

export const getCustomerChangeDisabled = (state) => {
    return state.general.filteredCustomers.length < 2
}

export const getSarebMenuOpen = (state) => {
    return state.general.sarebMenuOpen
}

export const getTraceAppMenuOpen = (state) => {
    return state.general.traceAppMenuOpen
}

export const getCustomerMenuOpen = (state) => {
    return state.general.customerMenuOpen
}

export const getViewName = (state) => {
    return state.general.viewName
}

export const getShowServiceDropdown = (state) => {
    const tablesWithoutServiceDropdown = [
        constants.viewNames.KEYRINGS,
        constants.viewNames.INCIDENTS,
        constants.viewNames.VISITS,
    ]
    return !tablesWithoutServiceDropdown.includes(state.general.viewName)
}

export const getShowDatePickers = (state) => {
    const tablesWithoutDatePickers = [constants.viewNames.KEYRINGS]
    return !tablesWithoutDatePickers.includes(state.general.viewName)
}

export const getUserName = (state) => {
    return state.general.userName
}

export const getVisitorControlMenuOpen = (state) => {
    return state.general.visitorControlMenuOpen
}

export const getWebcontrolMenuOpen = (state) => {
    return state.general.webcontrolMenuOpen
}

export const getShowPowerbiHomeScreen = (state) => {
    const filter = { screen_code: constants.screenNames.POWERBI_HOME }
    return (
        state.general.screens &&
        filterByObject(filter, state.general.screens).length > 0
    )
}

export const getPowerbiHomeScreens = (state) => {
    const filter = { screen_code: constants.screenNames.POWERBI_HOME }
    return filterByObject(filter, state.general.screens)
}

export const getVisitDetailModalOpen = (state) => {
    return state.general.visitDetailModalOpen
}

export const getControlappImageModalOpen = (state) => {
    return state.general.controlappImageModalOpen
}

export const getControlappRoundTasksModalOpen = (state) => {
    return state.general.controlappRoundTasksModalOpen
}

export const getControlappRoundIncidentModalOpen = (state) => {
    return state.general.controlappRoundIncidentModalOpen
}
