export const filterByObject = (filterObject, data) => {
    const matched = data.filter((object) => {
        return Object.entries(filterObject).every(
            ([filterKey, filterValue]) => {
                return object[filterKey].includes(filterValue)
            }
        )
    })
    return matched
}
